import { lazy } from "react";

const PagesRoutes = [
  //COMPONENTS
  // MAIN
  {
    path: "/main/dashboard/analytics",
    component: lazy(() => import("../../view/main/dashboard/analytics")),
    layout: "VerticalLayout",
  },

  // APPS
  {
    path: "/orders",
    component: lazy(() => import("../../view/orders")),
    layout: "VerticalLayout",
  },
  {
    path: "/tokens",
    component: lazy(() => import("../../view/tokens")),
    layout: "VerticalLayout",
  },
  {
    path: "/wallets",
    component: lazy(() => import("../../view/wallets")),
    layout: "VerticalLayout",
  },
  {
    path: "/balance-sheet-reports",
    component: lazy(() => import("../../view/balance-sheet-reports")),
    layout: "VerticalLayout",
  },
  {
    path: "/inventory-movements",
    component: lazy(() => import("../../view/inventory-movements")),
    layout: "VerticalLayout",
  },

  // PAGE
  {
    path: "/customers",
    component: lazy(() => import("../../view/customers")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin-users",
    component: lazy(() => import("../../view/admin-users")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/authentication/login",
    component: lazy(() => import("../../view/pages/authentication/login")),
    layout: "FullLayout",
  },
  {
    path: "/pages/authentication/recover-password",
    component: lazy(() =>
      import("../../view/pages/authentication/recover-password")
    ),
    layout: "FullLayout",
  },
  {
    path: "/pages/authentication/register",
    component: lazy(() => import("../../view/pages/authentication/register")),
    layout: "FullLayout",
  },
  {
    path: "/pages/authentication/reset-password",
    component: lazy(() =>
      import("../../view/pages/authentication/reset-password")
    ),
    layout: "FullLayout",
  },
  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/error-403",
    component: lazy(() => import("../../view/pages/errors/403")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-404",
    component: lazy(() => import("../../view/pages/errors/404")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-500",
    component: lazy(() => import("../../view/pages/errors/500")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-502",
    component: lazy(() => import("../../view/pages/errors/502")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-503",
    component: lazy(() => import("../../view/pages/errors/503")),
    layout: "FullLayout",
  },
  {
    path: "/pages/coming-soon",
    component: lazy(() => import("../../view/pages/errors/coming-soon")),
    layout: "FullLayout",
  },
  {
    path: "/pages/maintenance",
    component: lazy(() => import("../../view/pages/errors/maintenance")),
    layout: "FullLayout",
  },
  {
    path: "/pages/welcome",
    component: lazy(() => import("../../view/pages/lock-screen/welcome")),
    layout: "FullLayout",
  },
  {
    path: "/pages/password-is-changed",
    component: lazy(() => import("../../view/pages/lock-screen/password-is-changed")),
    layout: "FullLayout",
  },
  {
    path: "/pages/deactivated",
    component: lazy(() => import("../../view/pages/lock-screen/deactivated")),
    layout: "FullLayout",
  },
  {
    path: "/pages/lock",
    component: lazy(() => import("../../view/pages/lock-screen/lock")),
    layout: "FullLayout",
  },
  {
    path: "/pages/profile/personel-information",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/profile/notifications",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/profile/activity",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/profile/security",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/profile/password-change",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/profile/connect-with-social",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
  }
];

export default PagesRoutes;
