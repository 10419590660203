import { createEventBus } from 'lib/create-event-bus';
import { getAccessToken } from 'lib/local-storage';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ComponentResult } from 'shared-types';

const authChangeEventBus = createEventBus();

function getIsAuthenticated() {
  return getAccessToken() !== null;
}

const AuthContext = createContext<boolean>(false);

const AuthDispatchContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => {});
AuthDispatchContext.displayName = 'AuthDispatchContext';

export const AuthProvider = (props: {
  children: React.ReactNode;
}): ComponentResult => {
  const [isAuthenticated, setIsAuthenticated] = useState(getIsAuthenticated);
  const latestIsAuthenticatedRef = useRef(isAuthenticated);
  latestIsAuthenticatedRef.current = isAuthenticated;

  useEffect(() => {
    const syncStorage = () => {
      const newIsAuthenticated = getIsAuthenticated();
      if (newIsAuthenticated !== latestIsAuthenticatedRef.current) {
        setIsAuthenticated(newIsAuthenticated);
      }
    };

    const unsub = authChangeEventBus.listen(syncStorage);

    window.addEventListener('storage', syncStorage);

    return () => {
      unsub();
      window.removeEventListener('storage', syncStorage);
    };
  }, []);

  return (
    <AuthContext.Provider value={isAuthenticated}>
      <AuthDispatchContext.Provider value={setIsAuthenticated}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};

export const useIsAuthenticated = () => useContext(AuthContext);

export const useSetIsAuthenticated = () => useContext(AuthDispatchContext);

export const notifyAuthChange = () => authChangeEventBus.emit();
