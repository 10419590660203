import { InfoSquare, User, Send, Wallet } from 'react-iconly';
import { RiBillFill, RiBookReadFill } from 'react-icons/ri';

const apps = [
  {
    header: 'SYSTEM',
    children: [],
  },
  {
    id: 'customers',
    title: 'Customers',
    icon: <User set="curved" />,
    navLink: '/customers',
  },
  {
    id: 'apps-orders',
    title: 'Orders',
    icon: <Send set="bold" />,
    navLink: '/orders',
  },
  {
    id: 'apps-wallets',
    title: 'Wallets',
    icon: <Wallet set="bold" />,
    navLink: '/wallets',
  },
  {
    id: 'apps-tokens',
    title: 'Tokens',
    icon: <InfoSquare set="curved" />,
    navLink: '/tokens',
  },
  {
    id: 'apps-inventory-movements',
    title: 'Inventory Movements',
    icon: <RiBillFill/>,
    navLink: '/inventory-movements',
  },
  {
    id: 'apps-balance-sheet-reports',
    title: 'Balance Sheet Reports',
    icon: <RiBookReadFill />,
    navLink: '/balance-sheet-reports',
  },
  {
    id: 'admin-users',
    title: 'Admin Users',
    icon: <User set="curved" />,
    navLink: '/admin-users',
  },
];

export default apps;
