import { ApolloProvider } from '@apollo/client';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './assets/icons/remixicon.css';
import './assets/less/yoda-theme.less';

import App from './app';
import { createQueryClient } from './lib/create-query-client';
import { AuthProvider } from 'modules/auth';

const client = createQueryClient();

ReactDOM.render(
  <Suspense fallback="loading">
    <ApolloProvider client={client}>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </ApolloProvider>
  </Suspense>,
  document.getElementById('root')
);
