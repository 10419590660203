import { Col } from "antd";

export default function ErrorPageFooter() {
  return (
    <Col span={24} className="da-py-24">
      <p className="da-mb-0 da-badge-text">
        Copyright © {(new Date().getFullYear())} TokenBinder. All rights reserved.
      </p>
    </Col>
  );
};