import React from "react";

import { Row, Col } from "antd";

import ExpensesCard from "./expensesCard";
import AnalyticsProjectTableCard from "./analyticsProjectTableCard";
import AnalyticsVisitersLineCard from "./analyticsVisitersLineCard";
import AnalyticsRevenueRadarCard from "./analyticsRevenueRadarCard";
import { useIsAuthenticated } from "modules/auth";
import { Redirect } from "react-router";

export default function Analytics() {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return <Redirect to="/pages/authentication/login" />
  }

  return (
    <Row gutter={[32, 0]}>
      <Col span={24}>
        <h3>Welcome back, Edward 👋</h3>

        <p className="da-p1-body da-text-color-black-100 da-mb-0">
          Your current status and analytics are here
        </p>
      </Col>

      <Col span={24} className="da-mt-32">
        <Row gutter={[32, 0]}>
          <Col flex="1" className="da-overflow-hidden">
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <AnalyticsVisitersLineCard />
              </Col>

              <Col span={24}>
                <AnalyticsProjectTableCard />
              </Col>
            </Row>
          </Col>

          <Col className="da-analytics-col-2">
            <ExpensesCard />

            <AnalyticsRevenueRadarCard />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
