import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** GraphQL Scalar representing the Prisma.Decimal type, based on Decimal.js library. */
  Decimal: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AdjustWalletBalanceInput = {
  walletId: Scalars['String'];
  type: LedgerTransactionType;
  amount: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type AdminLoginInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type AdminMakeTransactionInput = {
  type: TransactionType;
  method: PaymentMethod;
  currency: Currency;
  cryptoCurrency: Scalars['String'];
  cryptoRate: Scalars['Float'];
  crypto: Scalars['Float'];
  fiat: Scalars['Float'];
  walletAddress?: Maybe<Scalars['String']>;
  walletNetwork?: Maybe<Scalars['String']>;
  fiatDepositAccount?: Maybe<Scalars['String']>;
  fiatDepositAccountName?: Maybe<Scalars['String']>;
  fiatDepositBankName?: Maybe<Scalars['String']>;
  margin: Scalars['Float'];
  userId: Scalars['String'];
  status: TransactionStatus;
  note?: Maybe<Scalars['String']>;
  cryptoWalletId?: Maybe<Scalars['String']>;
  fiatWalletId?: Maybe<Scalars['String']>;
};

export type AdminPermission = {
  __typename?: 'AdminPermission';
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  roleId: Scalars['Int'];
  role: AdminRole;
};

export type AdminPermissionCreateManyRoleInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdminPermissionCreateManyRoleInputEnvelope = {
  data: Array<AdminPermissionCreateManyRoleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AdminPermissionCreateNestedManyWithoutRoleInput = {
  create?: Maybe<Array<AdminPermissionCreateWithoutRoleInput>>;
  connectOrCreate?: Maybe<Array<AdminPermissionCreateOrConnectWithoutRoleInput>>;
  createMany?: Maybe<AdminPermissionCreateManyRoleInputEnvelope>;
  connect?: Maybe<Array<AdminPermissionWhereUniqueInput>>;
};

export type AdminPermissionCreateOrConnectWithoutRoleInput = {
  where: AdminPermissionWhereUniqueInput;
  create: AdminPermissionCreateWithoutRoleInput;
};

export type AdminPermissionCreateWithoutRoleInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdminPermissionListRelationFilter = {
  every?: Maybe<AdminPermissionWhereInput>;
  some?: Maybe<AdminPermissionWhereInput>;
  none?: Maybe<AdminPermissionWhereInput>;
};

export type AdminPermissionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type AdminPermissionOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  roleId?: Maybe<SortOrder>;
  role?: Maybe<AdminRoleOrderByWithRelationInput>;
};

export enum AdminPermissionScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Slug = 'slug',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  RoleId = 'roleId'
}

export type AdminPermissionScalarWhereInput = {
  AND?: Maybe<Array<AdminPermissionScalarWhereInput>>;
  OR?: Maybe<Array<AdminPermissionScalarWhereInput>>;
  NOT?: Maybe<Array<AdminPermissionScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  roleId?: Maybe<IntFilter>;
};

export type AdminPermissionUpdateManyMutationInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdminPermissionUpdateManyWithWhereWithoutRoleInput = {
  where: AdminPermissionScalarWhereInput;
  data: AdminPermissionUpdateManyMutationInput;
};

export type AdminPermissionUpdateManyWithoutRoleInput = {
  create?: Maybe<Array<AdminPermissionCreateWithoutRoleInput>>;
  connectOrCreate?: Maybe<Array<AdminPermissionCreateOrConnectWithoutRoleInput>>;
  upsert?: Maybe<Array<AdminPermissionUpsertWithWhereUniqueWithoutRoleInput>>;
  createMany?: Maybe<AdminPermissionCreateManyRoleInputEnvelope>;
  set?: Maybe<Array<AdminPermissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AdminPermissionWhereUniqueInput>>;
  delete?: Maybe<Array<AdminPermissionWhereUniqueInput>>;
  connect?: Maybe<Array<AdminPermissionWhereUniqueInput>>;
  update?: Maybe<Array<AdminPermissionUpdateWithWhereUniqueWithoutRoleInput>>;
  updateMany?: Maybe<Array<AdminPermissionUpdateManyWithWhereWithoutRoleInput>>;
  deleteMany?: Maybe<Array<AdminPermissionScalarWhereInput>>;
};

export type AdminPermissionUpdateWithWhereUniqueWithoutRoleInput = {
  where: AdminPermissionWhereUniqueInput;
  data: AdminPermissionUpdateWithoutRoleInput;
};

export type AdminPermissionUpdateWithoutRoleInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdminPermissionUpsertWithWhereUniqueWithoutRoleInput = {
  where: AdminPermissionWhereUniqueInput;
  update: AdminPermissionUpdateWithoutRoleInput;
  create: AdminPermissionCreateWithoutRoleInput;
};

export type AdminPermissionWhereInput = {
  AND?: Maybe<Array<AdminPermissionWhereInput>>;
  OR?: Maybe<Array<AdminPermissionWhereInput>>;
  NOT?: Maybe<Array<AdminPermissionWhereInput>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  roleId?: Maybe<IntFilter>;
  role?: Maybe<AdminRoleRelationFilter>;
};

export type AdminPermissionWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type AdminRole = {
  __typename?: 'AdminRole';
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<AdminRoleCount>;
  permissions: Array<AdminPermission>;
  admins: Array<AdminUser>;
};


export type AdminRolePermissionsArgs = {
  where?: Maybe<AdminPermissionWhereInput>;
  orderBy?: Maybe<Array<AdminPermissionOrderByWithRelationInput>>;
  cursor?: Maybe<AdminPermissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AdminPermissionScalarFieldEnum>>;
};


export type AdminRoleAdminsArgs = {
  where?: Maybe<AdminUserWhereInput>;
  orderBy?: Maybe<Array<AdminUserOrderByWithRelationInput>>;
  cursor?: Maybe<AdminUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AdminUserScalarFieldEnum>>;
};

export type AdminRoleAvgAggregate = {
  __typename?: 'AdminRoleAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type AdminRoleAvgOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
};

export type AdminRoleCount = {
  __typename?: 'AdminRoleCount';
  permissions: Scalars['Int'];
  admins: Scalars['Int'];
};

export type AdminRoleCountAggregate = {
  __typename?: 'AdminRoleCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  slug: Scalars['Int'];
  description: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AdminRoleCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AdminRoleCreateInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<AdminPermissionCreateNestedManyWithoutRoleInput>;
  admins?: Maybe<AdminUserCreateNestedManyWithoutRoleInput>;
};

export type AdminRoleCreateManyInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdminRoleCreateNestedOneWithoutAdminsInput = {
  create?: Maybe<AdminRoleCreateWithoutAdminsInput>;
  connectOrCreate?: Maybe<AdminRoleCreateOrConnectWithoutAdminsInput>;
  connect?: Maybe<AdminRoleWhereUniqueInput>;
};

export type AdminRoleCreateOrConnectWithoutAdminsInput = {
  where: AdminRoleWhereUniqueInput;
  create: AdminRoleCreateWithoutAdminsInput;
};

export type AdminRoleCreateWithoutAdminsInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<AdminPermissionCreateNestedManyWithoutRoleInput>;
};

export type AdminRoleGroupBy = {
  __typename?: 'AdminRoleGroupBy';
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<AdminRoleCountAggregate>;
  _avg?: Maybe<AdminRoleAvgAggregate>;
  _sum?: Maybe<AdminRoleSumAggregate>;
  _min?: Maybe<AdminRoleMinAggregate>;
  _max?: Maybe<AdminRoleMaxAggregate>;
};

export type AdminRoleMaxAggregate = {
  __typename?: 'AdminRoleMaxAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdminRoleMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AdminRoleMinAggregate = {
  __typename?: 'AdminRoleMinAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdminRoleMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AdminRoleOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<AdminRoleCountOrderByAggregateInput>;
  _avg?: Maybe<AdminRoleAvgOrderByAggregateInput>;
  _max?: Maybe<AdminRoleMaxOrderByAggregateInput>;
  _min?: Maybe<AdminRoleMinOrderByAggregateInput>;
  _sum?: Maybe<AdminRoleSumOrderByAggregateInput>;
};

export type AdminRoleOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  permissions?: Maybe<AdminPermissionOrderByRelationAggregateInput>;
  admins?: Maybe<AdminUserOrderByRelationAggregateInput>;
};

export type AdminRoleRelationFilter = {
  is?: Maybe<AdminRoleWhereInput>;
  isNot?: Maybe<AdminRoleWhereInput>;
};

export enum AdminRoleScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Slug = 'slug',
  Description = 'description',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type AdminRoleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AdminRoleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AdminRoleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AdminRoleScalarWhereWithAggregatesInput>>;
  id?: Maybe<IntWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  slug?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type AdminRoleSumAggregate = {
  __typename?: 'AdminRoleSumAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type AdminRoleSumOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
};

export type AdminRoleUpdateInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  permissions?: Maybe<AdminPermissionUpdateManyWithoutRoleInput>;
  admins?: Maybe<AdminUserUpdateManyWithoutRoleInput>;
};

export type AdminRoleUpdateManyMutationInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdminRoleUpdateOneWithoutAdminsInput = {
  create?: Maybe<AdminRoleCreateWithoutAdminsInput>;
  connectOrCreate?: Maybe<AdminRoleCreateOrConnectWithoutAdminsInput>;
  upsert?: Maybe<AdminRoleUpsertWithoutAdminsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AdminRoleWhereUniqueInput>;
  update?: Maybe<AdminRoleUpdateWithoutAdminsInput>;
};

export type AdminRoleUpdateWithoutAdminsInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  permissions?: Maybe<AdminPermissionUpdateManyWithoutRoleInput>;
};

export type AdminRoleUpsertWithoutAdminsInput = {
  update: AdminRoleUpdateWithoutAdminsInput;
  create: AdminRoleCreateWithoutAdminsInput;
};

export type AdminRoleWhereInput = {
  AND?: Maybe<Array<AdminRoleWhereInput>>;
  OR?: Maybe<Array<AdminRoleWhereInput>>;
  NOT?: Maybe<Array<AdminRoleWhereInput>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  permissions?: Maybe<AdminPermissionListRelationFilter>;
  admins?: Maybe<AdminUserListRelationFilter>;
};

export type AdminRoleWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type AdminUpdateTransactionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  token?: Maybe<GameTokenUpdateOneRequiredWithoutTransactionsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutTransactionsInput>;
  type?: Maybe<EnumTransactionTypeFieldUpdateOperationsInput>;
  crypto?: Maybe<DecimalFieldUpdateOperationsInput>;
  fiat?: Maybe<DecimalFieldUpdateOperationsInput>;
  serviceCharge?: Maybe<DecimalFieldUpdateOperationsInput>;
  total?: Maybe<DecimalFieldUpdateOperationsInput>;
  currency?: Maybe<EnumCurrencyFieldUpdateOperationsInput>;
  cryptoCurrency?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethod?: Maybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  status?: Maybe<EnumTransactionStatusFieldUpdateOperationsInput>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qrCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rate?: Maybe<DecimalFieldUpdateOperationsInput>;
  margin?: Maybe<DecimalFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiresAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cryptoWalletId?: Maybe<Scalars['String']>;
  fiatWalletId?: Maybe<Scalars['String']>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  id: Scalars['String'];
  username: Scalars['String'];
  email: Scalars['String'];
  status: Status;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  roleId?: Maybe<Scalars['Int']>;
  _count?: Maybe<AdminUserCount>;
  role?: Maybe<AdminRole>;
  internalTransactions: Array<InternalTransaction>;
};


export type AdminUserInternalTransactionsArgs = {
  where?: Maybe<InternalTransactionWhereInput>;
  orderBy?: Maybe<Array<InternalTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<InternalTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InternalTransactionScalarFieldEnum>>;
};

export type AdminUserAvgAggregate = {
  __typename?: 'AdminUserAvgAggregate';
  roleId?: Maybe<Scalars['Float']>;
};

export type AdminUserAvgOrderByAggregateInput = {
  roleId?: Maybe<SortOrder>;
};

export type AdminUserCount = {
  __typename?: 'AdminUserCount';
  internalTransactions: Scalars['Int'];
};

export type AdminUserCountAggregate = {
  __typename?: 'AdminUserCountAggregate';
  id: Scalars['Int'];
  username: Scalars['Int'];
  email: Scalars['Int'];
  password: Scalars['Int'];
  status: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  roleId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AdminUserCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  roleId?: Maybe<SortOrder>;
};

export type AdminUserCreateInput = {
  id?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<AdminRoleCreateNestedOneWithoutAdminsInput>;
  internalTransactions?: Maybe<InternalTransactionCreateNestedManyWithoutAdminInput>;
};

export type AdminUserCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  roleId?: Maybe<Scalars['Int']>;
};

export type AdminUserCreateManyRoleInput = {
  id?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdminUserCreateManyRoleInputEnvelope = {
  data: Array<AdminUserCreateManyRoleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AdminUserCreateNestedManyWithoutRoleInput = {
  create?: Maybe<Array<AdminUserCreateWithoutRoleInput>>;
  connectOrCreate?: Maybe<Array<AdminUserCreateOrConnectWithoutRoleInput>>;
  createMany?: Maybe<AdminUserCreateManyRoleInputEnvelope>;
  connect?: Maybe<Array<AdminUserWhereUniqueInput>>;
};

export type AdminUserCreateNestedOneWithoutInternalTransactionsInput = {
  create?: Maybe<AdminUserCreateWithoutInternalTransactionsInput>;
  connectOrCreate?: Maybe<AdminUserCreateOrConnectWithoutInternalTransactionsInput>;
  connect?: Maybe<AdminUserWhereUniqueInput>;
};

export type AdminUserCreateOrConnectWithoutInternalTransactionsInput = {
  where: AdminUserWhereUniqueInput;
  create: AdminUserCreateWithoutInternalTransactionsInput;
};

export type AdminUserCreateOrConnectWithoutRoleInput = {
  where: AdminUserWhereUniqueInput;
  create: AdminUserCreateWithoutRoleInput;
};

export type AdminUserCreateWithoutInternalTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<AdminRoleCreateNestedOneWithoutAdminsInput>;
};

export type AdminUserCreateWithoutRoleInput = {
  id?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  internalTransactions?: Maybe<InternalTransactionCreateNestedManyWithoutAdminInput>;
};

export type AdminUserGroupBy = {
  __typename?: 'AdminUserGroupBy';
  id: Scalars['String'];
  username: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  status: Status;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  roleId?: Maybe<Scalars['Int']>;
  _count?: Maybe<AdminUserCountAggregate>;
  _avg?: Maybe<AdminUserAvgAggregate>;
  _sum?: Maybe<AdminUserSumAggregate>;
  _min?: Maybe<AdminUserMinAggregate>;
  _max?: Maybe<AdminUserMaxAggregate>;
};

export type AdminUserListRelationFilter = {
  every?: Maybe<AdminUserWhereInput>;
  some?: Maybe<AdminUserWhereInput>;
  none?: Maybe<AdminUserWhereInput>;
};

export type AdminUserMaxAggregate = {
  __typename?: 'AdminUserMaxAggregate';
  id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  roleId?: Maybe<Scalars['Int']>;
};

export type AdminUserMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  roleId?: Maybe<SortOrder>;
};

export type AdminUserMinAggregate = {
  __typename?: 'AdminUserMinAggregate';
  id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  roleId?: Maybe<Scalars['Int']>;
};

export type AdminUserMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  roleId?: Maybe<SortOrder>;
};

export type AdminUserOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type AdminUserOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  roleId?: Maybe<SortOrder>;
  _count?: Maybe<AdminUserCountOrderByAggregateInput>;
  _avg?: Maybe<AdminUserAvgOrderByAggregateInput>;
  _max?: Maybe<AdminUserMaxOrderByAggregateInput>;
  _min?: Maybe<AdminUserMinOrderByAggregateInput>;
  _sum?: Maybe<AdminUserSumOrderByAggregateInput>;
};

export type AdminUserOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  roleId?: Maybe<SortOrder>;
  role?: Maybe<AdminRoleOrderByWithRelationInput>;
  internalTransactions?: Maybe<InternalTransactionOrderByRelationAggregateInput>;
};

export type AdminUserRelationFilter = {
  is?: Maybe<AdminUserWhereInput>;
  isNot?: Maybe<AdminUserWhereInput>;
};

export enum AdminUserScalarFieldEnum {
  Id = 'id',
  Username = 'username',
  Email = 'email',
  Password = 'password',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  RoleId = 'roleId'
}

export type AdminUserScalarWhereInput = {
  AND?: Maybe<Array<AdminUserScalarWhereInput>>;
  OR?: Maybe<Array<AdminUserScalarWhereInput>>;
  NOT?: Maybe<Array<AdminUserScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  username?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  password?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumStatusFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  roleId?: Maybe<IntNullableFilter>;
};

export type AdminUserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AdminUserScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AdminUserScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AdminUserScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  username?: Maybe<StringWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  password?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumStatusWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  roleId?: Maybe<IntNullableWithAggregatesFilter>;
};

export type AdminUserSumAggregate = {
  __typename?: 'AdminUserSumAggregate';
  roleId?: Maybe<Scalars['Int']>;
};

export type AdminUserSumOrderByAggregateInput = {
  roleId?: Maybe<SortOrder>;
};

export type AdminUserUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  role?: Maybe<AdminRoleUpdateOneWithoutAdminsInput>;
  internalTransactions?: Maybe<InternalTransactionUpdateManyWithoutAdminInput>;
};

export type AdminUserUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdminUserUpdateManyWithWhereWithoutRoleInput = {
  where: AdminUserScalarWhereInput;
  data: AdminUserUpdateManyMutationInput;
};

export type AdminUserUpdateManyWithoutRoleInput = {
  create?: Maybe<Array<AdminUserCreateWithoutRoleInput>>;
  connectOrCreate?: Maybe<Array<AdminUserCreateOrConnectWithoutRoleInput>>;
  upsert?: Maybe<Array<AdminUserUpsertWithWhereUniqueWithoutRoleInput>>;
  createMany?: Maybe<AdminUserCreateManyRoleInputEnvelope>;
  set?: Maybe<Array<AdminUserWhereUniqueInput>>;
  disconnect?: Maybe<Array<AdminUserWhereUniqueInput>>;
  delete?: Maybe<Array<AdminUserWhereUniqueInput>>;
  connect?: Maybe<Array<AdminUserWhereUniqueInput>>;
  update?: Maybe<Array<AdminUserUpdateWithWhereUniqueWithoutRoleInput>>;
  updateMany?: Maybe<Array<AdminUserUpdateManyWithWhereWithoutRoleInput>>;
  deleteMany?: Maybe<Array<AdminUserScalarWhereInput>>;
};

export type AdminUserUpdateOneRequiredWithoutInternalTransactionsInput = {
  create?: Maybe<AdminUserCreateWithoutInternalTransactionsInput>;
  connectOrCreate?: Maybe<AdminUserCreateOrConnectWithoutInternalTransactionsInput>;
  upsert?: Maybe<AdminUserUpsertWithoutInternalTransactionsInput>;
  connect?: Maybe<AdminUserWhereUniqueInput>;
  update?: Maybe<AdminUserUpdateWithoutInternalTransactionsInput>;
};

export type AdminUserUpdateWithWhereUniqueWithoutRoleInput = {
  where: AdminUserWhereUniqueInput;
  data: AdminUserUpdateWithoutRoleInput;
};

export type AdminUserUpdateWithoutInternalTransactionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  role?: Maybe<AdminRoleUpdateOneWithoutAdminsInput>;
};

export type AdminUserUpdateWithoutRoleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  internalTransactions?: Maybe<InternalTransactionUpdateManyWithoutAdminInput>;
};

export type AdminUserUpsertWithWhereUniqueWithoutRoleInput = {
  where: AdminUserWhereUniqueInput;
  update: AdminUserUpdateWithoutRoleInput;
  create: AdminUserCreateWithoutRoleInput;
};

export type AdminUserUpsertWithoutInternalTransactionsInput = {
  update: AdminUserUpdateWithoutInternalTransactionsInput;
  create: AdminUserCreateWithoutInternalTransactionsInput;
};

export type AdminUserWhereInput = {
  AND?: Maybe<Array<AdminUserWhereInput>>;
  OR?: Maybe<Array<AdminUserWhereInput>>;
  NOT?: Maybe<Array<AdminUserWhereInput>>;
  id?: Maybe<StringFilter>;
  username?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  password?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumStatusFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  roleId?: Maybe<IntNullableFilter>;
  role?: Maybe<AdminRoleRelationFilter>;
  internalTransactions?: Maybe<InternalTransactionListRelationFilter>;
};

export type AdminUserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateAdminRole = {
  __typename?: 'AggregateAdminRole';
  _count?: Maybe<AdminRoleCountAggregate>;
  _avg?: Maybe<AdminRoleAvgAggregate>;
  _sum?: Maybe<AdminRoleSumAggregate>;
  _min?: Maybe<AdminRoleMinAggregate>;
  _max?: Maybe<AdminRoleMaxAggregate>;
};

export type AggregateAdminUser = {
  __typename?: 'AggregateAdminUser';
  _count?: Maybe<AdminUserCountAggregate>;
  _avg?: Maybe<AdminUserAvgAggregate>;
  _sum?: Maybe<AdminUserSumAggregate>;
  _min?: Maybe<AdminUserMinAggregate>;
  _max?: Maybe<AdminUserMaxAggregate>;
};

export type AggregateBalanceSheetReport = {
  __typename?: 'AggregateBalanceSheetReport';
  _count?: Maybe<BalanceSheetReportCountAggregate>;
  _avg?: Maybe<BalanceSheetReportAvgAggregate>;
  _sum?: Maybe<BalanceSheetReportSumAggregate>;
  _min?: Maybe<BalanceSheetReportMinAggregate>;
  _max?: Maybe<BalanceSheetReportMaxAggregate>;
};

export type AggregateBlockchainNetwork = {
  __typename?: 'AggregateBlockchainNetwork';
  _count?: Maybe<BlockchainNetworkCountAggregate>;
  _min?: Maybe<BlockchainNetworkMinAggregate>;
  _max?: Maybe<BlockchainNetworkMaxAggregate>;
};

export type AggregateGameToken = {
  __typename?: 'AggregateGameToken';
  _count?: Maybe<GameTokenCountAggregate>;
  _avg?: Maybe<GameTokenAvgAggregate>;
  _sum?: Maybe<GameTokenSumAggregate>;
  _min?: Maybe<GameTokenMinAggregate>;
  _max?: Maybe<GameTokenMaxAggregate>;
};

export type AggregateGlobalSetting = {
  __typename?: 'AggregateGlobalSetting';
  _count?: Maybe<GlobalSettingCountAggregate>;
  _avg?: Maybe<GlobalSettingAvgAggregate>;
  _sum?: Maybe<GlobalSettingSumAggregate>;
  _min?: Maybe<GlobalSettingMinAggregate>;
  _max?: Maybe<GlobalSettingMaxAggregate>;
};

export type AggregateInternalTransaction = {
  __typename?: 'AggregateInternalTransaction';
  _count?: Maybe<InternalTransactionCountAggregate>;
  _min?: Maybe<InternalTransactionMinAggregate>;
  _max?: Maybe<InternalTransactionMaxAggregate>;
};

export type AggregateLedgerTransaction = {
  __typename?: 'AggregateLedgerTransaction';
  _count?: Maybe<LedgerTransactionCountAggregate>;
  _avg?: Maybe<LedgerTransactionAvgAggregate>;
  _sum?: Maybe<LedgerTransactionSumAggregate>;
  _min?: Maybe<LedgerTransactionMinAggregate>;
  _max?: Maybe<LedgerTransactionMaxAggregate>;
};

export type AggregateTransaction = {
  __typename?: 'AggregateTransaction';
  _count?: Maybe<TransactionCountAggregate>;
  _avg?: Maybe<TransactionAvgAggregate>;
  _sum?: Maybe<TransactionSumAggregate>;
  _min?: Maybe<TransactionMinAggregate>;
  _max?: Maybe<TransactionMaxAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _max?: Maybe<UserMaxAggregate>;
};

export type AggregateWallet = {
  __typename?: 'AggregateWallet';
  _count?: Maybe<WalletCountAggregate>;
  _avg?: Maybe<WalletAvgAggregate>;
  _sum?: Maybe<WalletSumAggregate>;
  _min?: Maybe<WalletMinAggregate>;
  _max?: Maybe<WalletMaxAggregate>;
};

export type AppRemoteConfig = {
  __typename?: 'AppRemoteConfig';
  supportedPaymentMethods: Array<SupportedPaymentMethods>;
  supportedTokens: Array<SupportedToken>;
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['String'];
  expiresIn: Scalars['Float'];
  /** JWT refresh token */
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Float'];
};

export type AuthUserUnion = User | AdminUser;

export type BalanceSheetReport = {
  __typename?: 'BalanceSheetReport';
  id: Scalars['Int'];
  date: Scalars['DateTime'];
  total: Scalars['Decimal'];
  metadata?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  gainLoss?: Maybe<Scalars['Float']>;
};

export type BalanceSheetReportAvgAggregate = {
  __typename?: 'BalanceSheetReportAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Decimal']>;
};

export type BalanceSheetReportAvgOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type BalanceSheetReportCountAggregate = {
  __typename?: 'BalanceSheetReportCountAggregate';
  id: Scalars['Int'];
  date: Scalars['Int'];
  total: Scalars['Int'];
  metadata: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BalanceSheetReportCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  metadata?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BalanceSheetReportCreateInput = {
  date: Scalars['DateTime'];
  total?: Maybe<Scalars['Decimal']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BalanceSheetReportCreateManyInput = {
  id?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  total?: Maybe<Scalars['Decimal']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BalanceSheetReportGroupBy = {
  __typename?: 'BalanceSheetReportGroupBy';
  id: Scalars['Int'];
  date: Scalars['DateTime'];
  total: Scalars['Decimal'];
  metadata?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<BalanceSheetReportCountAggregate>;
  _avg?: Maybe<BalanceSheetReportAvgAggregate>;
  _sum?: Maybe<BalanceSheetReportSumAggregate>;
  _min?: Maybe<BalanceSheetReportMinAggregate>;
  _max?: Maybe<BalanceSheetReportMaxAggregate>;
};

export type BalanceSheetReportMaxAggregate = {
  __typename?: 'BalanceSheetReportMaxAggregate';
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  total?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BalanceSheetReportMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BalanceSheetReportMinAggregate = {
  __typename?: 'BalanceSheetReportMinAggregate';
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  total?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BalanceSheetReportMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BalanceSheetReportOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  metadata?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<BalanceSheetReportCountOrderByAggregateInput>;
  _avg?: Maybe<BalanceSheetReportAvgOrderByAggregateInput>;
  _max?: Maybe<BalanceSheetReportMaxOrderByAggregateInput>;
  _min?: Maybe<BalanceSheetReportMinOrderByAggregateInput>;
  _sum?: Maybe<BalanceSheetReportSumOrderByAggregateInput>;
};

export type BalanceSheetReportOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  metadata?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum BalanceSheetReportScalarFieldEnum {
  Id = 'id',
  Date = 'date',
  Total = 'total',
  Metadata = 'metadata',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type BalanceSheetReportScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BalanceSheetReportScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BalanceSheetReportScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BalanceSheetReportScalarWhereWithAggregatesInput>>;
  id?: Maybe<IntWithAggregatesFilter>;
  date?: Maybe<DateTimeWithAggregatesFilter>;
  total?: Maybe<DecimalWithAggregatesFilter>;
  metadata?: Maybe<JsonNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type BalanceSheetReportSumAggregate = {
  __typename?: 'BalanceSheetReportSumAggregate';
  id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Decimal']>;
};

export type BalanceSheetReportSumOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type BalanceSheetReportUpdateInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  total?: Maybe<DecimalFieldUpdateOperationsInput>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BalanceSheetReportUpdateManyMutationInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  total?: Maybe<DecimalFieldUpdateOperationsInput>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BalanceSheetReportWhereInput = {
  AND?: Maybe<Array<BalanceSheetReportWhereInput>>;
  OR?: Maybe<Array<BalanceSheetReportWhereInput>>;
  NOT?: Maybe<Array<BalanceSheetReportWhereInput>>;
  id?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  total?: Maybe<DecimalFilter>;
  metadata?: Maybe<JsonNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BalanceSheetReportWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type BlockchainNetwork = {
  __typename?: 'BlockchainNetwork';
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<BlockchainNetworkCount>;
  tokens: Array<BlockchainNetworksOnGameTokens>;
};


export type BlockchainNetworkTokensArgs = {
  where?: Maybe<BlockchainNetworksOnGameTokensWhereInput>;
  orderBy?: Maybe<Array<BlockchainNetworksOnGameTokensOrderByWithRelationInput>>;
  cursor?: Maybe<BlockchainNetworksOnGameTokensWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BlockchainNetworksOnGameTokensScalarFieldEnum>>;
};

export type BlockchainNetworkCount = {
  __typename?: 'BlockchainNetworkCount';
  tokens: Scalars['Int'];
};

export type BlockchainNetworkCountAggregate = {
  __typename?: 'BlockchainNetworkCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  description: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BlockchainNetworkCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BlockchainNetworkCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tokens?: Maybe<BlockchainNetworksOnGameTokensCreateNestedManyWithoutNetworkInput>;
};

export type BlockchainNetworkCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlockchainNetworkCreateNestedOneWithoutTokensInput = {
  create?: Maybe<BlockchainNetworkCreateWithoutTokensInput>;
  connectOrCreate?: Maybe<BlockchainNetworkCreateOrConnectWithoutTokensInput>;
  connect?: Maybe<BlockchainNetworkWhereUniqueInput>;
};

export type BlockchainNetworkCreateOrConnectWithoutTokensInput = {
  where: BlockchainNetworkWhereUniqueInput;
  create: BlockchainNetworkCreateWithoutTokensInput;
};

export type BlockchainNetworkCreateWithoutTokensInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlockchainNetworkGroupBy = {
  __typename?: 'BlockchainNetworkGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<BlockchainNetworkCountAggregate>;
  _min?: Maybe<BlockchainNetworkMinAggregate>;
  _max?: Maybe<BlockchainNetworkMaxAggregate>;
};

export type BlockchainNetworkMaxAggregate = {
  __typename?: 'BlockchainNetworkMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlockchainNetworkMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BlockchainNetworkMinAggregate = {
  __typename?: 'BlockchainNetworkMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlockchainNetworkMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BlockchainNetworkOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<BlockchainNetworkCountOrderByAggregateInput>;
  _max?: Maybe<BlockchainNetworkMaxOrderByAggregateInput>;
  _min?: Maybe<BlockchainNetworkMinOrderByAggregateInput>;
};

export type BlockchainNetworkOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  tokens?: Maybe<BlockchainNetworksOnGameTokensOrderByRelationAggregateInput>;
};

export type BlockchainNetworkRelationFilter = {
  is?: Maybe<BlockchainNetworkWhereInput>;
  isNot?: Maybe<BlockchainNetworkWhereInput>;
};

export enum BlockchainNetworkScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type BlockchainNetworkScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BlockchainNetworkScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BlockchainNetworkScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BlockchainNetworkScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type BlockchainNetworkUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  tokens?: Maybe<BlockchainNetworksOnGameTokensUpdateManyWithoutNetworkInput>;
};

export type BlockchainNetworkUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlockchainNetworkUpdateOneRequiredWithoutTokensInput = {
  create?: Maybe<BlockchainNetworkCreateWithoutTokensInput>;
  connectOrCreate?: Maybe<BlockchainNetworkCreateOrConnectWithoutTokensInput>;
  upsert?: Maybe<BlockchainNetworkUpsertWithoutTokensInput>;
  connect?: Maybe<BlockchainNetworkWhereUniqueInput>;
  update?: Maybe<BlockchainNetworkUpdateWithoutTokensInput>;
};

export type BlockchainNetworkUpdateWithoutTokensInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlockchainNetworkUpsertWithoutTokensInput = {
  update: BlockchainNetworkUpdateWithoutTokensInput;
  create: BlockchainNetworkCreateWithoutTokensInput;
};

export type BlockchainNetworkWhereInput = {
  AND?: Maybe<Array<BlockchainNetworkWhereInput>>;
  OR?: Maybe<Array<BlockchainNetworkWhereInput>>;
  NOT?: Maybe<Array<BlockchainNetworkWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  tokens?: Maybe<BlockchainNetworksOnGameTokensListRelationFilter>;
};

export type BlockchainNetworkWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type BlockchainNetworksOnGameTokens = {
  __typename?: 'BlockchainNetworksOnGameTokens';
  networkId: Scalars['String'];
  tokenId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  network: BlockchainNetwork;
  token: GameToken;
};

export type BlockchainNetworksOnGameTokensCreateManyNetworkInput = {
  tokenId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlockchainNetworksOnGameTokensCreateManyNetworkInputEnvelope = {
  data: Array<BlockchainNetworksOnGameTokensCreateManyNetworkInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BlockchainNetworksOnGameTokensCreateManyTokenInput = {
  networkId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlockchainNetworksOnGameTokensCreateManyTokenInputEnvelope = {
  data: Array<BlockchainNetworksOnGameTokensCreateManyTokenInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BlockchainNetworksOnGameTokensCreateNestedManyWithoutNetworkInput = {
  create?: Maybe<Array<BlockchainNetworksOnGameTokensCreateWithoutNetworkInput>>;
  connectOrCreate?: Maybe<Array<BlockchainNetworksOnGameTokensCreateOrConnectWithoutNetworkInput>>;
  createMany?: Maybe<BlockchainNetworksOnGameTokensCreateManyNetworkInputEnvelope>;
  connect?: Maybe<Array<BlockchainNetworksOnGameTokensWhereUniqueInput>>;
};

export type BlockchainNetworksOnGameTokensCreateNestedManyWithoutTokenInput = {
  create?: Maybe<Array<BlockchainNetworksOnGameTokensCreateWithoutTokenInput>>;
  connectOrCreate?: Maybe<Array<BlockchainNetworksOnGameTokensCreateOrConnectWithoutTokenInput>>;
  createMany?: Maybe<BlockchainNetworksOnGameTokensCreateManyTokenInputEnvelope>;
  connect?: Maybe<Array<BlockchainNetworksOnGameTokensWhereUniqueInput>>;
};

export type BlockchainNetworksOnGameTokensCreateOrConnectWithoutNetworkInput = {
  where: BlockchainNetworksOnGameTokensWhereUniqueInput;
  create: BlockchainNetworksOnGameTokensCreateWithoutNetworkInput;
};

export type BlockchainNetworksOnGameTokensCreateOrConnectWithoutTokenInput = {
  where: BlockchainNetworksOnGameTokensWhereUniqueInput;
  create: BlockchainNetworksOnGameTokensCreateWithoutTokenInput;
};

export type BlockchainNetworksOnGameTokensCreateWithoutNetworkInput = {
  token: GameTokenCreateNestedOneWithoutNetworksInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlockchainNetworksOnGameTokensCreateWithoutTokenInput = {
  network: BlockchainNetworkCreateNestedOneWithoutTokensInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlockchainNetworksOnGameTokensListRelationFilter = {
  every?: Maybe<BlockchainNetworksOnGameTokensWhereInput>;
  some?: Maybe<BlockchainNetworksOnGameTokensWhereInput>;
  none?: Maybe<BlockchainNetworksOnGameTokensWhereInput>;
};

export type BlockchainNetworksOnGameTokensNetworkIdTokenIdCompoundUniqueInput = {
  networkId: Scalars['String'];
  tokenId: Scalars['String'];
};

export type BlockchainNetworksOnGameTokensOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BlockchainNetworksOnGameTokensOrderByWithRelationInput = {
  networkId?: Maybe<SortOrder>;
  network?: Maybe<BlockchainNetworkOrderByWithRelationInput>;
  tokenId?: Maybe<SortOrder>;
  token?: Maybe<GameTokenOrderByWithRelationInput>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum BlockchainNetworksOnGameTokensScalarFieldEnum {
  NetworkId = 'networkId',
  TokenId = 'tokenId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type BlockchainNetworksOnGameTokensScalarWhereInput = {
  AND?: Maybe<Array<BlockchainNetworksOnGameTokensScalarWhereInput>>;
  OR?: Maybe<Array<BlockchainNetworksOnGameTokensScalarWhereInput>>;
  NOT?: Maybe<Array<BlockchainNetworksOnGameTokensScalarWhereInput>>;
  networkId?: Maybe<StringFilter>;
  tokenId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BlockchainNetworksOnGameTokensUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlockchainNetworksOnGameTokensUpdateManyWithWhereWithoutNetworkInput = {
  where: BlockchainNetworksOnGameTokensScalarWhereInput;
  data: BlockchainNetworksOnGameTokensUpdateManyMutationInput;
};

export type BlockchainNetworksOnGameTokensUpdateManyWithWhereWithoutTokenInput = {
  where: BlockchainNetworksOnGameTokensScalarWhereInput;
  data: BlockchainNetworksOnGameTokensUpdateManyMutationInput;
};

export type BlockchainNetworksOnGameTokensUpdateManyWithoutNetworkInput = {
  create?: Maybe<Array<BlockchainNetworksOnGameTokensCreateWithoutNetworkInput>>;
  connectOrCreate?: Maybe<Array<BlockchainNetworksOnGameTokensCreateOrConnectWithoutNetworkInput>>;
  upsert?: Maybe<Array<BlockchainNetworksOnGameTokensUpsertWithWhereUniqueWithoutNetworkInput>>;
  createMany?: Maybe<BlockchainNetworksOnGameTokensCreateManyNetworkInputEnvelope>;
  set?: Maybe<Array<BlockchainNetworksOnGameTokensWhereUniqueInput>>;
  disconnect?: Maybe<Array<BlockchainNetworksOnGameTokensWhereUniqueInput>>;
  delete?: Maybe<Array<BlockchainNetworksOnGameTokensWhereUniqueInput>>;
  connect?: Maybe<Array<BlockchainNetworksOnGameTokensWhereUniqueInput>>;
  update?: Maybe<Array<BlockchainNetworksOnGameTokensUpdateWithWhereUniqueWithoutNetworkInput>>;
  updateMany?: Maybe<Array<BlockchainNetworksOnGameTokensUpdateManyWithWhereWithoutNetworkInput>>;
  deleteMany?: Maybe<Array<BlockchainNetworksOnGameTokensScalarWhereInput>>;
};

export type BlockchainNetworksOnGameTokensUpdateManyWithoutTokenInput = {
  create?: Maybe<Array<BlockchainNetworksOnGameTokensCreateWithoutTokenInput>>;
  connectOrCreate?: Maybe<Array<BlockchainNetworksOnGameTokensCreateOrConnectWithoutTokenInput>>;
  upsert?: Maybe<Array<BlockchainNetworksOnGameTokensUpsertWithWhereUniqueWithoutTokenInput>>;
  createMany?: Maybe<BlockchainNetworksOnGameTokensCreateManyTokenInputEnvelope>;
  set?: Maybe<Array<BlockchainNetworksOnGameTokensWhereUniqueInput>>;
  disconnect?: Maybe<Array<BlockchainNetworksOnGameTokensWhereUniqueInput>>;
  delete?: Maybe<Array<BlockchainNetworksOnGameTokensWhereUniqueInput>>;
  connect?: Maybe<Array<BlockchainNetworksOnGameTokensWhereUniqueInput>>;
  update?: Maybe<Array<BlockchainNetworksOnGameTokensUpdateWithWhereUniqueWithoutTokenInput>>;
  updateMany?: Maybe<Array<BlockchainNetworksOnGameTokensUpdateManyWithWhereWithoutTokenInput>>;
  deleteMany?: Maybe<Array<BlockchainNetworksOnGameTokensScalarWhereInput>>;
};

export type BlockchainNetworksOnGameTokensUpdateWithWhereUniqueWithoutNetworkInput = {
  where: BlockchainNetworksOnGameTokensWhereUniqueInput;
  data: BlockchainNetworksOnGameTokensUpdateWithoutNetworkInput;
};

export type BlockchainNetworksOnGameTokensUpdateWithWhereUniqueWithoutTokenInput = {
  where: BlockchainNetworksOnGameTokensWhereUniqueInput;
  data: BlockchainNetworksOnGameTokensUpdateWithoutTokenInput;
};

export type BlockchainNetworksOnGameTokensUpdateWithoutNetworkInput = {
  token?: Maybe<GameTokenUpdateOneRequiredWithoutNetworksInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlockchainNetworksOnGameTokensUpdateWithoutTokenInput = {
  network?: Maybe<BlockchainNetworkUpdateOneRequiredWithoutTokensInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlockchainNetworksOnGameTokensUpsertWithWhereUniqueWithoutNetworkInput = {
  where: BlockchainNetworksOnGameTokensWhereUniqueInput;
  update: BlockchainNetworksOnGameTokensUpdateWithoutNetworkInput;
  create: BlockchainNetworksOnGameTokensCreateWithoutNetworkInput;
};

export type BlockchainNetworksOnGameTokensUpsertWithWhereUniqueWithoutTokenInput = {
  where: BlockchainNetworksOnGameTokensWhereUniqueInput;
  update: BlockchainNetworksOnGameTokensUpdateWithoutTokenInput;
  create: BlockchainNetworksOnGameTokensCreateWithoutTokenInput;
};

export type BlockchainNetworksOnGameTokensWhereInput = {
  AND?: Maybe<Array<BlockchainNetworksOnGameTokensWhereInput>>;
  OR?: Maybe<Array<BlockchainNetworksOnGameTokensWhereInput>>;
  NOT?: Maybe<Array<BlockchainNetworksOnGameTokensWhereInput>>;
  networkId?: Maybe<StringFilter>;
  network?: Maybe<BlockchainNetworkRelationFilter>;
  tokenId?: Maybe<StringFilter>;
  token?: Maybe<GameTokenRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BlockchainNetworksOnGameTokensWhereUniqueInput = {
  networkId_tokenId?: Maybe<BlockchainNetworksOnGameTokensNetworkIdTokenIdCompoundUniqueInput>;
};

export type ConvertGameTokenRateResponse = {
  __typename?: 'ConvertGameTokenRateResponse';
  fromCurrency?: Maybe<Scalars['String']>;
  toCurrency?: Maybe<Scalars['String']>;
  conversionRate?: Maybe<Scalars['Float']>;
};

export enum Currency {
  Myr = 'MYR'
}


export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type DateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
};

export type DateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
};


export type DecimalFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Decimal']>;
  increment?: Maybe<Scalars['Decimal']>;
  decrement?: Maybe<Scalars['Decimal']>;
  multiply?: Maybe<Scalars['Decimal']>;
  divide?: Maybe<Scalars['Decimal']>;
};

export type DecimalFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalFilter>;
};

export type DecimalWithAggregatesFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedDecimalFilter>;
  _sum?: Maybe<NestedDecimalFilter>;
  _min?: Maybe<NestedDecimalFilter>;
  _max?: Maybe<NestedDecimalFilter>;
};

export type EnumCurrencyFieldUpdateOperationsInput = {
  set?: Maybe<Currency>;
};

export type EnumCurrencyFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyFilter>;
};

export type EnumCurrencyWithAggregatesFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCurrencyFilter>;
  _max?: Maybe<NestedEnumCurrencyFilter>;
};

export type EnumGlobalSettingKeyFieldUpdateOperationsInput = {
  set?: Maybe<GlobalSettingKey>;
};

export type EnumGlobalSettingKeyFilter = {
  equals?: Maybe<GlobalSettingKey>;
  in?: Maybe<Array<GlobalSettingKey>>;
  notIn?: Maybe<Array<GlobalSettingKey>>;
  not?: Maybe<NestedEnumGlobalSettingKeyFilter>;
};

export type EnumGlobalSettingKeyWithAggregatesFilter = {
  equals?: Maybe<GlobalSettingKey>;
  in?: Maybe<Array<GlobalSettingKey>>;
  notIn?: Maybe<Array<GlobalSettingKey>>;
  not?: Maybe<NestedEnumGlobalSettingKeyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumGlobalSettingKeyFilter>;
  _max?: Maybe<NestedEnumGlobalSettingKeyFilter>;
};

export type EnumGlobalSettingValueTypeFieldUpdateOperationsInput = {
  set?: Maybe<GlobalSettingValueType>;
};

export type EnumGlobalSettingValueTypeFilter = {
  equals?: Maybe<GlobalSettingValueType>;
  in?: Maybe<Array<GlobalSettingValueType>>;
  notIn?: Maybe<Array<GlobalSettingValueType>>;
  not?: Maybe<NestedEnumGlobalSettingValueTypeFilter>;
};

export type EnumGlobalSettingValueTypeWithAggregatesFilter = {
  equals?: Maybe<GlobalSettingValueType>;
  in?: Maybe<Array<GlobalSettingValueType>>;
  notIn?: Maybe<Array<GlobalSettingValueType>>;
  not?: Maybe<NestedEnumGlobalSettingValueTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumGlobalSettingValueTypeFilter>;
  _max?: Maybe<NestedEnumGlobalSettingValueTypeFilter>;
};

export type EnumInternalTransactionTypeFieldUpdateOperationsInput = {
  set?: Maybe<InternalTransactionType>;
};

export type EnumInternalTransactionTypeFilter = {
  equals?: Maybe<InternalTransactionType>;
  in?: Maybe<Array<InternalTransactionType>>;
  notIn?: Maybe<Array<InternalTransactionType>>;
  not?: Maybe<NestedEnumInternalTransactionTypeFilter>;
};

export type EnumInternalTransactionTypeWithAggregatesFilter = {
  equals?: Maybe<InternalTransactionType>;
  in?: Maybe<Array<InternalTransactionType>>;
  notIn?: Maybe<Array<InternalTransactionType>>;
  not?: Maybe<NestedEnumInternalTransactionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInternalTransactionTypeFilter>;
  _max?: Maybe<NestedEnumInternalTransactionTypeFilter>;
};

export type EnumLedgerTransactionReferenceTypeFieldUpdateOperationsInput = {
  set?: Maybe<LedgerTransactionReferenceType>;
};

export type EnumLedgerTransactionReferenceTypeFilter = {
  equals?: Maybe<LedgerTransactionReferenceType>;
  in?: Maybe<Array<LedgerTransactionReferenceType>>;
  notIn?: Maybe<Array<LedgerTransactionReferenceType>>;
  not?: Maybe<NestedEnumLedgerTransactionReferenceTypeFilter>;
};

export type EnumLedgerTransactionReferenceTypeWithAggregatesFilter = {
  equals?: Maybe<LedgerTransactionReferenceType>;
  in?: Maybe<Array<LedgerTransactionReferenceType>>;
  notIn?: Maybe<Array<LedgerTransactionReferenceType>>;
  not?: Maybe<NestedEnumLedgerTransactionReferenceTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLedgerTransactionReferenceTypeFilter>;
  _max?: Maybe<NestedEnumLedgerTransactionReferenceTypeFilter>;
};

export type EnumLedgerTransactionTypeFieldUpdateOperationsInput = {
  set?: Maybe<LedgerTransactionType>;
};

export type EnumLedgerTransactionTypeFilter = {
  equals?: Maybe<LedgerTransactionType>;
  in?: Maybe<Array<LedgerTransactionType>>;
  notIn?: Maybe<Array<LedgerTransactionType>>;
  not?: Maybe<NestedEnumLedgerTransactionTypeFilter>;
};

export type EnumLedgerTransactionTypeWithAggregatesFilter = {
  equals?: Maybe<LedgerTransactionType>;
  in?: Maybe<Array<LedgerTransactionType>>;
  notIn?: Maybe<Array<LedgerTransactionType>>;
  not?: Maybe<NestedEnumLedgerTransactionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLedgerTransactionTypeFilter>;
  _max?: Maybe<NestedEnumLedgerTransactionTypeFilter>;
};

export type EnumPaymentMethodFieldUpdateOperationsInput = {
  set?: Maybe<PaymentMethod>;
};

export type EnumPaymentMethodFilter = {
  equals?: Maybe<PaymentMethod>;
  in?: Maybe<Array<PaymentMethod>>;
  notIn?: Maybe<Array<PaymentMethod>>;
  not?: Maybe<NestedEnumPaymentMethodFilter>;
};

export type EnumPaymentMethodWithAggregatesFilter = {
  equals?: Maybe<PaymentMethod>;
  in?: Maybe<Array<PaymentMethod>>;
  notIn?: Maybe<Array<PaymentMethod>>;
  not?: Maybe<NestedEnumPaymentMethodWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPaymentMethodFilter>;
  _max?: Maybe<NestedEnumPaymentMethodFilter>;
};

export type EnumStatusFieldUpdateOperationsInput = {
  set?: Maybe<Status>;
};

export type EnumStatusFilter = {
  equals?: Maybe<Status>;
  in?: Maybe<Array<Status>>;
  notIn?: Maybe<Array<Status>>;
  not?: Maybe<NestedEnumStatusFilter>;
};

export type EnumStatusWithAggregatesFilter = {
  equals?: Maybe<Status>;
  in?: Maybe<Array<Status>>;
  notIn?: Maybe<Array<Status>>;
  not?: Maybe<NestedEnumStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStatusFilter>;
  _max?: Maybe<NestedEnumStatusFilter>;
};

export type EnumTransactionStatusFieldUpdateOperationsInput = {
  set?: Maybe<TransactionStatus>;
};

export type EnumTransactionStatusFilter = {
  equals?: Maybe<TransactionStatus>;
  in?: Maybe<Array<TransactionStatus>>;
  notIn?: Maybe<Array<TransactionStatus>>;
  not?: Maybe<NestedEnumTransactionStatusFilter>;
};

export type EnumTransactionStatusWithAggregatesFilter = {
  equals?: Maybe<TransactionStatus>;
  in?: Maybe<Array<TransactionStatus>>;
  notIn?: Maybe<Array<TransactionStatus>>;
  not?: Maybe<NestedEnumTransactionStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTransactionStatusFilter>;
  _max?: Maybe<NestedEnumTransactionStatusFilter>;
};

export type EnumTransactionTypeFieldUpdateOperationsInput = {
  set?: Maybe<TransactionType>;
};

export type EnumTransactionTypeFilter = {
  equals?: Maybe<TransactionType>;
  in?: Maybe<Array<TransactionType>>;
  notIn?: Maybe<Array<TransactionType>>;
  not?: Maybe<NestedEnumTransactionTypeFilter>;
};

export type EnumTransactionTypeWithAggregatesFilter = {
  equals?: Maybe<TransactionType>;
  in?: Maybe<Array<TransactionType>>;
  notIn?: Maybe<Array<TransactionType>>;
  not?: Maybe<NestedEnumTransactionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTransactionTypeFilter>;
  _max?: Maybe<NestedEnumTransactionTypeFilter>;
};

export type EnumWalletTypeFieldUpdateOperationsInput = {
  set?: Maybe<WalletType>;
};

export type EnumWalletTypeFilter = {
  equals?: Maybe<WalletType>;
  in?: Maybe<Array<WalletType>>;
  notIn?: Maybe<Array<WalletType>>;
  not?: Maybe<NestedEnumWalletTypeFilter>;
};

export type EnumWalletTypeWithAggregatesFilter = {
  equals?: Maybe<WalletType>;
  in?: Maybe<Array<WalletType>>;
  notIn?: Maybe<Array<WalletType>>;
  not?: Maybe<NestedEnumWalletTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWalletTypeFilter>;
  _max?: Maybe<NestedEnumWalletTypeFilter>;
};

export type EstimateTransactionResponse = {
  __typename?: 'EstimateTransactionResponse';
  currency: Currency;
  cryptoCurrency: Scalars['String'];
  cryptoRate?: Maybe<Scalars['Float']>;
  crypto?: Maybe<Scalars['Float']>;
  fiat?: Maybe<Scalars['Float']>;
};

export type ExchangeWalletsInput = {
  fromWalletId: Scalars['String'];
  toWalletId: Scalars['String'];
  fromAmount: Scalars['Float'];
  toAmount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
};

export type FloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type FloatWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _max?: Maybe<NestedFloatFilter>;
};

export type GameToken = {
  __typename?: 'GameToken';
  id: Scalars['String'];
  name: Scalars['String'];
  status: Status;
  symbol: Scalars['String'];
  cryptoCurrency?: Maybe<Scalars['String']>;
  coinId: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  buyMargin: Scalars['Float'];
  sellMargin: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sequence: Scalars['Int'];
  _count?: Maybe<GameTokenCount>;
  transactions: Array<Transaction>;
  networks: Array<BlockchainNetworksOnGameTokens>;
  blockchainNetworks?: Maybe<Array<BlockchainNetwork>>;
};


export type GameTokenTransactionsArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<TransactionOrderByWithRelationInput>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<TransactionScalarFieldEnum>>;
};


export type GameTokenNetworksArgs = {
  where?: Maybe<BlockchainNetworksOnGameTokensWhereInput>;
  orderBy?: Maybe<Array<BlockchainNetworksOnGameTokensOrderByWithRelationInput>>;
  cursor?: Maybe<BlockchainNetworksOnGameTokensWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BlockchainNetworksOnGameTokensScalarFieldEnum>>;
};

export type GameTokenAvgAggregate = {
  __typename?: 'GameTokenAvgAggregate';
  buyMargin?: Maybe<Scalars['Float']>;
  sellMargin?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

export type GameTokenAvgOrderByAggregateInput = {
  buyMargin?: Maybe<SortOrder>;
  sellMargin?: Maybe<SortOrder>;
  sequence?: Maybe<SortOrder>;
};

export type GameTokenCount = {
  __typename?: 'GameTokenCount';
  transactions: Scalars['Int'];
  networks: Scalars['Int'];
};

export type GameTokenCountAggregate = {
  __typename?: 'GameTokenCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  symbol: Scalars['Int'];
  cryptoCurrency: Scalars['Int'];
  coinId: Scalars['Int'];
  logo: Scalars['Int'];
  buyMargin: Scalars['Int'];
  sellMargin: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  sequence: Scalars['Int'];
  _all: Scalars['Int'];
};

export type GameTokenCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  symbol?: Maybe<SortOrder>;
  cryptoCurrency?: Maybe<SortOrder>;
  coinId?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  buyMargin?: Maybe<SortOrder>;
  sellMargin?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  sequence?: Maybe<SortOrder>;
};

export type GameTokenCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<Status>;
  symbol: Scalars['String'];
  cryptoCurrency?: Maybe<Scalars['String']>;
  coinId: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  buyMargin?: Maybe<Scalars['Float']>;
  sellMargin?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sequence?: Maybe<Scalars['Int']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutTokenInput>;
  networks?: Maybe<BlockchainNetworksOnGameTokensCreateNestedManyWithoutTokenInput>;
};

export type GameTokenCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<Status>;
  symbol: Scalars['String'];
  cryptoCurrency?: Maybe<Scalars['String']>;
  coinId: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  buyMargin?: Maybe<Scalars['Float']>;
  sellMargin?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sequence?: Maybe<Scalars['Int']>;
};

export type GameTokenCreateNestedOneWithoutNetworksInput = {
  create?: Maybe<GameTokenCreateWithoutNetworksInput>;
  connectOrCreate?: Maybe<GameTokenCreateOrConnectWithoutNetworksInput>;
  connect?: Maybe<GameTokenWhereUniqueInput>;
};

export type GameTokenCreateNestedOneWithoutTransactionsInput = {
  create?: Maybe<GameTokenCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<GameTokenCreateOrConnectWithoutTransactionsInput>;
  connect?: Maybe<GameTokenWhereUniqueInput>;
};

export type GameTokenCreateOrConnectWithoutNetworksInput = {
  where: GameTokenWhereUniqueInput;
  create: GameTokenCreateWithoutNetworksInput;
};

export type GameTokenCreateOrConnectWithoutTransactionsInput = {
  where: GameTokenWhereUniqueInput;
  create: GameTokenCreateWithoutTransactionsInput;
};

export type GameTokenCreateWithoutNetworksInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<Status>;
  symbol: Scalars['String'];
  cryptoCurrency?: Maybe<Scalars['String']>;
  coinId: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  buyMargin?: Maybe<Scalars['Float']>;
  sellMargin?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sequence?: Maybe<Scalars['Int']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutTokenInput>;
};

export type GameTokenCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<Status>;
  symbol: Scalars['String'];
  cryptoCurrency?: Maybe<Scalars['String']>;
  coinId: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  buyMargin?: Maybe<Scalars['Float']>;
  sellMargin?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sequence?: Maybe<Scalars['Int']>;
  networks?: Maybe<BlockchainNetworksOnGameTokensCreateNestedManyWithoutTokenInput>;
};

export type GameTokenGroupBy = {
  __typename?: 'GameTokenGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  status: Status;
  symbol: Scalars['String'];
  cryptoCurrency?: Maybe<Scalars['String']>;
  coinId: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  buyMargin: Scalars['Float'];
  sellMargin: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sequence: Scalars['Int'];
  _count?: Maybe<GameTokenCountAggregate>;
  _avg?: Maybe<GameTokenAvgAggregate>;
  _sum?: Maybe<GameTokenSumAggregate>;
  _min?: Maybe<GameTokenMinAggregate>;
  _max?: Maybe<GameTokenMaxAggregate>;
};

export type GameTokenMaxAggregate = {
  __typename?: 'GameTokenMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  symbol?: Maybe<Scalars['String']>;
  cryptoCurrency?: Maybe<Scalars['String']>;
  coinId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  buyMargin?: Maybe<Scalars['Float']>;
  sellMargin?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sequence?: Maybe<Scalars['Int']>;
};

export type GameTokenMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  symbol?: Maybe<SortOrder>;
  cryptoCurrency?: Maybe<SortOrder>;
  coinId?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  buyMargin?: Maybe<SortOrder>;
  sellMargin?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  sequence?: Maybe<SortOrder>;
};

export type GameTokenMinAggregate = {
  __typename?: 'GameTokenMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  symbol?: Maybe<Scalars['String']>;
  cryptoCurrency?: Maybe<Scalars['String']>;
  coinId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  buyMargin?: Maybe<Scalars['Float']>;
  sellMargin?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sequence?: Maybe<Scalars['Int']>;
};

export type GameTokenMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  symbol?: Maybe<SortOrder>;
  cryptoCurrency?: Maybe<SortOrder>;
  coinId?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  buyMargin?: Maybe<SortOrder>;
  sellMargin?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  sequence?: Maybe<SortOrder>;
};

export type GameTokenOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  symbol?: Maybe<SortOrder>;
  cryptoCurrency?: Maybe<SortOrder>;
  coinId?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  buyMargin?: Maybe<SortOrder>;
  sellMargin?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  sequence?: Maybe<SortOrder>;
  _count?: Maybe<GameTokenCountOrderByAggregateInput>;
  _avg?: Maybe<GameTokenAvgOrderByAggregateInput>;
  _max?: Maybe<GameTokenMaxOrderByAggregateInput>;
  _min?: Maybe<GameTokenMinOrderByAggregateInput>;
  _sum?: Maybe<GameTokenSumOrderByAggregateInput>;
};

export type GameTokenOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  symbol?: Maybe<SortOrder>;
  cryptoCurrency?: Maybe<SortOrder>;
  coinId?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  buyMargin?: Maybe<SortOrder>;
  sellMargin?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  sequence?: Maybe<SortOrder>;
  transactions?: Maybe<TransactionOrderByRelationAggregateInput>;
  networks?: Maybe<BlockchainNetworksOnGameTokensOrderByRelationAggregateInput>;
};

export type GameTokenRateResult = {
  __typename?: 'GameTokenRateResult';
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  buyPrice?: Maybe<Scalars['String']>;
  sellPrice?: Maybe<Scalars['String']>;
  lastUpdatedAt?: Maybe<Scalars['Float']>;
};

export type GameTokenRelationFilter = {
  is?: Maybe<GameTokenWhereInput>;
  isNot?: Maybe<GameTokenWhereInput>;
};

export enum GameTokenScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Status = 'status',
  Symbol = 'symbol',
  CryptoCurrency = 'cryptoCurrency',
  CoinId = 'coinId',
  Logo = 'logo',
  BuyMargin = 'buyMargin',
  SellMargin = 'sellMargin',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Sequence = 'sequence'
}

export type GameTokenScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<GameTokenScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<GameTokenScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<GameTokenScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumStatusWithAggregatesFilter>;
  symbol?: Maybe<StringWithAggregatesFilter>;
  cryptoCurrency?: Maybe<StringNullableWithAggregatesFilter>;
  coinId?: Maybe<StringWithAggregatesFilter>;
  logo?: Maybe<StringNullableWithAggregatesFilter>;
  buyMargin?: Maybe<FloatWithAggregatesFilter>;
  sellMargin?: Maybe<FloatWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  sequence?: Maybe<IntWithAggregatesFilter>;
};

export type GameTokenSumAggregate = {
  __typename?: 'GameTokenSumAggregate';
  buyMargin?: Maybe<Scalars['Float']>;
  sellMargin?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Int']>;
};

export type GameTokenSumOrderByAggregateInput = {
  buyMargin?: Maybe<SortOrder>;
  sellMargin?: Maybe<SortOrder>;
  sequence?: Maybe<SortOrder>;
};

export type GameTokenUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  symbol?: Maybe<StringFieldUpdateOperationsInput>;
  cryptoCurrency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  coinId?: Maybe<StringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buyMargin?: Maybe<FloatFieldUpdateOperationsInput>;
  sellMargin?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: Maybe<IntFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutTokenInput>;
  networks?: Maybe<BlockchainNetworksOnGameTokensUpdateManyWithoutTokenInput>;
};

export type GameTokenUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  symbol?: Maybe<StringFieldUpdateOperationsInput>;
  cryptoCurrency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  coinId?: Maybe<StringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buyMargin?: Maybe<FloatFieldUpdateOperationsInput>;
  sellMargin?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: Maybe<IntFieldUpdateOperationsInput>;
};

export type GameTokenUpdateOneRequiredWithoutNetworksInput = {
  create?: Maybe<GameTokenCreateWithoutNetworksInput>;
  connectOrCreate?: Maybe<GameTokenCreateOrConnectWithoutNetworksInput>;
  upsert?: Maybe<GameTokenUpsertWithoutNetworksInput>;
  connect?: Maybe<GameTokenWhereUniqueInput>;
  update?: Maybe<GameTokenUpdateWithoutNetworksInput>;
};

export type GameTokenUpdateOneRequiredWithoutTransactionsInput = {
  create?: Maybe<GameTokenCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<GameTokenCreateOrConnectWithoutTransactionsInput>;
  upsert?: Maybe<GameTokenUpsertWithoutTransactionsInput>;
  connect?: Maybe<GameTokenWhereUniqueInput>;
  update?: Maybe<GameTokenUpdateWithoutTransactionsInput>;
};

export type GameTokenUpdateWithoutNetworksInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  symbol?: Maybe<StringFieldUpdateOperationsInput>;
  cryptoCurrency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  coinId?: Maybe<StringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buyMargin?: Maybe<FloatFieldUpdateOperationsInput>;
  sellMargin?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: Maybe<IntFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutTokenInput>;
};

export type GameTokenUpdateWithoutTransactionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  symbol?: Maybe<StringFieldUpdateOperationsInput>;
  cryptoCurrency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  coinId?: Maybe<StringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buyMargin?: Maybe<FloatFieldUpdateOperationsInput>;
  sellMargin?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: Maybe<IntFieldUpdateOperationsInput>;
  networks?: Maybe<BlockchainNetworksOnGameTokensUpdateManyWithoutTokenInput>;
};

export type GameTokenUpsertWithoutNetworksInput = {
  update: GameTokenUpdateWithoutNetworksInput;
  create: GameTokenCreateWithoutNetworksInput;
};

export type GameTokenUpsertWithoutTransactionsInput = {
  update: GameTokenUpdateWithoutTransactionsInput;
  create: GameTokenCreateWithoutTransactionsInput;
};

export type GameTokenWhereInput = {
  AND?: Maybe<Array<GameTokenWhereInput>>;
  OR?: Maybe<Array<GameTokenWhereInput>>;
  NOT?: Maybe<Array<GameTokenWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumStatusFilter>;
  symbol?: Maybe<StringFilter>;
  cryptoCurrency?: Maybe<StringNullableFilter>;
  coinId?: Maybe<StringFilter>;
  logo?: Maybe<StringNullableFilter>;
  buyMargin?: Maybe<FloatFilter>;
  sellMargin?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  sequence?: Maybe<IntFilter>;
  transactions?: Maybe<TransactionListRelationFilter>;
  networks?: Maybe<BlockchainNetworksOnGameTokensListRelationFilter>;
};

export type GameTokenWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GeneratePresignedFileInput = {
  fileName: Scalars['String'];
  /** The purpose of the upload, such as: profile_picture, ... */
  purpose: Scalars['String'];
  referenceId?: Maybe<Scalars['String']>;
};

export type GlobalSetting = {
  __typename?: 'GlobalSetting';
  id: Scalars['Int'];
  key: GlobalSettingKey;
  value?: Maybe<Scalars['JSON']>;
  valueType: GlobalSettingValueType;
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type GlobalSettingAvgAggregate = {
  __typename?: 'GlobalSettingAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type GlobalSettingAvgOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
};

export type GlobalSettingCountAggregate = {
  __typename?: 'GlobalSettingCountAggregate';
  id: Scalars['Int'];
  key: Scalars['Int'];
  value: Scalars['Int'];
  valueType: Scalars['Int'];
  description: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type GlobalSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  valueType?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type GlobalSettingCreateInput = {
  key: GlobalSettingKey;
  value?: Maybe<Scalars['JSON']>;
  valueType?: Maybe<GlobalSettingValueType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalSettingCreateManyInput = {
  id?: Maybe<Scalars['Int']>;
  key: GlobalSettingKey;
  value?: Maybe<Scalars['JSON']>;
  valueType?: Maybe<GlobalSettingValueType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalSettingGroupBy = {
  __typename?: 'GlobalSettingGroupBy';
  id: Scalars['Int'];
  key: GlobalSettingKey;
  value?: Maybe<Scalars['JSON']>;
  valueType: GlobalSettingValueType;
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<GlobalSettingCountAggregate>;
  _avg?: Maybe<GlobalSettingAvgAggregate>;
  _sum?: Maybe<GlobalSettingSumAggregate>;
  _min?: Maybe<GlobalSettingMinAggregate>;
  _max?: Maybe<GlobalSettingMaxAggregate>;
};

export enum GlobalSettingKey {
  TransactionExpiryMinute = 'TransactionExpiryMinute'
}

export type GlobalSettingMaxAggregate = {
  __typename?: 'GlobalSettingMaxAggregate';
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<GlobalSettingKey>;
  valueType?: Maybe<GlobalSettingValueType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  valueType?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type GlobalSettingMinAggregate = {
  __typename?: 'GlobalSettingMinAggregate';
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<GlobalSettingKey>;
  valueType?: Maybe<GlobalSettingValueType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  valueType?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type GlobalSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  valueType?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<GlobalSettingCountOrderByAggregateInput>;
  _avg?: Maybe<GlobalSettingAvgOrderByAggregateInput>;
  _max?: Maybe<GlobalSettingMaxOrderByAggregateInput>;
  _min?: Maybe<GlobalSettingMinOrderByAggregateInput>;
  _sum?: Maybe<GlobalSettingSumOrderByAggregateInput>;
};

export type GlobalSettingOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  valueType?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum GlobalSettingScalarFieldEnum {
  Id = 'id',
  Key = 'key',
  Value = 'value',
  ValueType = 'valueType',
  Description = 'description',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type GlobalSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<GlobalSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<GlobalSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<GlobalSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<IntWithAggregatesFilter>;
  key?: Maybe<EnumGlobalSettingKeyWithAggregatesFilter>;
  value?: Maybe<JsonNullableWithAggregatesFilter>;
  valueType?: Maybe<EnumGlobalSettingValueTypeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type GlobalSettingSumAggregate = {
  __typename?: 'GlobalSettingSumAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type GlobalSettingSumOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
};

export type GlobalSettingUpdateInput = {
  key?: Maybe<EnumGlobalSettingKeyFieldUpdateOperationsInput>;
  value?: Maybe<Scalars['JSON']>;
  valueType?: Maybe<EnumGlobalSettingValueTypeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type GlobalSettingUpdateManyMutationInput = {
  key?: Maybe<EnumGlobalSettingKeyFieldUpdateOperationsInput>;
  value?: Maybe<Scalars['JSON']>;
  valueType?: Maybe<EnumGlobalSettingValueTypeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export enum GlobalSettingValueType {
  String = 'String',
  Boolean = 'Boolean',
  Number = 'Number',
  Json = 'Json',
  Array = 'Array',
  Date = 'Date'
}

export type GlobalSettingWhereInput = {
  AND?: Maybe<Array<GlobalSettingWhereInput>>;
  OR?: Maybe<Array<GlobalSettingWhereInput>>;
  NOT?: Maybe<Array<GlobalSettingWhereInput>>;
  id?: Maybe<IntFilter>;
  key?: Maybe<EnumGlobalSettingKeyFilter>;
  value?: Maybe<JsonNullableFilter>;
  valueType?: Maybe<EnumGlobalSettingValueTypeFilter>;
  description?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type GlobalSettingWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<GlobalSettingKey>;
};

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type IntNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
};

export type IntWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
};

export type InternalTransaction = {
  __typename?: 'InternalTransaction';
  id: Scalars['String'];
  adminId: Scalars['String'];
  type: InternalTransactionType;
  typeDefinition?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  note?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  admin: AdminUser;
};

export type InternalTransactionCountAggregate = {
  __typename?: 'InternalTransactionCountAggregate';
  id: Scalars['Int'];
  adminId: Scalars['Int'];
  type: Scalars['Int'];
  typeDefinition: Scalars['Int'];
  withdrawalMetadata: Scalars['Int'];
  depositMetadata: Scalars['Int'];
  note: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InternalTransactionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  adminId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  typeDefinition?: Maybe<SortOrder>;
  withdrawalMetadata?: Maybe<SortOrder>;
  depositMetadata?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InternalTransactionCreateInput = {
  id?: Maybe<Scalars['String']>;
  admin: AdminUserCreateNestedOneWithoutInternalTransactionsInput;
  type: InternalTransactionType;
  typeDefinition?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  note?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InternalTransactionCreateManyAdminInput = {
  id?: Maybe<Scalars['String']>;
  type: InternalTransactionType;
  typeDefinition?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  note?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InternalTransactionCreateManyAdminInputEnvelope = {
  data: Array<InternalTransactionCreateManyAdminInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InternalTransactionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  adminId: Scalars['String'];
  type: InternalTransactionType;
  typeDefinition?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  note?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InternalTransactionCreateNestedManyWithoutAdminInput = {
  create?: Maybe<Array<InternalTransactionCreateWithoutAdminInput>>;
  connectOrCreate?: Maybe<Array<InternalTransactionCreateOrConnectWithoutAdminInput>>;
  createMany?: Maybe<InternalTransactionCreateManyAdminInputEnvelope>;
  connect?: Maybe<Array<InternalTransactionWhereUniqueInput>>;
};

export type InternalTransactionCreateOrConnectWithoutAdminInput = {
  where: InternalTransactionWhereUniqueInput;
  create: InternalTransactionCreateWithoutAdminInput;
};

export type InternalTransactionCreateWithoutAdminInput = {
  id?: Maybe<Scalars['String']>;
  type: InternalTransactionType;
  typeDefinition?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  note?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InternalTransactionGroupBy = {
  __typename?: 'InternalTransactionGroupBy';
  id: Scalars['String'];
  adminId: Scalars['String'];
  type: InternalTransactionType;
  typeDefinition?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  note?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<InternalTransactionCountAggregate>;
  _min?: Maybe<InternalTransactionMinAggregate>;
  _max?: Maybe<InternalTransactionMaxAggregate>;
};

export type InternalTransactionListRelationFilter = {
  every?: Maybe<InternalTransactionWhereInput>;
  some?: Maybe<InternalTransactionWhereInput>;
  none?: Maybe<InternalTransactionWhereInput>;
};

export type InternalTransactionMaxAggregate = {
  __typename?: 'InternalTransactionMaxAggregate';
  id?: Maybe<Scalars['String']>;
  adminId?: Maybe<Scalars['String']>;
  type?: Maybe<InternalTransactionType>;
  note?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InternalTransactionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  adminId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InternalTransactionMinAggregate = {
  __typename?: 'InternalTransactionMinAggregate';
  id?: Maybe<Scalars['String']>;
  adminId?: Maybe<Scalars['String']>;
  type?: Maybe<InternalTransactionType>;
  note?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InternalTransactionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  adminId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InternalTransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type InternalTransactionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  adminId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  typeDefinition?: Maybe<SortOrder>;
  withdrawalMetadata?: Maybe<SortOrder>;
  depositMetadata?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<InternalTransactionCountOrderByAggregateInput>;
  _max?: Maybe<InternalTransactionMaxOrderByAggregateInput>;
  _min?: Maybe<InternalTransactionMinOrderByAggregateInput>;
};

export type InternalTransactionOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  adminId?: Maybe<SortOrder>;
  admin?: Maybe<AdminUserOrderByWithRelationInput>;
  type?: Maybe<SortOrder>;
  typeDefinition?: Maybe<SortOrder>;
  withdrawalMetadata?: Maybe<SortOrder>;
  depositMetadata?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InternalTransactionScalarFieldEnum {
  Id = 'id',
  AdminId = 'adminId',
  Type = 'type',
  TypeDefinition = 'typeDefinition',
  WithdrawalMetadata = 'withdrawalMetadata',
  DepositMetadata = 'depositMetadata',
  Note = 'note',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type InternalTransactionScalarWhereInput = {
  AND?: Maybe<Array<InternalTransactionScalarWhereInput>>;
  OR?: Maybe<Array<InternalTransactionScalarWhereInput>>;
  NOT?: Maybe<Array<InternalTransactionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  adminId?: Maybe<StringFilter>;
  type?: Maybe<EnumInternalTransactionTypeFilter>;
  typeDefinition?: Maybe<JsonNullableFilter>;
  withdrawalMetadata?: Maybe<JsonNullableFilter>;
  depositMetadata?: Maybe<JsonNullableFilter>;
  note?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InternalTransactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InternalTransactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InternalTransactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InternalTransactionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  adminId?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumInternalTransactionTypeWithAggregatesFilter>;
  typeDefinition?: Maybe<JsonNullableWithAggregatesFilter>;
  withdrawalMetadata?: Maybe<JsonNullableWithAggregatesFilter>;
  depositMetadata?: Maybe<JsonNullableWithAggregatesFilter>;
  note?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InternalTransactionType {
  Adjustment = 'ADJUSTMENT',
  Exchange = 'EXCHANGE'
}

export type InternalTransactionUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  admin?: Maybe<AdminUserUpdateOneRequiredWithoutInternalTransactionsInput>;
  type?: Maybe<EnumInternalTransactionTypeFieldUpdateOperationsInput>;
  typeDefinition?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type InternalTransactionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumInternalTransactionTypeFieldUpdateOperationsInput>;
  typeDefinition?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type InternalTransactionUpdateManyWithWhereWithoutAdminInput = {
  where: InternalTransactionScalarWhereInput;
  data: InternalTransactionUpdateManyMutationInput;
};

export type InternalTransactionUpdateManyWithoutAdminInput = {
  create?: Maybe<Array<InternalTransactionCreateWithoutAdminInput>>;
  connectOrCreate?: Maybe<Array<InternalTransactionCreateOrConnectWithoutAdminInput>>;
  upsert?: Maybe<Array<InternalTransactionUpsertWithWhereUniqueWithoutAdminInput>>;
  createMany?: Maybe<InternalTransactionCreateManyAdminInputEnvelope>;
  set?: Maybe<Array<InternalTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<InternalTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<InternalTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<InternalTransactionWhereUniqueInput>>;
  update?: Maybe<Array<InternalTransactionUpdateWithWhereUniqueWithoutAdminInput>>;
  updateMany?: Maybe<Array<InternalTransactionUpdateManyWithWhereWithoutAdminInput>>;
  deleteMany?: Maybe<Array<InternalTransactionScalarWhereInput>>;
};

export type InternalTransactionUpdateWithWhereUniqueWithoutAdminInput = {
  where: InternalTransactionWhereUniqueInput;
  data: InternalTransactionUpdateWithoutAdminInput;
};

export type InternalTransactionUpdateWithoutAdminInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumInternalTransactionTypeFieldUpdateOperationsInput>;
  typeDefinition?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type InternalTransactionUpsertWithWhereUniqueWithoutAdminInput = {
  where: InternalTransactionWhereUniqueInput;
  update: InternalTransactionUpdateWithoutAdminInput;
  create: InternalTransactionCreateWithoutAdminInput;
};

export type InternalTransactionWhereInput = {
  AND?: Maybe<Array<InternalTransactionWhereInput>>;
  OR?: Maybe<Array<InternalTransactionWhereInput>>;
  NOT?: Maybe<Array<InternalTransactionWhereInput>>;
  id?: Maybe<StringFilter>;
  adminId?: Maybe<StringFilter>;
  admin?: Maybe<AdminUserRelationFilter>;
  type?: Maybe<EnumInternalTransactionTypeFilter>;
  typeDefinition?: Maybe<JsonNullableFilter>;
  withdrawalMetadata?: Maybe<JsonNullableFilter>;
  depositMetadata?: Maybe<JsonNullableFilter>;
  note?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InternalTransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};


export type JsonNullableFilter = {
  equals?: Maybe<Scalars['JSON']>;
  not?: Maybe<Scalars['JSON']>;
};

export type JsonNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['JSON']>;
  not?: Maybe<Scalars['JSON']>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedJsonNullableFilter>;
  _max?: Maybe<NestedJsonNullableFilter>;
};

export type LedgerTransaction = {
  __typename?: 'LedgerTransaction';
  id: Scalars['String'];
  referenceType: LedgerTransactionReferenceType;
  referenceId: Scalars['String'];
  walletId: Scalars['String'];
  type: LedgerTransactionType;
  amount: Scalars['Decimal'];
  previousBalance: Scalars['Decimal'];
  balance: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  wallet: Wallet;
  totalValue?: Maybe<Scalars['Float']>;
};

export type LedgerTransactionAvgAggregate = {
  __typename?: 'LedgerTransactionAvgAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  previousBalance?: Maybe<Scalars['Decimal']>;
  balance?: Maybe<Scalars['Decimal']>;
  rate?: Maybe<Scalars['Decimal']>;
};

export type LedgerTransactionAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  previousBalance?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
};

export type LedgerTransactionCountAggregate = {
  __typename?: 'LedgerTransactionCountAggregate';
  id: Scalars['Int'];
  referenceType: Scalars['Int'];
  referenceId: Scalars['Int'];
  walletId: Scalars['Int'];
  type: Scalars['Int'];
  amount: Scalars['Int'];
  previousBalance: Scalars['Int'];
  balance: Scalars['Int'];
  rate: Scalars['Int'];
  description: Scalars['Int'];
  note: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LedgerTransactionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  referenceType?: Maybe<SortOrder>;
  referenceId?: Maybe<SortOrder>;
  walletId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  previousBalance?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LedgerTransactionCreateInput = {
  id?: Maybe<Scalars['String']>;
  referenceType: LedgerTransactionReferenceType;
  referenceId: Scalars['String'];
  wallet: WalletCreateNestedOneWithoutLedgersInput;
  type: LedgerTransactionType;
  amount?: Maybe<Scalars['Decimal']>;
  previousBalance?: Maybe<Scalars['Decimal']>;
  balance?: Maybe<Scalars['Decimal']>;
  rate?: Maybe<Scalars['Decimal']>;
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LedgerTransactionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  referenceType: LedgerTransactionReferenceType;
  referenceId: Scalars['String'];
  walletId: Scalars['String'];
  type: LedgerTransactionType;
  amount?: Maybe<Scalars['Decimal']>;
  previousBalance?: Maybe<Scalars['Decimal']>;
  balance?: Maybe<Scalars['Decimal']>;
  rate?: Maybe<Scalars['Decimal']>;
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LedgerTransactionCreateManyWalletInput = {
  id?: Maybe<Scalars['String']>;
  referenceType: LedgerTransactionReferenceType;
  referenceId: Scalars['String'];
  type: LedgerTransactionType;
  amount?: Maybe<Scalars['Decimal']>;
  previousBalance?: Maybe<Scalars['Decimal']>;
  balance?: Maybe<Scalars['Decimal']>;
  rate?: Maybe<Scalars['Decimal']>;
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LedgerTransactionCreateManyWalletInputEnvelope = {
  data: Array<LedgerTransactionCreateManyWalletInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LedgerTransactionCreateNestedManyWithoutWalletInput = {
  create?: Maybe<Array<LedgerTransactionCreateWithoutWalletInput>>;
  connectOrCreate?: Maybe<Array<LedgerTransactionCreateOrConnectWithoutWalletInput>>;
  createMany?: Maybe<LedgerTransactionCreateManyWalletInputEnvelope>;
  connect?: Maybe<Array<LedgerTransactionWhereUniqueInput>>;
};

export type LedgerTransactionCreateOrConnectWithoutWalletInput = {
  where: LedgerTransactionWhereUniqueInput;
  create: LedgerTransactionCreateWithoutWalletInput;
};

export type LedgerTransactionCreateWithoutWalletInput = {
  id?: Maybe<Scalars['String']>;
  referenceType: LedgerTransactionReferenceType;
  referenceId: Scalars['String'];
  type: LedgerTransactionType;
  amount?: Maybe<Scalars['Decimal']>;
  previousBalance?: Maybe<Scalars['Decimal']>;
  balance?: Maybe<Scalars['Decimal']>;
  rate?: Maybe<Scalars['Decimal']>;
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LedgerTransactionGroupBy = {
  __typename?: 'LedgerTransactionGroupBy';
  id: Scalars['String'];
  referenceType: LedgerTransactionReferenceType;
  referenceId: Scalars['String'];
  walletId: Scalars['String'];
  type: LedgerTransactionType;
  amount: Scalars['Decimal'];
  previousBalance: Scalars['Decimal'];
  balance: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<LedgerTransactionCountAggregate>;
  _avg?: Maybe<LedgerTransactionAvgAggregate>;
  _sum?: Maybe<LedgerTransactionSumAggregate>;
  _min?: Maybe<LedgerTransactionMinAggregate>;
  _max?: Maybe<LedgerTransactionMaxAggregate>;
};

export type LedgerTransactionListRelationFilter = {
  every?: Maybe<LedgerTransactionWhereInput>;
  some?: Maybe<LedgerTransactionWhereInput>;
  none?: Maybe<LedgerTransactionWhereInput>;
};

export type LedgerTransactionMaxAggregate = {
  __typename?: 'LedgerTransactionMaxAggregate';
  id?: Maybe<Scalars['String']>;
  referenceType?: Maybe<LedgerTransactionReferenceType>;
  referenceId?: Maybe<Scalars['String']>;
  walletId?: Maybe<Scalars['String']>;
  type?: Maybe<LedgerTransactionType>;
  amount?: Maybe<Scalars['Decimal']>;
  previousBalance?: Maybe<Scalars['Decimal']>;
  balance?: Maybe<Scalars['Decimal']>;
  rate?: Maybe<Scalars['Decimal']>;
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LedgerTransactionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  referenceType?: Maybe<SortOrder>;
  referenceId?: Maybe<SortOrder>;
  walletId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  previousBalance?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LedgerTransactionMinAggregate = {
  __typename?: 'LedgerTransactionMinAggregate';
  id?: Maybe<Scalars['String']>;
  referenceType?: Maybe<LedgerTransactionReferenceType>;
  referenceId?: Maybe<Scalars['String']>;
  walletId?: Maybe<Scalars['String']>;
  type?: Maybe<LedgerTransactionType>;
  amount?: Maybe<Scalars['Decimal']>;
  previousBalance?: Maybe<Scalars['Decimal']>;
  balance?: Maybe<Scalars['Decimal']>;
  rate?: Maybe<Scalars['Decimal']>;
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LedgerTransactionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  referenceType?: Maybe<SortOrder>;
  referenceId?: Maybe<SortOrder>;
  walletId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  previousBalance?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LedgerTransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type LedgerTransactionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  referenceType?: Maybe<SortOrder>;
  referenceId?: Maybe<SortOrder>;
  walletId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  previousBalance?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<LedgerTransactionCountOrderByAggregateInput>;
  _avg?: Maybe<LedgerTransactionAvgOrderByAggregateInput>;
  _max?: Maybe<LedgerTransactionMaxOrderByAggregateInput>;
  _min?: Maybe<LedgerTransactionMinOrderByAggregateInput>;
  _sum?: Maybe<LedgerTransactionSumOrderByAggregateInput>;
};

export type LedgerTransactionOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  referenceType?: Maybe<SortOrder>;
  referenceId?: Maybe<SortOrder>;
  walletId?: Maybe<SortOrder>;
  wallet?: Maybe<WalletOrderByWithRelationInput>;
  type?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  previousBalance?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum LedgerTransactionReferenceType {
  Transaction = 'TRANSACTION',
  InternalTransaction = 'INTERNAL_TRANSACTION'
}

export enum LedgerTransactionScalarFieldEnum {
  Id = 'id',
  ReferenceType = 'referenceType',
  ReferenceId = 'referenceId',
  WalletId = 'walletId',
  Type = 'type',
  Amount = 'amount',
  PreviousBalance = 'previousBalance',
  Balance = 'balance',
  Rate = 'rate',
  Description = 'description',
  Note = 'note',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type LedgerTransactionScalarWhereInput = {
  AND?: Maybe<Array<LedgerTransactionScalarWhereInput>>;
  OR?: Maybe<Array<LedgerTransactionScalarWhereInput>>;
  NOT?: Maybe<Array<LedgerTransactionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  referenceType?: Maybe<EnumLedgerTransactionReferenceTypeFilter>;
  referenceId?: Maybe<StringFilter>;
  walletId?: Maybe<StringFilter>;
  type?: Maybe<EnumLedgerTransactionTypeFilter>;
  amount?: Maybe<DecimalFilter>;
  previousBalance?: Maybe<DecimalFilter>;
  balance?: Maybe<DecimalFilter>;
  rate?: Maybe<DecimalFilter>;
  description?: Maybe<StringNullableFilter>;
  note?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LedgerTransactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LedgerTransactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LedgerTransactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LedgerTransactionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  referenceType?: Maybe<EnumLedgerTransactionReferenceTypeWithAggregatesFilter>;
  referenceId?: Maybe<StringWithAggregatesFilter>;
  walletId?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumLedgerTransactionTypeWithAggregatesFilter>;
  amount?: Maybe<DecimalWithAggregatesFilter>;
  previousBalance?: Maybe<DecimalWithAggregatesFilter>;
  balance?: Maybe<DecimalWithAggregatesFilter>;
  rate?: Maybe<DecimalWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  note?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type LedgerTransactionSumAggregate = {
  __typename?: 'LedgerTransactionSumAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  previousBalance?: Maybe<Scalars['Decimal']>;
  balance?: Maybe<Scalars['Decimal']>;
  rate?: Maybe<Scalars['Decimal']>;
};

export type LedgerTransactionSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  previousBalance?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
};

export enum LedgerTransactionType {
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL'
}

export type LedgerTransactionUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  referenceType?: Maybe<EnumLedgerTransactionReferenceTypeFieldUpdateOperationsInput>;
  referenceId?: Maybe<StringFieldUpdateOperationsInput>;
  wallet?: Maybe<WalletUpdateOneRequiredWithoutLedgersInput>;
  type?: Maybe<EnumLedgerTransactionTypeFieldUpdateOperationsInput>;
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  previousBalance?: Maybe<DecimalFieldUpdateOperationsInput>;
  balance?: Maybe<DecimalFieldUpdateOperationsInput>;
  rate?: Maybe<DecimalFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type LedgerTransactionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  referenceType?: Maybe<EnumLedgerTransactionReferenceTypeFieldUpdateOperationsInput>;
  referenceId?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumLedgerTransactionTypeFieldUpdateOperationsInput>;
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  previousBalance?: Maybe<DecimalFieldUpdateOperationsInput>;
  balance?: Maybe<DecimalFieldUpdateOperationsInput>;
  rate?: Maybe<DecimalFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type LedgerTransactionUpdateManyWithWhereWithoutWalletInput = {
  where: LedgerTransactionScalarWhereInput;
  data: LedgerTransactionUpdateManyMutationInput;
};

export type LedgerTransactionUpdateManyWithoutWalletInput = {
  create?: Maybe<Array<LedgerTransactionCreateWithoutWalletInput>>;
  connectOrCreate?: Maybe<Array<LedgerTransactionCreateOrConnectWithoutWalletInput>>;
  upsert?: Maybe<Array<LedgerTransactionUpsertWithWhereUniqueWithoutWalletInput>>;
  createMany?: Maybe<LedgerTransactionCreateManyWalletInputEnvelope>;
  set?: Maybe<Array<LedgerTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<LedgerTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<LedgerTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<LedgerTransactionWhereUniqueInput>>;
  update?: Maybe<Array<LedgerTransactionUpdateWithWhereUniqueWithoutWalletInput>>;
  updateMany?: Maybe<Array<LedgerTransactionUpdateManyWithWhereWithoutWalletInput>>;
  deleteMany?: Maybe<Array<LedgerTransactionScalarWhereInput>>;
};

export type LedgerTransactionUpdateWithWhereUniqueWithoutWalletInput = {
  where: LedgerTransactionWhereUniqueInput;
  data: LedgerTransactionUpdateWithoutWalletInput;
};

export type LedgerTransactionUpdateWithoutWalletInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  referenceType?: Maybe<EnumLedgerTransactionReferenceTypeFieldUpdateOperationsInput>;
  referenceId?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumLedgerTransactionTypeFieldUpdateOperationsInput>;
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  previousBalance?: Maybe<DecimalFieldUpdateOperationsInput>;
  balance?: Maybe<DecimalFieldUpdateOperationsInput>;
  rate?: Maybe<DecimalFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type LedgerTransactionUpsertWithWhereUniqueWithoutWalletInput = {
  where: LedgerTransactionWhereUniqueInput;
  update: LedgerTransactionUpdateWithoutWalletInput;
  create: LedgerTransactionCreateWithoutWalletInput;
};

export type LedgerTransactionWhereInput = {
  AND?: Maybe<Array<LedgerTransactionWhereInput>>;
  OR?: Maybe<Array<LedgerTransactionWhereInput>>;
  NOT?: Maybe<Array<LedgerTransactionWhereInput>>;
  id?: Maybe<StringFilter>;
  referenceType?: Maybe<EnumLedgerTransactionReferenceTypeFilter>;
  referenceId?: Maybe<StringFilter>;
  walletId?: Maybe<StringFilter>;
  wallet?: Maybe<WalletRelationFilter>;
  type?: Maybe<EnumLedgerTransactionTypeFilter>;
  amount?: Maybe<DecimalFilter>;
  previousBalance?: Maybe<DecimalFilter>;
  balance?: Maybe<DecimalFilter>;
  rate?: Maybe<DecimalFilter>;
  description?: Maybe<StringNullableFilter>;
  note?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LedgerTransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type MakeTransactionInput = {
  type: TransactionType;
  method: PaymentMethod;
  currency: Currency;
  cryptoCurrency: Scalars['String'];
  cryptoRate: Scalars['Float'];
  crypto: Scalars['Float'];
  fiat: Scalars['Float'];
  walletAddress?: Maybe<Scalars['String']>;
  walletNetwork?: Maybe<Scalars['String']>;
  fiatDepositAccount?: Maybe<Scalars['String']>;
  fiatDepositAccountName?: Maybe<Scalars['String']>;
  fiatDepositBankName?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  login: Auth;
  register: Auth;
  adminLogin: Auth;
  refreshToken: Auth;
  createUser: User;
  createManyUser: AffectedRowsOutput;
  deleteUser?: Maybe<User>;
  updateUser?: Maybe<User>;
  deleteManyUser: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  upsertUser: User;
  createAdminUser: AdminUser;
  createManyAdminUser: AffectedRowsOutput;
  deleteAdminUser?: Maybe<AdminUser>;
  updateAdminUser?: Maybe<AdminUser>;
  deleteManyAdminUser: AffectedRowsOutput;
  updateManyAdminUser: AffectedRowsOutput;
  upsertAdminUser: AdminUser;
  createAdminRole: AdminRole;
  createManyAdminRole: AffectedRowsOutput;
  deleteAdminRole?: Maybe<AdminRole>;
  updateAdminRole?: Maybe<AdminRole>;
  deleteManyAdminRole: AffectedRowsOutput;
  updateManyAdminRole: AffectedRowsOutput;
  upsertAdminRole: AdminRole;
  createGameToken: GameToken;
  createManyGameToken: AffectedRowsOutput;
  deleteGameToken?: Maybe<GameToken>;
  updateGameToken?: Maybe<GameToken>;
  deleteManyGameToken: AffectedRowsOutput;
  updateManyGameToken: AffectedRowsOutput;
  upsertGameToken: GameToken;
  createBlockchainNetwork: BlockchainNetwork;
  createManyBlockchainNetwork: AffectedRowsOutput;
  deleteBlockchainNetwork?: Maybe<BlockchainNetwork>;
  updateBlockchainNetwork?: Maybe<BlockchainNetwork>;
  deleteManyBlockchainNetwork: AffectedRowsOutput;
  updateManyBlockchainNetwork: AffectedRowsOutput;
  upsertBlockchainNetwork: BlockchainNetwork;
  generatePresignedFile: SignedFile;
  createTransaction: Transaction;
  createManyTransaction: AffectedRowsOutput;
  deleteTransaction?: Maybe<Transaction>;
  updateTransaction?: Maybe<Transaction>;
  deleteManyTransaction: AffectedRowsOutput;
  updateManyTransaction: AffectedRowsOutput;
  upsertTransaction: Transaction;
  makeTransaction: Transaction;
  markTransactionAsPaid: Transaction;
  cancelTransaction: Transaction;
  adminUpdateTransaction: Transaction;
  adminMakeTransaction: Transaction;
  createWallet: Wallet;
  createManyWallet: AffectedRowsOutput;
  deleteWallet?: Maybe<Wallet>;
  updateWallet?: Maybe<Wallet>;
  deleteManyWallet: AffectedRowsOutput;
  updateManyWallet: AffectedRowsOutput;
  upsertWallet: Wallet;
  exchangeWallets: Scalars['Boolean'];
  adjustWalletBalance: Scalars['Boolean'];
  createLedgerTransaction: LedgerTransaction;
  createManyLedgerTransaction: AffectedRowsOutput;
  deleteLedgerTransaction?: Maybe<LedgerTransaction>;
  updateLedgerTransaction?: Maybe<LedgerTransaction>;
  deleteManyLedgerTransaction: AffectedRowsOutput;
  updateManyLedgerTransaction: AffectedRowsOutput;
  upsertLedgerTransaction: LedgerTransaction;
  createGlobalSetting: GlobalSetting;
  createManyGlobalSetting: AffectedRowsOutput;
  deleteGlobalSetting?: Maybe<GlobalSetting>;
  updateGlobalSetting?: Maybe<GlobalSetting>;
  deleteManyGlobalSetting: AffectedRowsOutput;
  updateManyGlobalSetting: AffectedRowsOutput;
  upsertGlobalSetting: GlobalSetting;
  createBalanceSheetReport: BalanceSheetReport;
  createManyBalanceSheetReport: AffectedRowsOutput;
  deleteBalanceSheetReport?: Maybe<BalanceSheetReport>;
  updateBalanceSheetReport?: Maybe<BalanceSheetReport>;
  deleteManyBalanceSheetReport: AffectedRowsOutput;
  updateManyBalanceSheetReport: AffectedRowsOutput;
  upsertBalanceSheetReport: BalanceSheetReport;
  createInternalTransaction: InternalTransaction;
  createManyInternalTransaction: AffectedRowsOutput;
  deleteInternalTransaction?: Maybe<InternalTransaction>;
  updateInternalTransaction?: Maybe<InternalTransaction>;
  deleteManyInternalTransaction: AffectedRowsOutput;
  updateManyInternalTransaction: AffectedRowsOutput;
  upsertInternalTransaction: InternalTransaction;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationRegisterArgs = {
  data: RegisterInput;
};


export type MutationAdminLoginArgs = {
  data: AdminLoginInput;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String'];
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationDeleteManyUserArgs = {
  where?: Maybe<UserWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: Maybe<UserWhereInput>;
};


export type MutationUpsertUserArgs = {
  where: UserWhereUniqueInput;
  create: UserCreateInput;
  update: UserUpdateInput;
};


export type MutationCreateAdminUserArgs = {
  data: AdminUserCreateInput;
};


export type MutationCreateManyAdminUserArgs = {
  data: Array<AdminUserCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteAdminUserArgs = {
  where: AdminUserWhereUniqueInput;
};


export type MutationUpdateAdminUserArgs = {
  data: AdminUserUpdateInput;
  where: AdminUserWhereUniqueInput;
};


export type MutationDeleteManyAdminUserArgs = {
  where?: Maybe<AdminUserWhereInput>;
};


export type MutationUpdateManyAdminUserArgs = {
  data: AdminUserUpdateManyMutationInput;
  where?: Maybe<AdminUserWhereInput>;
};


export type MutationUpsertAdminUserArgs = {
  where: AdminUserWhereUniqueInput;
  create: AdminUserCreateInput;
  update: AdminUserUpdateInput;
};


export type MutationCreateAdminRoleArgs = {
  data: AdminRoleCreateInput;
};


export type MutationCreateManyAdminRoleArgs = {
  data: Array<AdminRoleCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteAdminRoleArgs = {
  where: AdminRoleWhereUniqueInput;
};


export type MutationUpdateAdminRoleArgs = {
  data: AdminRoleUpdateInput;
  where: AdminRoleWhereUniqueInput;
};


export type MutationDeleteManyAdminRoleArgs = {
  where?: Maybe<AdminRoleWhereInput>;
};


export type MutationUpdateManyAdminRoleArgs = {
  data: AdminRoleUpdateManyMutationInput;
  where?: Maybe<AdminRoleWhereInput>;
};


export type MutationUpsertAdminRoleArgs = {
  where: AdminRoleWhereUniqueInput;
  create: AdminRoleCreateInput;
  update: AdminRoleUpdateInput;
};


export type MutationCreateGameTokenArgs = {
  data: GameTokenCreateInput;
};


export type MutationCreateManyGameTokenArgs = {
  data: Array<GameTokenCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteGameTokenArgs = {
  where: GameTokenWhereUniqueInput;
};


export type MutationUpdateGameTokenArgs = {
  data: GameTokenUpdateInput;
  where: GameTokenWhereUniqueInput;
};


export type MutationDeleteManyGameTokenArgs = {
  where?: Maybe<GameTokenWhereInput>;
};


export type MutationUpdateManyGameTokenArgs = {
  data: GameTokenUpdateManyMutationInput;
  where?: Maybe<GameTokenWhereInput>;
};


export type MutationUpsertGameTokenArgs = {
  where: GameTokenWhereUniqueInput;
  create: GameTokenCreateInput;
  update: GameTokenUpdateInput;
};


export type MutationCreateBlockchainNetworkArgs = {
  data: BlockchainNetworkCreateInput;
};


export type MutationCreateManyBlockchainNetworkArgs = {
  data: Array<BlockchainNetworkCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteBlockchainNetworkArgs = {
  where: BlockchainNetworkWhereUniqueInput;
};


export type MutationUpdateBlockchainNetworkArgs = {
  data: BlockchainNetworkUpdateInput;
  where: BlockchainNetworkWhereUniqueInput;
};


export type MutationDeleteManyBlockchainNetworkArgs = {
  where?: Maybe<BlockchainNetworkWhereInput>;
};


export type MutationUpdateManyBlockchainNetworkArgs = {
  data: BlockchainNetworkUpdateManyMutationInput;
  where?: Maybe<BlockchainNetworkWhereInput>;
};


export type MutationUpsertBlockchainNetworkArgs = {
  where: BlockchainNetworkWhereUniqueInput;
  create: BlockchainNetworkCreateInput;
  update: BlockchainNetworkUpdateInput;
};


export type MutationGeneratePresignedFileArgs = {
  data: GeneratePresignedFileInput;
};


export type MutationCreateTransactionArgs = {
  data: TransactionCreateInput;
};


export type MutationCreateManyTransactionArgs = {
  data: Array<TransactionCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type MutationUpdateTransactionArgs = {
  data: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationDeleteManyTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
};


export type MutationUpdateManyTransactionArgs = {
  data: TransactionUpdateManyMutationInput;
  where?: Maybe<TransactionWhereInput>;
};


export type MutationUpsertTransactionArgs = {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateInput;
  update: TransactionUpdateInput;
};


export type MutationMakeTransactionArgs = {
  data: MakeTransactionInput;
};


export type MutationMarkTransactionAsPaidArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationCancelTransactionArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationAdminUpdateTransactionArgs = {
  data: AdminUpdateTransactionInput;
  where: TransactionWhereUniqueInput;
};


export type MutationAdminMakeTransactionArgs = {
  data: AdminMakeTransactionInput;
};


export type MutationCreateWalletArgs = {
  data: WalletCreateInput;
};


export type MutationCreateManyWalletArgs = {
  data: Array<WalletCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteWalletArgs = {
  where: WalletWhereUniqueInput;
};


export type MutationUpdateWalletArgs = {
  data: WalletUpdateInput;
  where: WalletWhereUniqueInput;
};


export type MutationDeleteManyWalletArgs = {
  where?: Maybe<WalletWhereInput>;
};


export type MutationUpdateManyWalletArgs = {
  data: WalletUpdateManyMutationInput;
  where?: Maybe<WalletWhereInput>;
};


export type MutationUpsertWalletArgs = {
  where: WalletWhereUniqueInput;
  create: WalletCreateInput;
  update: WalletUpdateInput;
};


export type MutationExchangeWalletsArgs = {
  data: ExchangeWalletsInput;
};


export type MutationAdjustWalletBalanceArgs = {
  data: AdjustWalletBalanceInput;
};


export type MutationCreateLedgerTransactionArgs = {
  data: LedgerTransactionCreateInput;
};


export type MutationCreateManyLedgerTransactionArgs = {
  data: Array<LedgerTransactionCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteLedgerTransactionArgs = {
  where: LedgerTransactionWhereUniqueInput;
};


export type MutationUpdateLedgerTransactionArgs = {
  data: LedgerTransactionUpdateInput;
  where: LedgerTransactionWhereUniqueInput;
};


export type MutationDeleteManyLedgerTransactionArgs = {
  where?: Maybe<LedgerTransactionWhereInput>;
};


export type MutationUpdateManyLedgerTransactionArgs = {
  data: LedgerTransactionUpdateManyMutationInput;
  where?: Maybe<LedgerTransactionWhereInput>;
};


export type MutationUpsertLedgerTransactionArgs = {
  where: LedgerTransactionWhereUniqueInput;
  create: LedgerTransactionCreateInput;
  update: LedgerTransactionUpdateInput;
};


export type MutationCreateGlobalSettingArgs = {
  data: GlobalSettingCreateInput;
};


export type MutationCreateManyGlobalSettingArgs = {
  data: Array<GlobalSettingCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteGlobalSettingArgs = {
  where: GlobalSettingWhereUniqueInput;
};


export type MutationUpdateGlobalSettingArgs = {
  data: GlobalSettingUpdateInput;
  where: GlobalSettingWhereUniqueInput;
};


export type MutationDeleteManyGlobalSettingArgs = {
  where?: Maybe<GlobalSettingWhereInput>;
};


export type MutationUpdateManyGlobalSettingArgs = {
  data: GlobalSettingUpdateManyMutationInput;
  where?: Maybe<GlobalSettingWhereInput>;
};


export type MutationUpsertGlobalSettingArgs = {
  where: GlobalSettingWhereUniqueInput;
  create: GlobalSettingCreateInput;
  update: GlobalSettingUpdateInput;
};


export type MutationCreateBalanceSheetReportArgs = {
  data: BalanceSheetReportCreateInput;
};


export type MutationCreateManyBalanceSheetReportArgs = {
  data: Array<BalanceSheetReportCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteBalanceSheetReportArgs = {
  where: BalanceSheetReportWhereUniqueInput;
};


export type MutationUpdateBalanceSheetReportArgs = {
  data: BalanceSheetReportUpdateInput;
  where: BalanceSheetReportWhereUniqueInput;
};


export type MutationDeleteManyBalanceSheetReportArgs = {
  where?: Maybe<BalanceSheetReportWhereInput>;
};


export type MutationUpdateManyBalanceSheetReportArgs = {
  data: BalanceSheetReportUpdateManyMutationInput;
  where?: Maybe<BalanceSheetReportWhereInput>;
};


export type MutationUpsertBalanceSheetReportArgs = {
  where: BalanceSheetReportWhereUniqueInput;
  create: BalanceSheetReportCreateInput;
  update: BalanceSheetReportUpdateInput;
};


export type MutationCreateInternalTransactionArgs = {
  data: InternalTransactionCreateInput;
};


export type MutationCreateManyInternalTransactionArgs = {
  data: Array<InternalTransactionCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteInternalTransactionArgs = {
  where: InternalTransactionWhereUniqueInput;
};


export type MutationUpdateInternalTransactionArgs = {
  data: InternalTransactionUpdateInput;
  where: InternalTransactionWhereUniqueInput;
};


export type MutationDeleteManyInternalTransactionArgs = {
  where?: Maybe<InternalTransactionWhereInput>;
};


export type MutationUpdateManyInternalTransactionArgs = {
  data: InternalTransactionUpdateManyMutationInput;
  where?: Maybe<InternalTransactionWhereInput>;
};


export type MutationUpsertInternalTransactionArgs = {
  where: InternalTransactionWhereUniqueInput;
  create: InternalTransactionCreateInput;
  update: InternalTransactionUpdateInput;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
};

export type NestedDecimalFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalFilter>;
};

export type NestedDecimalWithAggregatesFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedDecimalFilter>;
  _sum?: Maybe<NestedDecimalFilter>;
  _min?: Maybe<NestedDecimalFilter>;
  _max?: Maybe<NestedDecimalFilter>;
};

export type NestedEnumCurrencyFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyFilter>;
};

export type NestedEnumCurrencyWithAggregatesFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCurrencyFilter>;
  _max?: Maybe<NestedEnumCurrencyFilter>;
};

export type NestedEnumGlobalSettingKeyFilter = {
  equals?: Maybe<GlobalSettingKey>;
  in?: Maybe<Array<GlobalSettingKey>>;
  notIn?: Maybe<Array<GlobalSettingKey>>;
  not?: Maybe<NestedEnumGlobalSettingKeyFilter>;
};

export type NestedEnumGlobalSettingKeyWithAggregatesFilter = {
  equals?: Maybe<GlobalSettingKey>;
  in?: Maybe<Array<GlobalSettingKey>>;
  notIn?: Maybe<Array<GlobalSettingKey>>;
  not?: Maybe<NestedEnumGlobalSettingKeyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumGlobalSettingKeyFilter>;
  _max?: Maybe<NestedEnumGlobalSettingKeyFilter>;
};

export type NestedEnumGlobalSettingValueTypeFilter = {
  equals?: Maybe<GlobalSettingValueType>;
  in?: Maybe<Array<GlobalSettingValueType>>;
  notIn?: Maybe<Array<GlobalSettingValueType>>;
  not?: Maybe<NestedEnumGlobalSettingValueTypeFilter>;
};

export type NestedEnumGlobalSettingValueTypeWithAggregatesFilter = {
  equals?: Maybe<GlobalSettingValueType>;
  in?: Maybe<Array<GlobalSettingValueType>>;
  notIn?: Maybe<Array<GlobalSettingValueType>>;
  not?: Maybe<NestedEnumGlobalSettingValueTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumGlobalSettingValueTypeFilter>;
  _max?: Maybe<NestedEnumGlobalSettingValueTypeFilter>;
};

export type NestedEnumInternalTransactionTypeFilter = {
  equals?: Maybe<InternalTransactionType>;
  in?: Maybe<Array<InternalTransactionType>>;
  notIn?: Maybe<Array<InternalTransactionType>>;
  not?: Maybe<NestedEnumInternalTransactionTypeFilter>;
};

export type NestedEnumInternalTransactionTypeWithAggregatesFilter = {
  equals?: Maybe<InternalTransactionType>;
  in?: Maybe<Array<InternalTransactionType>>;
  notIn?: Maybe<Array<InternalTransactionType>>;
  not?: Maybe<NestedEnumInternalTransactionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInternalTransactionTypeFilter>;
  _max?: Maybe<NestedEnumInternalTransactionTypeFilter>;
};

export type NestedEnumLedgerTransactionReferenceTypeFilter = {
  equals?: Maybe<LedgerTransactionReferenceType>;
  in?: Maybe<Array<LedgerTransactionReferenceType>>;
  notIn?: Maybe<Array<LedgerTransactionReferenceType>>;
  not?: Maybe<NestedEnumLedgerTransactionReferenceTypeFilter>;
};

export type NestedEnumLedgerTransactionReferenceTypeWithAggregatesFilter = {
  equals?: Maybe<LedgerTransactionReferenceType>;
  in?: Maybe<Array<LedgerTransactionReferenceType>>;
  notIn?: Maybe<Array<LedgerTransactionReferenceType>>;
  not?: Maybe<NestedEnumLedgerTransactionReferenceTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLedgerTransactionReferenceTypeFilter>;
  _max?: Maybe<NestedEnumLedgerTransactionReferenceTypeFilter>;
};

export type NestedEnumLedgerTransactionTypeFilter = {
  equals?: Maybe<LedgerTransactionType>;
  in?: Maybe<Array<LedgerTransactionType>>;
  notIn?: Maybe<Array<LedgerTransactionType>>;
  not?: Maybe<NestedEnumLedgerTransactionTypeFilter>;
};

export type NestedEnumLedgerTransactionTypeWithAggregatesFilter = {
  equals?: Maybe<LedgerTransactionType>;
  in?: Maybe<Array<LedgerTransactionType>>;
  notIn?: Maybe<Array<LedgerTransactionType>>;
  not?: Maybe<NestedEnumLedgerTransactionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLedgerTransactionTypeFilter>;
  _max?: Maybe<NestedEnumLedgerTransactionTypeFilter>;
};

export type NestedEnumPaymentMethodFilter = {
  equals?: Maybe<PaymentMethod>;
  in?: Maybe<Array<PaymentMethod>>;
  notIn?: Maybe<Array<PaymentMethod>>;
  not?: Maybe<NestedEnumPaymentMethodFilter>;
};

export type NestedEnumPaymentMethodWithAggregatesFilter = {
  equals?: Maybe<PaymentMethod>;
  in?: Maybe<Array<PaymentMethod>>;
  notIn?: Maybe<Array<PaymentMethod>>;
  not?: Maybe<NestedEnumPaymentMethodWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPaymentMethodFilter>;
  _max?: Maybe<NestedEnumPaymentMethodFilter>;
};

export type NestedEnumStatusFilter = {
  equals?: Maybe<Status>;
  in?: Maybe<Array<Status>>;
  notIn?: Maybe<Array<Status>>;
  not?: Maybe<NestedEnumStatusFilter>;
};

export type NestedEnumStatusWithAggregatesFilter = {
  equals?: Maybe<Status>;
  in?: Maybe<Array<Status>>;
  notIn?: Maybe<Array<Status>>;
  not?: Maybe<NestedEnumStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStatusFilter>;
  _max?: Maybe<NestedEnumStatusFilter>;
};

export type NestedEnumTransactionStatusFilter = {
  equals?: Maybe<TransactionStatus>;
  in?: Maybe<Array<TransactionStatus>>;
  notIn?: Maybe<Array<TransactionStatus>>;
  not?: Maybe<NestedEnumTransactionStatusFilter>;
};

export type NestedEnumTransactionStatusWithAggregatesFilter = {
  equals?: Maybe<TransactionStatus>;
  in?: Maybe<Array<TransactionStatus>>;
  notIn?: Maybe<Array<TransactionStatus>>;
  not?: Maybe<NestedEnumTransactionStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTransactionStatusFilter>;
  _max?: Maybe<NestedEnumTransactionStatusFilter>;
};

export type NestedEnumTransactionTypeFilter = {
  equals?: Maybe<TransactionType>;
  in?: Maybe<Array<TransactionType>>;
  notIn?: Maybe<Array<TransactionType>>;
  not?: Maybe<NestedEnumTransactionTypeFilter>;
};

export type NestedEnumTransactionTypeWithAggregatesFilter = {
  equals?: Maybe<TransactionType>;
  in?: Maybe<Array<TransactionType>>;
  notIn?: Maybe<Array<TransactionType>>;
  not?: Maybe<NestedEnumTransactionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTransactionTypeFilter>;
  _max?: Maybe<NestedEnumTransactionTypeFilter>;
};

export type NestedEnumWalletTypeFilter = {
  equals?: Maybe<WalletType>;
  in?: Maybe<Array<WalletType>>;
  notIn?: Maybe<Array<WalletType>>;
  not?: Maybe<NestedEnumWalletTypeFilter>;
};

export type NestedEnumWalletTypeWithAggregatesFilter = {
  equals?: Maybe<WalletType>;
  in?: Maybe<Array<WalletType>>;
  notIn?: Maybe<Array<WalletType>>;
  not?: Maybe<NestedEnumWalletTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWalletTypeFilter>;
  _max?: Maybe<NestedEnumWalletTypeFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type NestedFloatWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _max?: Maybe<NestedFloatFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
};

export type NestedJsonNullableFilter = {
  equals?: Maybe<Scalars['JSON']>;
  not?: Maybe<Scalars['JSON']>;
};

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedStringFilter>;
  _max?: Maybe<NestedStringFilter>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type PaginatationMetadata = {
  __typename?: 'PaginatationMetadata';
  currentPage: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginatedAdminRole = {
  __typename?: 'PaginatedAdminRole';
  items?: Maybe<Array<AdminRole>>;
  metadata: PaginatationMetadata;
};

export type PaginatedAdminUser = {
  __typename?: 'PaginatedAdminUser';
  items?: Maybe<Array<AdminUser>>;
  metadata: PaginatationMetadata;
};

export type PaginatedBalanceSheetReport = {
  __typename?: 'PaginatedBalanceSheetReport';
  items?: Maybe<Array<BalanceSheetReport>>;
  metadata: PaginatationMetadata;
};

export type PaginatedBlockchainNetwork = {
  __typename?: 'PaginatedBlockchainNetwork';
  items?: Maybe<Array<BlockchainNetwork>>;
  metadata: PaginatationMetadata;
};

export type PaginatedGameToken = {
  __typename?: 'PaginatedGameToken';
  items?: Maybe<Array<GameToken>>;
  metadata: PaginatationMetadata;
};

export type PaginatedGlobalSetting = {
  __typename?: 'PaginatedGlobalSetting';
  items?: Maybe<Array<GlobalSetting>>;
  metadata: PaginatationMetadata;
};

export type PaginatedInternalTransaction = {
  __typename?: 'PaginatedInternalTransaction';
  items?: Maybe<Array<InternalTransaction>>;
  metadata: PaginatationMetadata;
};

export type PaginatedLedgerTransaction = {
  __typename?: 'PaginatedLedgerTransaction';
  items?: Maybe<Array<LedgerTransaction>>;
  metadata: PaginatationMetadata;
};

export type PaginatedTransaction = {
  __typename?: 'PaginatedTransaction';
  items?: Maybe<Array<Transaction>>;
  metadata: PaginatationMetadata;
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  items?: Maybe<Array<User>>;
  metadata: PaginatationMetadata;
};

export type PaginatedWallet = {
  __typename?: 'PaginatedWallet';
  items?: Maybe<Array<Wallet>>;
  metadata: PaginatationMetadata;
};

export enum PaymentMethod {
  Duitnow = 'DUITNOW',
  Cash = 'CASH',
  OnlineTransfer = 'ONLINE_TRANSFER',
  Crypto = 'CRYPTO'
}

export type Query = {
  __typename?: 'Query';
  health: Scalars['String'];
  fetchRemoteConfig: AppRemoteConfig;
  me: AuthUserUnion;
  user?: Maybe<User>;
  findFirstUser?: Maybe<User>;
  users: Array<User>;
  aggregateUser: AggregateUser;
  groupByUser: Array<UserGroupBy>;
  getUser?: Maybe<User>;
  listUsers: PaginatedUser;
  adminUser?: Maybe<AdminUser>;
  findFirstAdminUser?: Maybe<AdminUser>;
  adminUsers: Array<AdminUser>;
  aggregateAdminUser: AggregateAdminUser;
  groupByAdminUser: Array<AdminUserGroupBy>;
  getAdminUser?: Maybe<AdminUser>;
  listAdminUsers: PaginatedAdminUser;
  adminRole?: Maybe<AdminRole>;
  findFirstAdminRole?: Maybe<AdminRole>;
  adminRoles: Array<AdminRole>;
  aggregateAdminRole: AggregateAdminRole;
  groupByAdminRole: Array<AdminRoleGroupBy>;
  getAdminRole?: Maybe<AdminRole>;
  listAdminRoles: PaginatedAdminRole;
  gameToken?: Maybe<GameToken>;
  findFirstGameToken?: Maybe<GameToken>;
  gameTokens: Array<GameToken>;
  aggregateGameToken: AggregateGameToken;
  groupByGameToken: Array<GameTokenGroupBy>;
  listRates?: Maybe<Array<GameTokenRateResult>>;
  getGameToken?: Maybe<GameToken>;
  listGameTokens: PaginatedGameToken;
  convertGameTokenRate?: Maybe<ConvertGameTokenRateResponse>;
  blockchainNetwork?: Maybe<BlockchainNetwork>;
  findFirstBlockchainNetwork?: Maybe<BlockchainNetwork>;
  blockchainNetworks: Array<BlockchainNetwork>;
  aggregateBlockchainNetwork: AggregateBlockchainNetwork;
  groupByBlockchainNetwork: Array<BlockchainNetworkGroupBy>;
  getBlockchainNetwork?: Maybe<BlockchainNetwork>;
  listBlockchainNetworks: PaginatedBlockchainNetwork;
  transaction?: Maybe<Transaction>;
  findFirstTransaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  aggregateTransaction: AggregateTransaction;
  groupByTransaction: Array<TransactionGroupBy>;
  getTransaction?: Maybe<Transaction>;
  listTransactions: PaginatedTransaction;
  getUserTransaction: Transaction;
  estimateTransaction?: Maybe<EstimateTransactionResponse>;
  adminEstimateTransaction?: Maybe<EstimateTransactionResponse>;
  wallet?: Maybe<Wallet>;
  findFirstWallet?: Maybe<Wallet>;
  wallets: Array<Wallet>;
  aggregateWallet: AggregateWallet;
  groupByWallet: Array<WalletGroupBy>;
  getWallet?: Maybe<Wallet>;
  listWallets: PaginatedWallet;
  ledgerTransaction?: Maybe<LedgerTransaction>;
  findFirstLedgerTransaction?: Maybe<LedgerTransaction>;
  ledgerTransactions: Array<LedgerTransaction>;
  aggregateLedgerTransaction: AggregateLedgerTransaction;
  groupByLedgerTransaction: Array<LedgerTransactionGroupBy>;
  getLedgerTransaction?: Maybe<LedgerTransaction>;
  listLedgerTransactions: PaginatedLedgerTransaction;
  globalSetting?: Maybe<GlobalSetting>;
  findFirstGlobalSetting?: Maybe<GlobalSetting>;
  globalSettings: Array<GlobalSetting>;
  aggregateGlobalSetting: AggregateGlobalSetting;
  groupByGlobalSetting: Array<GlobalSettingGroupBy>;
  getGlobalSetting?: Maybe<GlobalSetting>;
  listGlobalSettings: PaginatedGlobalSetting;
  balanceSheetReport?: Maybe<BalanceSheetReport>;
  findFirstBalanceSheetReport?: Maybe<BalanceSheetReport>;
  balanceSheetReports: Array<BalanceSheetReport>;
  aggregateBalanceSheetReport: AggregateBalanceSheetReport;
  groupByBalanceSheetReport: Array<BalanceSheetReportGroupBy>;
  getBalanceSheetReport?: Maybe<BalanceSheetReport>;
  listBalanceSheetReports: PaginatedBalanceSheetReport;
  internalTransaction?: Maybe<InternalTransaction>;
  findFirstInternalTransaction?: Maybe<InternalTransaction>;
  internalTransactions: Array<InternalTransaction>;
  aggregateInternalTransaction: AggregateInternalTransaction;
  groupByInternalTransaction: Array<InternalTransactionGroupBy>;
  getInternalTransaction?: Maybe<InternalTransaction>;
  listInternalTransactions: PaginatedInternalTransaction;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryFindFirstUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};


export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};


export type QueryAggregateUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithAggregationInput>>;
  by: Array<UserScalarFieldEnum>;
  having?: Maybe<UserScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetUserArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryListUsersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
};


export type QueryAdminUserArgs = {
  where: AdminUserWhereUniqueInput;
};


export type QueryFindFirstAdminUserArgs = {
  where?: Maybe<AdminUserWhereInput>;
  orderBy?: Maybe<Array<AdminUserOrderByWithRelationInput>>;
  cursor?: Maybe<AdminUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AdminUserScalarFieldEnum>>;
};


export type QueryAdminUsersArgs = {
  where?: Maybe<AdminUserWhereInput>;
  orderBy?: Maybe<Array<AdminUserOrderByWithRelationInput>>;
  cursor?: Maybe<AdminUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AdminUserScalarFieldEnum>>;
};


export type QueryAggregateAdminUserArgs = {
  where?: Maybe<AdminUserWhereInput>;
  orderBy?: Maybe<Array<AdminUserOrderByWithRelationInput>>;
  cursor?: Maybe<AdminUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByAdminUserArgs = {
  where?: Maybe<AdminUserWhereInput>;
  orderBy?: Maybe<Array<AdminUserOrderByWithAggregationInput>>;
  by: Array<AdminUserScalarFieldEnum>;
  having?: Maybe<AdminUserScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetAdminUserArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryListAdminUsersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<AdminUserWhereInput>;
  orderBy?: Maybe<Array<AdminUserOrderByWithRelationInput>>;
};


export type QueryAdminRoleArgs = {
  where: AdminRoleWhereUniqueInput;
};


export type QueryFindFirstAdminRoleArgs = {
  where?: Maybe<AdminRoleWhereInput>;
  orderBy?: Maybe<Array<AdminRoleOrderByWithRelationInput>>;
  cursor?: Maybe<AdminRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AdminRoleScalarFieldEnum>>;
};


export type QueryAdminRolesArgs = {
  where?: Maybe<AdminRoleWhereInput>;
  orderBy?: Maybe<Array<AdminRoleOrderByWithRelationInput>>;
  cursor?: Maybe<AdminRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AdminRoleScalarFieldEnum>>;
};


export type QueryAggregateAdminRoleArgs = {
  where?: Maybe<AdminRoleWhereInput>;
  orderBy?: Maybe<Array<AdminRoleOrderByWithRelationInput>>;
  cursor?: Maybe<AdminRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByAdminRoleArgs = {
  where?: Maybe<AdminRoleWhereInput>;
  orderBy?: Maybe<Array<AdminRoleOrderByWithAggregationInput>>;
  by: Array<AdminRoleScalarFieldEnum>;
  having?: Maybe<AdminRoleScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetAdminRoleArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryListAdminRolesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<AdminRoleWhereInput>;
  orderBy?: Maybe<Array<AdminRoleOrderByWithRelationInput>>;
};


export type QueryGameTokenArgs = {
  where: GameTokenWhereUniqueInput;
};


export type QueryFindFirstGameTokenArgs = {
  where?: Maybe<GameTokenWhereInput>;
  orderBy?: Maybe<Array<GameTokenOrderByWithRelationInput>>;
  cursor?: Maybe<GameTokenWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GameTokenScalarFieldEnum>>;
};


export type QueryGameTokensArgs = {
  where?: Maybe<GameTokenWhereInput>;
  orderBy?: Maybe<Array<GameTokenOrderByWithRelationInput>>;
  cursor?: Maybe<GameTokenWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GameTokenScalarFieldEnum>>;
};


export type QueryAggregateGameTokenArgs = {
  where?: Maybe<GameTokenWhereInput>;
  orderBy?: Maybe<Array<GameTokenOrderByWithRelationInput>>;
  cursor?: Maybe<GameTokenWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByGameTokenArgs = {
  where?: Maybe<GameTokenWhereInput>;
  orderBy?: Maybe<Array<GameTokenOrderByWithAggregationInput>>;
  by: Array<GameTokenScalarFieldEnum>;
  having?: Maybe<GameTokenScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetGameTokenArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryListGameTokensArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<GameTokenWhereInput>;
  orderBy?: Maybe<Array<GameTokenOrderByWithRelationInput>>;
};


export type QueryConvertGameTokenRateArgs = {
  fromCurrency: Scalars['String'];
  toCurrency: Scalars['String'];
};


export type QueryBlockchainNetworkArgs = {
  where: BlockchainNetworkWhereUniqueInput;
};


export type QueryFindFirstBlockchainNetworkArgs = {
  where?: Maybe<BlockchainNetworkWhereInput>;
  orderBy?: Maybe<Array<BlockchainNetworkOrderByWithRelationInput>>;
  cursor?: Maybe<BlockchainNetworkWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BlockchainNetworkScalarFieldEnum>>;
};


export type QueryBlockchainNetworksArgs = {
  where?: Maybe<BlockchainNetworkWhereInput>;
  orderBy?: Maybe<Array<BlockchainNetworkOrderByWithRelationInput>>;
  cursor?: Maybe<BlockchainNetworkWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BlockchainNetworkScalarFieldEnum>>;
};


export type QueryAggregateBlockchainNetworkArgs = {
  where?: Maybe<BlockchainNetworkWhereInput>;
  orderBy?: Maybe<Array<BlockchainNetworkOrderByWithRelationInput>>;
  cursor?: Maybe<BlockchainNetworkWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByBlockchainNetworkArgs = {
  where?: Maybe<BlockchainNetworkWhereInput>;
  orderBy?: Maybe<Array<BlockchainNetworkOrderByWithAggregationInput>>;
  by: Array<BlockchainNetworkScalarFieldEnum>;
  having?: Maybe<BlockchainNetworkScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetBlockchainNetworkArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryListBlockchainNetworksArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<BlockchainNetworkWhereInput>;
  orderBy?: Maybe<Array<BlockchainNetworkOrderByWithRelationInput>>;
};


export type QueryTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type QueryFindFirstTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<TransactionOrderByWithRelationInput>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<TransactionScalarFieldEnum>>;
};


export type QueryTransactionsArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<TransactionOrderByWithRelationInput>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<TransactionScalarFieldEnum>>;
};


export type QueryAggregateTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<TransactionOrderByWithRelationInput>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<TransactionOrderByWithAggregationInput>>;
  by: Array<TransactionScalarFieldEnum>;
  having?: Maybe<TransactionScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetTransactionArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryListTransactionsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<TransactionOrderByWithRelationInput>>;
};


export type QueryGetUserTransactionArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryEstimateTransactionArgs = {
  type: TransactionType;
  currency: Currency;
  fiat?: Maybe<Scalars['Float']>;
  cryptoCurrency: Scalars['String'];
  crypto?: Maybe<Scalars['Float']>;
};


export type QueryAdminEstimateTransactionArgs = {
  type: TransactionType;
  currency: Currency;
  fiat?: Maybe<Scalars['Float']>;
  cryptoCurrency: Scalars['String'];
  crypto?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};


export type QueryWalletArgs = {
  where: WalletWhereUniqueInput;
};


export type QueryFindFirstWalletArgs = {
  where?: Maybe<WalletWhereInput>;
  orderBy?: Maybe<Array<WalletOrderByWithRelationInput>>;
  cursor?: Maybe<WalletWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<WalletScalarFieldEnum>>;
};


export type QueryWalletsArgs = {
  where?: Maybe<WalletWhereInput>;
  orderBy?: Maybe<Array<WalletOrderByWithRelationInput>>;
  cursor?: Maybe<WalletWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<WalletScalarFieldEnum>>;
};


export type QueryAggregateWalletArgs = {
  where?: Maybe<WalletWhereInput>;
  orderBy?: Maybe<Array<WalletOrderByWithRelationInput>>;
  cursor?: Maybe<WalletWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByWalletArgs = {
  where?: Maybe<WalletWhereInput>;
  orderBy?: Maybe<Array<WalletOrderByWithAggregationInput>>;
  by: Array<WalletScalarFieldEnum>;
  having?: Maybe<WalletScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetWalletArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryListWalletsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<WalletWhereInput>;
  orderBy?: Maybe<Array<WalletOrderByWithRelationInput>>;
};


export type QueryLedgerTransactionArgs = {
  where: LedgerTransactionWhereUniqueInput;
};


export type QueryFindFirstLedgerTransactionArgs = {
  where?: Maybe<LedgerTransactionWhereInput>;
  orderBy?: Maybe<Array<LedgerTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<LedgerTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<LedgerTransactionScalarFieldEnum>>;
};


export type QueryLedgerTransactionsArgs = {
  where?: Maybe<LedgerTransactionWhereInput>;
  orderBy?: Maybe<Array<LedgerTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<LedgerTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<LedgerTransactionScalarFieldEnum>>;
};


export type QueryAggregateLedgerTransactionArgs = {
  where?: Maybe<LedgerTransactionWhereInput>;
  orderBy?: Maybe<Array<LedgerTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<LedgerTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByLedgerTransactionArgs = {
  where?: Maybe<LedgerTransactionWhereInput>;
  orderBy?: Maybe<Array<LedgerTransactionOrderByWithAggregationInput>>;
  by: Array<LedgerTransactionScalarFieldEnum>;
  having?: Maybe<LedgerTransactionScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetLedgerTransactionArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryListLedgerTransactionsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<LedgerTransactionWhereInput>;
  orderBy?: Maybe<Array<LedgerTransactionOrderByWithRelationInput>>;
};


export type QueryGlobalSettingArgs = {
  where: GlobalSettingWhereUniqueInput;
};


export type QueryFindFirstGlobalSettingArgs = {
  where?: Maybe<GlobalSettingWhereInput>;
  orderBy?: Maybe<Array<GlobalSettingOrderByWithRelationInput>>;
  cursor?: Maybe<GlobalSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GlobalSettingScalarFieldEnum>>;
};


export type QueryGlobalSettingsArgs = {
  where?: Maybe<GlobalSettingWhereInput>;
  orderBy?: Maybe<Array<GlobalSettingOrderByWithRelationInput>>;
  cursor?: Maybe<GlobalSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GlobalSettingScalarFieldEnum>>;
};


export type QueryAggregateGlobalSettingArgs = {
  where?: Maybe<GlobalSettingWhereInput>;
  orderBy?: Maybe<Array<GlobalSettingOrderByWithRelationInput>>;
  cursor?: Maybe<GlobalSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByGlobalSettingArgs = {
  where?: Maybe<GlobalSettingWhereInput>;
  orderBy?: Maybe<Array<GlobalSettingOrderByWithAggregationInput>>;
  by: Array<GlobalSettingScalarFieldEnum>;
  having?: Maybe<GlobalSettingScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetGlobalSettingArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryListGlobalSettingsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<GlobalSettingWhereInput>;
  orderBy?: Maybe<Array<GlobalSettingOrderByWithRelationInput>>;
};


export type QueryBalanceSheetReportArgs = {
  where: BalanceSheetReportWhereUniqueInput;
};


export type QueryFindFirstBalanceSheetReportArgs = {
  where?: Maybe<BalanceSheetReportWhereInput>;
  orderBy?: Maybe<Array<BalanceSheetReportOrderByWithRelationInput>>;
  cursor?: Maybe<BalanceSheetReportWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BalanceSheetReportScalarFieldEnum>>;
};


export type QueryBalanceSheetReportsArgs = {
  where?: Maybe<BalanceSheetReportWhereInput>;
  orderBy?: Maybe<Array<BalanceSheetReportOrderByWithRelationInput>>;
  cursor?: Maybe<BalanceSheetReportWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BalanceSheetReportScalarFieldEnum>>;
};


export type QueryAggregateBalanceSheetReportArgs = {
  where?: Maybe<BalanceSheetReportWhereInput>;
  orderBy?: Maybe<Array<BalanceSheetReportOrderByWithRelationInput>>;
  cursor?: Maybe<BalanceSheetReportWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByBalanceSheetReportArgs = {
  where?: Maybe<BalanceSheetReportWhereInput>;
  orderBy?: Maybe<Array<BalanceSheetReportOrderByWithAggregationInput>>;
  by: Array<BalanceSheetReportScalarFieldEnum>;
  having?: Maybe<BalanceSheetReportScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetBalanceSheetReportArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryListBalanceSheetReportsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<BalanceSheetReportWhereInput>;
  orderBy?: Maybe<Array<BalanceSheetReportOrderByWithRelationInput>>;
};


export type QueryInternalTransactionArgs = {
  where: InternalTransactionWhereUniqueInput;
};


export type QueryFindFirstInternalTransactionArgs = {
  where?: Maybe<InternalTransactionWhereInput>;
  orderBy?: Maybe<Array<InternalTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<InternalTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InternalTransactionScalarFieldEnum>>;
};


export type QueryInternalTransactionsArgs = {
  where?: Maybe<InternalTransactionWhereInput>;
  orderBy?: Maybe<Array<InternalTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<InternalTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InternalTransactionScalarFieldEnum>>;
};


export type QueryAggregateInternalTransactionArgs = {
  where?: Maybe<InternalTransactionWhereInput>;
  orderBy?: Maybe<Array<InternalTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<InternalTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByInternalTransactionArgs = {
  where?: Maybe<InternalTransactionWhereInput>;
  orderBy?: Maybe<Array<InternalTransactionOrderByWithAggregationInput>>;
  by: Array<InternalTransactionScalarFieldEnum>;
  having?: Maybe<InternalTransactionScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetInternalTransactionArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryListInternalTransactionsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<InternalTransactionWhereInput>;
  orderBy?: Maybe<Array<InternalTransactionOrderByWithRelationInput>>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RegisterInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  mobileNumber: Scalars['String'];
};

export type SignedFile = {
  __typename?: 'SignedFile';
  name: Scalars['String'];
  path: Scalars['String'];
  url: Scalars['String'];
  signedUrl: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Status {
  Active = 'active',
  Inactive = 'inactive'
}

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type StringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
};

export type StringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedStringFilter>;
  _max?: Maybe<NestedStringFilter>;
};

export type SupportedNetwork = {
  __typename?: 'SupportedNetwork';
  name: Scalars['String'];
  description: Scalars['String'];
};

export type SupportedPaymentMethods = {
  __typename?: 'SupportedPaymentMethods';
  method: PaymentMethod;
  currency: Currency;
};

export type SupportedToken = {
  __typename?: 'SupportedToken';
  cryptoCurrency: Scalars['String'];
  name: Scalars['String'];
  logo: Scalars['String'];
  networks?: Maybe<Array<SupportedNetwork>>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['String'];
  tokenId: Scalars['String'];
  userId: Scalars['String'];
  type: TransactionType;
  crypto: Scalars['Decimal'];
  fiat: Scalars['Decimal'];
  serviceCharge: Scalars['Decimal'];
  total: Scalars['Decimal'];
  currency: Currency;
  cryptoCurrency: Scalars['String'];
  paymentMethod: PaymentMethod;
  status: TransactionStatus;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  rate: Scalars['Decimal'];
  margin: Scalars['Decimal'];
  note?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  token: GameToken;
  user: User;
};

export type TransactionAvgAggregate = {
  __typename?: 'TransactionAvgAggregate';
  crypto?: Maybe<Scalars['Decimal']>;
  fiat?: Maybe<Scalars['Decimal']>;
  serviceCharge?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  rate?: Maybe<Scalars['Decimal']>;
  margin?: Maybe<Scalars['Decimal']>;
};

export type TransactionAvgOrderByAggregateInput = {
  crypto?: Maybe<SortOrder>;
  fiat?: Maybe<SortOrder>;
  serviceCharge?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  margin?: Maybe<SortOrder>;
};

export type TransactionCountAggregate = {
  __typename?: 'TransactionCountAggregate';
  id: Scalars['Int'];
  tokenId: Scalars['Int'];
  userId: Scalars['Int'];
  type: Scalars['Int'];
  crypto: Scalars['Int'];
  fiat: Scalars['Int'];
  serviceCharge: Scalars['Int'];
  total: Scalars['Int'];
  currency: Scalars['Int'];
  cryptoCurrency: Scalars['Int'];
  paymentMethod: Scalars['Int'];
  status: Scalars['Int'];
  depositMetadata: Scalars['Int'];
  withdrawalMetadata: Scalars['Int'];
  nonce: Scalars['Int'];
  qrCode: Scalars['Int'];
  rate: Scalars['Int'];
  margin: Scalars['Int'];
  note: Scalars['Int'];
  expiresAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TransactionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  tokenId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  crypto?: Maybe<SortOrder>;
  fiat?: Maybe<SortOrder>;
  serviceCharge?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  cryptoCurrency?: Maybe<SortOrder>;
  paymentMethod?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  depositMetadata?: Maybe<SortOrder>;
  withdrawalMetadata?: Maybe<SortOrder>;
  nonce?: Maybe<SortOrder>;
  qrCode?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  margin?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TransactionCreateInput = {
  id?: Maybe<Scalars['String']>;
  token: GameTokenCreateNestedOneWithoutTransactionsInput;
  user: UserCreateNestedOneWithoutTransactionsInput;
  type: TransactionType;
  crypto?: Maybe<Scalars['Decimal']>;
  fiat?: Maybe<Scalars['Decimal']>;
  serviceCharge?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Currency>;
  cryptoCurrency: Scalars['String'];
  paymentMethod: PaymentMethod;
  status?: Maybe<TransactionStatus>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Decimal']>;
  margin?: Maybe<Scalars['Decimal']>;
  note?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  tokenId: Scalars['String'];
  userId: Scalars['String'];
  type: TransactionType;
  crypto?: Maybe<Scalars['Decimal']>;
  fiat?: Maybe<Scalars['Decimal']>;
  serviceCharge?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Currency>;
  cryptoCurrency: Scalars['String'];
  paymentMethod: PaymentMethod;
  status?: Maybe<TransactionStatus>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Decimal']>;
  margin?: Maybe<Scalars['Decimal']>;
  note?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionCreateManyTokenInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  type: TransactionType;
  crypto?: Maybe<Scalars['Decimal']>;
  fiat?: Maybe<Scalars['Decimal']>;
  serviceCharge?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Currency>;
  cryptoCurrency: Scalars['String'];
  paymentMethod: PaymentMethod;
  status?: Maybe<TransactionStatus>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Decimal']>;
  margin?: Maybe<Scalars['Decimal']>;
  note?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionCreateManyTokenInputEnvelope = {
  data: Array<TransactionCreateManyTokenInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransactionCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  tokenId: Scalars['String'];
  type: TransactionType;
  crypto?: Maybe<Scalars['Decimal']>;
  fiat?: Maybe<Scalars['Decimal']>;
  serviceCharge?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Currency>;
  cryptoCurrency: Scalars['String'];
  paymentMethod: PaymentMethod;
  status?: Maybe<TransactionStatus>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Decimal']>;
  margin?: Maybe<Scalars['Decimal']>;
  note?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionCreateManyUserInputEnvelope = {
  data: Array<TransactionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransactionCreateNestedManyWithoutTokenInput = {
  create?: Maybe<Array<TransactionCreateWithoutTokenInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutTokenInput>>;
  createMany?: Maybe<TransactionCreateManyTokenInputEnvelope>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
};

export type TransactionCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<TransactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<TransactionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
};

export type TransactionCreateOrConnectWithoutTokenInput = {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateWithoutTokenInput;
};

export type TransactionCreateOrConnectWithoutUserInput = {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateWithoutUserInput;
};

export type TransactionCreateWithoutTokenInput = {
  id?: Maybe<Scalars['String']>;
  user: UserCreateNestedOneWithoutTransactionsInput;
  type: TransactionType;
  crypto?: Maybe<Scalars['Decimal']>;
  fiat?: Maybe<Scalars['Decimal']>;
  serviceCharge?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Currency>;
  cryptoCurrency: Scalars['String'];
  paymentMethod: PaymentMethod;
  status?: Maybe<TransactionStatus>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Decimal']>;
  margin?: Maybe<Scalars['Decimal']>;
  note?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  token: GameTokenCreateNestedOneWithoutTransactionsInput;
  type: TransactionType;
  crypto?: Maybe<Scalars['Decimal']>;
  fiat?: Maybe<Scalars['Decimal']>;
  serviceCharge?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Currency>;
  cryptoCurrency: Scalars['String'];
  paymentMethod: PaymentMethod;
  status?: Maybe<TransactionStatus>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Decimal']>;
  margin?: Maybe<Scalars['Decimal']>;
  note?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionGroupBy = {
  __typename?: 'TransactionGroupBy';
  id: Scalars['String'];
  tokenId: Scalars['String'];
  userId: Scalars['String'];
  type: TransactionType;
  crypto: Scalars['Decimal'];
  fiat: Scalars['Decimal'];
  serviceCharge: Scalars['Decimal'];
  total: Scalars['Decimal'];
  currency: Currency;
  cryptoCurrency: Scalars['String'];
  paymentMethod: PaymentMethod;
  status: TransactionStatus;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  rate: Scalars['Decimal'];
  margin: Scalars['Decimal'];
  note?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<TransactionCountAggregate>;
  _avg?: Maybe<TransactionAvgAggregate>;
  _sum?: Maybe<TransactionSumAggregate>;
  _min?: Maybe<TransactionMinAggregate>;
  _max?: Maybe<TransactionMaxAggregate>;
};

export type TransactionListRelationFilter = {
  every?: Maybe<TransactionWhereInput>;
  some?: Maybe<TransactionWhereInput>;
  none?: Maybe<TransactionWhereInput>;
};

export type TransactionMaxAggregate = {
  __typename?: 'TransactionMaxAggregate';
  id?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  type?: Maybe<TransactionType>;
  crypto?: Maybe<Scalars['Decimal']>;
  fiat?: Maybe<Scalars['Decimal']>;
  serviceCharge?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Currency>;
  cryptoCurrency?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status?: Maybe<TransactionStatus>;
  nonce?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Decimal']>;
  margin?: Maybe<Scalars['Decimal']>;
  note?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  tokenId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  crypto?: Maybe<SortOrder>;
  fiat?: Maybe<SortOrder>;
  serviceCharge?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  cryptoCurrency?: Maybe<SortOrder>;
  paymentMethod?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  nonce?: Maybe<SortOrder>;
  qrCode?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  margin?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TransactionMinAggregate = {
  __typename?: 'TransactionMinAggregate';
  id?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  type?: Maybe<TransactionType>;
  crypto?: Maybe<Scalars['Decimal']>;
  fiat?: Maybe<Scalars['Decimal']>;
  serviceCharge?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Currency>;
  cryptoCurrency?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status?: Maybe<TransactionStatus>;
  nonce?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Decimal']>;
  margin?: Maybe<Scalars['Decimal']>;
  note?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  tokenId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  crypto?: Maybe<SortOrder>;
  fiat?: Maybe<SortOrder>;
  serviceCharge?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  cryptoCurrency?: Maybe<SortOrder>;
  paymentMethod?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  nonce?: Maybe<SortOrder>;
  qrCode?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  margin?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type TransactionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  tokenId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  crypto?: Maybe<SortOrder>;
  fiat?: Maybe<SortOrder>;
  serviceCharge?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  cryptoCurrency?: Maybe<SortOrder>;
  paymentMethod?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  depositMetadata?: Maybe<SortOrder>;
  withdrawalMetadata?: Maybe<SortOrder>;
  nonce?: Maybe<SortOrder>;
  qrCode?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  margin?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<TransactionCountOrderByAggregateInput>;
  _avg?: Maybe<TransactionAvgOrderByAggregateInput>;
  _max?: Maybe<TransactionMaxOrderByAggregateInput>;
  _min?: Maybe<TransactionMinOrderByAggregateInput>;
  _sum?: Maybe<TransactionSumOrderByAggregateInput>;
};

export type TransactionOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  tokenId?: Maybe<SortOrder>;
  token?: Maybe<GameTokenOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  type?: Maybe<SortOrder>;
  crypto?: Maybe<SortOrder>;
  fiat?: Maybe<SortOrder>;
  serviceCharge?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  cryptoCurrency?: Maybe<SortOrder>;
  paymentMethod?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  depositMetadata?: Maybe<SortOrder>;
  withdrawalMetadata?: Maybe<SortOrder>;
  nonce?: Maybe<SortOrder>;
  qrCode?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  margin?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum TransactionScalarFieldEnum {
  Id = 'id',
  TokenId = 'tokenId',
  UserId = 'userId',
  Type = 'type',
  Crypto = 'crypto',
  Fiat = 'fiat',
  ServiceCharge = 'serviceCharge',
  Total = 'total',
  Currency = 'currency',
  CryptoCurrency = 'cryptoCurrency',
  PaymentMethod = 'paymentMethod',
  Status = 'status',
  DepositMetadata = 'depositMetadata',
  WithdrawalMetadata = 'withdrawalMetadata',
  Nonce = 'nonce',
  QrCode = 'qrCode',
  Rate = 'rate',
  Margin = 'margin',
  Note = 'note',
  ExpiresAt = 'expiresAt',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type TransactionScalarWhereInput = {
  AND?: Maybe<Array<TransactionScalarWhereInput>>;
  OR?: Maybe<Array<TransactionScalarWhereInput>>;
  NOT?: Maybe<Array<TransactionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  tokenId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  type?: Maybe<EnumTransactionTypeFilter>;
  crypto?: Maybe<DecimalFilter>;
  fiat?: Maybe<DecimalFilter>;
  serviceCharge?: Maybe<DecimalFilter>;
  total?: Maybe<DecimalFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  cryptoCurrency?: Maybe<StringFilter>;
  paymentMethod?: Maybe<EnumPaymentMethodFilter>;
  status?: Maybe<EnumTransactionStatusFilter>;
  depositMetadata?: Maybe<JsonNullableFilter>;
  withdrawalMetadata?: Maybe<JsonNullableFilter>;
  nonce?: Maybe<StringNullableFilter>;
  qrCode?: Maybe<StringNullableFilter>;
  rate?: Maybe<DecimalFilter>;
  margin?: Maybe<DecimalFilter>;
  note?: Maybe<StringNullableFilter>;
  expiresAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TransactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  tokenId?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumTransactionTypeWithAggregatesFilter>;
  crypto?: Maybe<DecimalWithAggregatesFilter>;
  fiat?: Maybe<DecimalWithAggregatesFilter>;
  serviceCharge?: Maybe<DecimalWithAggregatesFilter>;
  total?: Maybe<DecimalWithAggregatesFilter>;
  currency?: Maybe<EnumCurrencyWithAggregatesFilter>;
  cryptoCurrency?: Maybe<StringWithAggregatesFilter>;
  paymentMethod?: Maybe<EnumPaymentMethodWithAggregatesFilter>;
  status?: Maybe<EnumTransactionStatusWithAggregatesFilter>;
  depositMetadata?: Maybe<JsonNullableWithAggregatesFilter>;
  withdrawalMetadata?: Maybe<JsonNullableWithAggregatesFilter>;
  nonce?: Maybe<StringNullableWithAggregatesFilter>;
  qrCode?: Maybe<StringNullableWithAggregatesFilter>;
  rate?: Maybe<DecimalWithAggregatesFilter>;
  margin?: Maybe<DecimalWithAggregatesFilter>;
  note?: Maybe<StringNullableWithAggregatesFilter>;
  expiresAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum TransactionStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Transferred = 'TRANSFERRED',
  Completed = 'COMPLETED',
  Received = 'RECEIVED',
  Expired = 'EXPIRED',
  PayoutFailed = 'PAYOUT_FAILED',
  Refunded = 'REFUNDED',
  Cancelled = 'CANCELLED'
}

export type TransactionSumAggregate = {
  __typename?: 'TransactionSumAggregate';
  crypto?: Maybe<Scalars['Decimal']>;
  fiat?: Maybe<Scalars['Decimal']>;
  serviceCharge?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  rate?: Maybe<Scalars['Decimal']>;
  margin?: Maybe<Scalars['Decimal']>;
};

export type TransactionSumOrderByAggregateInput = {
  crypto?: Maybe<SortOrder>;
  fiat?: Maybe<SortOrder>;
  serviceCharge?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  margin?: Maybe<SortOrder>;
};

export enum TransactionType {
  Buy = 'BUY',
  Sell = 'SELL'
}

export type TransactionUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  token?: Maybe<GameTokenUpdateOneRequiredWithoutTransactionsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutTransactionsInput>;
  type?: Maybe<EnumTransactionTypeFieldUpdateOperationsInput>;
  crypto?: Maybe<DecimalFieldUpdateOperationsInput>;
  fiat?: Maybe<DecimalFieldUpdateOperationsInput>;
  serviceCharge?: Maybe<DecimalFieldUpdateOperationsInput>;
  total?: Maybe<DecimalFieldUpdateOperationsInput>;
  currency?: Maybe<EnumCurrencyFieldUpdateOperationsInput>;
  cryptoCurrency?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethod?: Maybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  status?: Maybe<EnumTransactionStatusFieldUpdateOperationsInput>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qrCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rate?: Maybe<DecimalFieldUpdateOperationsInput>;
  margin?: Maybe<DecimalFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiresAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransactionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumTransactionTypeFieldUpdateOperationsInput>;
  crypto?: Maybe<DecimalFieldUpdateOperationsInput>;
  fiat?: Maybe<DecimalFieldUpdateOperationsInput>;
  serviceCharge?: Maybe<DecimalFieldUpdateOperationsInput>;
  total?: Maybe<DecimalFieldUpdateOperationsInput>;
  currency?: Maybe<EnumCurrencyFieldUpdateOperationsInput>;
  cryptoCurrency?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethod?: Maybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  status?: Maybe<EnumTransactionStatusFieldUpdateOperationsInput>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qrCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rate?: Maybe<DecimalFieldUpdateOperationsInput>;
  margin?: Maybe<DecimalFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiresAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransactionUpdateManyWithWhereWithoutTokenInput = {
  where: TransactionScalarWhereInput;
  data: TransactionUpdateManyMutationInput;
};

export type TransactionUpdateManyWithWhereWithoutUserInput = {
  where: TransactionScalarWhereInput;
  data: TransactionUpdateManyMutationInput;
};

export type TransactionUpdateManyWithoutTokenInput = {
  create?: Maybe<Array<TransactionCreateWithoutTokenInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutTokenInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutTokenInput>>;
  createMany?: Maybe<TransactionCreateManyTokenInputEnvelope>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutTokenInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutTokenInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
};

export type TransactionUpdateManyWithoutUserInput = {
  create?: Maybe<Array<TransactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<TransactionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
};

export type TransactionUpdateWithWhereUniqueWithoutTokenInput = {
  where: TransactionWhereUniqueInput;
  data: TransactionUpdateWithoutTokenInput;
};

export type TransactionUpdateWithWhereUniqueWithoutUserInput = {
  where: TransactionWhereUniqueInput;
  data: TransactionUpdateWithoutUserInput;
};

export type TransactionUpdateWithoutTokenInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutTransactionsInput>;
  type?: Maybe<EnumTransactionTypeFieldUpdateOperationsInput>;
  crypto?: Maybe<DecimalFieldUpdateOperationsInput>;
  fiat?: Maybe<DecimalFieldUpdateOperationsInput>;
  serviceCharge?: Maybe<DecimalFieldUpdateOperationsInput>;
  total?: Maybe<DecimalFieldUpdateOperationsInput>;
  currency?: Maybe<EnumCurrencyFieldUpdateOperationsInput>;
  cryptoCurrency?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethod?: Maybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  status?: Maybe<EnumTransactionStatusFieldUpdateOperationsInput>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qrCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rate?: Maybe<DecimalFieldUpdateOperationsInput>;
  margin?: Maybe<DecimalFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiresAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransactionUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  token?: Maybe<GameTokenUpdateOneRequiredWithoutTransactionsInput>;
  type?: Maybe<EnumTransactionTypeFieldUpdateOperationsInput>;
  crypto?: Maybe<DecimalFieldUpdateOperationsInput>;
  fiat?: Maybe<DecimalFieldUpdateOperationsInput>;
  serviceCharge?: Maybe<DecimalFieldUpdateOperationsInput>;
  total?: Maybe<DecimalFieldUpdateOperationsInput>;
  currency?: Maybe<EnumCurrencyFieldUpdateOperationsInput>;
  cryptoCurrency?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethod?: Maybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  status?: Maybe<EnumTransactionStatusFieldUpdateOperationsInput>;
  depositMetadata?: Maybe<Scalars['JSON']>;
  withdrawalMetadata?: Maybe<Scalars['JSON']>;
  nonce?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qrCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rate?: Maybe<DecimalFieldUpdateOperationsInput>;
  margin?: Maybe<DecimalFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiresAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransactionUpsertWithWhereUniqueWithoutTokenInput = {
  where: TransactionWhereUniqueInput;
  update: TransactionUpdateWithoutTokenInput;
  create: TransactionCreateWithoutTokenInput;
};

export type TransactionUpsertWithWhereUniqueWithoutUserInput = {
  where: TransactionWhereUniqueInput;
  update: TransactionUpdateWithoutUserInput;
  create: TransactionCreateWithoutUserInput;
};

export type TransactionWhereInput = {
  AND?: Maybe<Array<TransactionWhereInput>>;
  OR?: Maybe<Array<TransactionWhereInput>>;
  NOT?: Maybe<Array<TransactionWhereInput>>;
  id?: Maybe<StringFilter>;
  tokenId?: Maybe<StringFilter>;
  token?: Maybe<GameTokenRelationFilter>;
  userId?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  type?: Maybe<EnumTransactionTypeFilter>;
  crypto?: Maybe<DecimalFilter>;
  fiat?: Maybe<DecimalFilter>;
  serviceCharge?: Maybe<DecimalFilter>;
  total?: Maybe<DecimalFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  cryptoCurrency?: Maybe<StringFilter>;
  paymentMethod?: Maybe<EnumPaymentMethodFilter>;
  status?: Maybe<EnumTransactionStatusFilter>;
  depositMetadata?: Maybe<JsonNullableFilter>;
  withdrawalMetadata?: Maybe<JsonNullableFilter>;
  nonce?: Maybe<StringNullableFilter>;
  qrCode?: Maybe<StringNullableFilter>;
  rate?: Maybe<DecimalFilter>;
  margin?: Maybe<DecimalFilter>;
  note?: Maybe<StringNullableFilter>;
  expiresAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  email: Scalars['String'];
  status: Status;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<UserCount>;
  transactions: Array<Transaction>;
};


export type UserTransactionsArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<TransactionOrderByWithRelationInput>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<TransactionScalarFieldEnum>>;
};

export type UserCount = {
  __typename?: 'UserCount';
  transactions: Scalars['Int'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  id: Scalars['Int'];
  firstName: Scalars['Int'];
  lastName: Scalars['Int'];
  mobileNumber: Scalars['Int'];
  email: Scalars['Int'];
  password: Scalars['Int'];
  status: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  mobileNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UserCreateInput = {
  id?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutUserInput>;
};

export type UserCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserCreateNestedOneWithoutTransactionsInput = {
  create?: Maybe<UserCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTransactionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateOrConnectWithoutTransactionsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutTransactionsInput;
};

export type UserCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  status: Status;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<UserCountAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _max?: Maybe<UserMaxAggregate>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  mobileNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  mobileNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  mobileNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<UserCountOrderByAggregateInput>;
  _max?: Maybe<UserMaxOrderByAggregateInput>;
  _min?: Maybe<UserMinOrderByAggregateInput>;
};

export type UserOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  mobileNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  transactions?: Maybe<TransactionOrderByRelationAggregateInput>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  Id = 'id',
  FirstName = 'firstName',
  LastName = 'lastName',
  MobileNumber = 'mobileNumber',
  Email = 'email',
  Password = 'password',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type UserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  firstName?: Maybe<StringWithAggregatesFilter>;
  lastName?: Maybe<StringWithAggregatesFilter>;
  mobileNumber?: Maybe<StringWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  password?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumStatusWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type UserUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<StringFieldUpdateOperationsInput>;
  mobileNumber?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutUserInput>;
};

export type UserUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<StringFieldUpdateOperationsInput>;
  mobileNumber?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutTransactionsInput = {
  create?: Maybe<UserCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTransactionsInput>;
  upsert?: Maybe<UserUpsertWithoutTransactionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutTransactionsInput>;
};

export type UserUpdateWithoutTransactionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<StringFieldUpdateOperationsInput>;
  mobileNumber?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpsertWithoutTransactionsInput = {
  update: UserUpdateWithoutTransactionsInput;
  create: UserCreateWithoutTransactionsInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  id?: Maybe<StringFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  mobileNumber?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  password?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumStatusFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  transactions?: Maybe<TransactionListRelationFilter>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type Wallet = {
  __typename?: 'Wallet';
  id: Scalars['String'];
  qrCode?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: WalletType;
  currency: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  balance: Scalars['Decimal'];
  status: Status;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<WalletCount>;
  ledgers: Array<LedgerTransaction>;
};


export type WalletLedgersArgs = {
  where?: Maybe<LedgerTransactionWhereInput>;
  orderBy?: Maybe<Array<LedgerTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<LedgerTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<LedgerTransactionScalarFieldEnum>>;
};

export type WalletAvgAggregate = {
  __typename?: 'WalletAvgAggregate';
  balance?: Maybe<Scalars['Decimal']>;
};

export type WalletAvgOrderByAggregateInput = {
  balance?: Maybe<SortOrder>;
};

export type WalletCount = {
  __typename?: 'WalletCount';
  ledgers: Scalars['Int'];
};

export type WalletCountAggregate = {
  __typename?: 'WalletCountAggregate';
  id: Scalars['Int'];
  qrCode: Scalars['Int'];
  title: Scalars['Int'];
  type: Scalars['Int'];
  currency: Scalars['Int'];
  metadata: Scalars['Int'];
  balance: Scalars['Int'];
  status: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type WalletCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  qrCode?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  metadata?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type WalletCreateInput = {
  id?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: WalletType;
  currency: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  balance?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ledgers?: Maybe<LedgerTransactionCreateNestedManyWithoutWalletInput>;
};

export type WalletCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: WalletType;
  currency: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  balance?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WalletCreateNestedOneWithoutLedgersInput = {
  create?: Maybe<WalletCreateWithoutLedgersInput>;
  connectOrCreate?: Maybe<WalletCreateOrConnectWithoutLedgersInput>;
  connect?: Maybe<WalletWhereUniqueInput>;
};

export type WalletCreateOrConnectWithoutLedgersInput = {
  where: WalletWhereUniqueInput;
  create: WalletCreateWithoutLedgersInput;
};

export type WalletCreateWithoutLedgersInput = {
  id?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: WalletType;
  currency: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  balance?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WalletGroupBy = {
  __typename?: 'WalletGroupBy';
  id: Scalars['String'];
  qrCode?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: WalletType;
  currency: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  balance: Scalars['Decimal'];
  status: Status;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<WalletCountAggregate>;
  _avg?: Maybe<WalletAvgAggregate>;
  _sum?: Maybe<WalletSumAggregate>;
  _min?: Maybe<WalletMinAggregate>;
  _max?: Maybe<WalletMaxAggregate>;
};

export type WalletMaxAggregate = {
  __typename?: 'WalletMaxAggregate';
  id?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<WalletType>;
  currency?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WalletMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  qrCode?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type WalletMinAggregate = {
  __typename?: 'WalletMinAggregate';
  id?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<WalletType>;
  currency?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WalletMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  qrCode?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type WalletOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  qrCode?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  metadata?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<WalletCountOrderByAggregateInput>;
  _avg?: Maybe<WalletAvgOrderByAggregateInput>;
  _max?: Maybe<WalletMaxOrderByAggregateInput>;
  _min?: Maybe<WalletMinOrderByAggregateInput>;
  _sum?: Maybe<WalletSumOrderByAggregateInput>;
};

export type WalletOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  qrCode?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  metadata?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  ledgers?: Maybe<LedgerTransactionOrderByRelationAggregateInput>;
};

export type WalletRelationFilter = {
  is?: Maybe<WalletWhereInput>;
  isNot?: Maybe<WalletWhereInput>;
};

export enum WalletScalarFieldEnum {
  Id = 'id',
  QrCode = 'qrCode',
  Title = 'title',
  Type = 'type',
  Currency = 'currency',
  Metadata = 'metadata',
  Balance = 'balance',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type WalletScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<WalletScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<WalletScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<WalletScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  qrCode?: Maybe<StringNullableWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumWalletTypeWithAggregatesFilter>;
  currency?: Maybe<StringWithAggregatesFilter>;
  metadata?: Maybe<JsonNullableWithAggregatesFilter>;
  balance?: Maybe<DecimalWithAggregatesFilter>;
  status?: Maybe<EnumStatusWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type WalletSumAggregate = {
  __typename?: 'WalletSumAggregate';
  balance?: Maybe<Scalars['Decimal']>;
};

export type WalletSumOrderByAggregateInput = {
  balance?: Maybe<SortOrder>;
};

export enum WalletType {
  Crypto = 'CRYPTO',
  Fiat = 'FIAT'
}

export type WalletUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  qrCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumWalletTypeFieldUpdateOperationsInput>;
  currency?: Maybe<StringFieldUpdateOperationsInput>;
  metadata?: Maybe<Scalars['JSON']>;
  balance?: Maybe<DecimalFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  ledgers?: Maybe<LedgerTransactionUpdateManyWithoutWalletInput>;
};

export type WalletUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  qrCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumWalletTypeFieldUpdateOperationsInput>;
  currency?: Maybe<StringFieldUpdateOperationsInput>;
  metadata?: Maybe<Scalars['JSON']>;
  balance?: Maybe<DecimalFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WalletUpdateOneRequiredWithoutLedgersInput = {
  create?: Maybe<WalletCreateWithoutLedgersInput>;
  connectOrCreate?: Maybe<WalletCreateOrConnectWithoutLedgersInput>;
  upsert?: Maybe<WalletUpsertWithoutLedgersInput>;
  connect?: Maybe<WalletWhereUniqueInput>;
  update?: Maybe<WalletUpdateWithoutLedgersInput>;
};

export type WalletUpdateWithoutLedgersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  qrCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumWalletTypeFieldUpdateOperationsInput>;
  currency?: Maybe<StringFieldUpdateOperationsInput>;
  metadata?: Maybe<Scalars['JSON']>;
  balance?: Maybe<DecimalFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WalletUpsertWithoutLedgersInput = {
  update: WalletUpdateWithoutLedgersInput;
  create: WalletCreateWithoutLedgersInput;
};

export type WalletWhereInput = {
  AND?: Maybe<Array<WalletWhereInput>>;
  OR?: Maybe<Array<WalletWhereInput>>;
  NOT?: Maybe<Array<WalletWhereInput>>;
  id?: Maybe<StringFilter>;
  qrCode?: Maybe<StringNullableFilter>;
  title?: Maybe<StringFilter>;
  type?: Maybe<EnumWalletTypeFilter>;
  currency?: Maybe<StringFilter>;
  metadata?: Maybe<JsonNullableFilter>;
  balance?: Maybe<DecimalFilter>;
  status?: Maybe<EnumStatusFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  ledgers?: Maybe<LedgerTransactionListRelationFilter>;
};

export type WalletWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type RegularAdminUserFragment = (
  { __typename?: 'AdminUser' }
  & Pick<AdminUser, 'id' | 'username' | 'email' | 'status' | 'createdAt' | 'updatedAt' | 'roleId'>
  & { role?: Maybe<(
    { __typename?: 'AdminRole' }
    & Pick<AdminRole, 'name'>
  )> }
);

export type Regular_Balance_Sheet_ReportFragment = (
  { __typename?: 'BalanceSheetReport' }
  & Pick<BalanceSheetReport, 'id' | 'date' | 'total' | 'metadata' | 'gainLoss' | 'createdAt' | 'updatedAt'>
);

export type RegularBlockchainNetworkFragment = (
  { __typename?: 'BlockchainNetwork' }
  & Pick<BlockchainNetwork, 'id' | 'name' | 'description' | 'createdAt' | 'updatedAt'>
);

export type RegularGameTokenFragment = (
  { __typename?: 'GameToken' }
  & Pick<GameToken, 'logo' | 'id' | 'name' | 'cryptoCurrency' | 'status' | 'coinId' | 'buyMargin' | 'sellMargin' | 'symbol' | 'createdAt' | 'updatedAt'>
  & { blockchainNetworks?: Maybe<Array<(
    { __typename?: 'BlockchainNetwork' }
    & Pick<BlockchainNetwork, 'id' | 'name' | 'description'>
  )>> }
);

export type RegularInternalTransactionFragment = (
  { __typename?: 'InternalTransaction' }
  & Pick<InternalTransaction, 'id' | 'type' | 'typeDefinition' | 'withdrawalMetadata' | 'depositMetadata' | 'note' | 'createdAt' | 'updatedAt'>
  & { admin: (
    { __typename?: 'AdminUser' }
    & Pick<AdminUser, 'username' | 'email'>
  ) }
);

export type RegularLedgerTransactionFragment = (
  { __typename?: 'LedgerTransaction' }
  & Pick<LedgerTransaction, 'id' | 'type' | 'amount' | 'balance' | 'previousBalance' | 'rate' | 'description' | 'totalValue' | 'createdAt' | 'updatedAt' | 'referenceId' | 'referenceType' | 'walletId'>
);

export type RegularTransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'tokenId' | 'userId' | 'type' | 'crypto' | 'fiat' | 'serviceCharge' | 'total' | 'margin' | 'currency' | 'cryptoCurrency' | 'paymentMethod' | 'status' | 'depositMetadata' | 'withdrawalMetadata' | 'qrCode' | 'nonce' | 'rate' | 'note' | 'createdAt' | 'updatedAt'>
  & { token: (
    { __typename?: 'GameToken' }
    & Pick<GameToken, 'id' | 'logo' | 'name' | 'cryptoCurrency'>
    & { blockchainNetworks?: Maybe<Array<(
      { __typename?: 'BlockchainNetwork' }
      & Pick<BlockchainNetwork, 'id' | 'name' | 'description'>
    )>> }
  ), user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'lastName' | 'firstName' | 'email' | 'mobileNumber'>
  ) }
);

export type RegularUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'lastName' | 'firstName' | 'email' | 'mobileNumber' | 'status' | 'createdAt' | 'updatedAt'>
);

export type RegularWalletFragment = (
  { __typename?: 'Wallet' }
  & Pick<Wallet, 'id' | 'title' | 'qrCode' | 'type' | 'currency' | 'metadata' | 'status' | 'createdAt' | 'updatedAt' | 'balance'>
  & { ledgers: Array<(
    { __typename?: 'LedgerTransaction' }
    & Pick<LedgerTransaction, 'type' | 'amount' | 'balance'>
  )> }
);

export type AdjustWalletBalanceMutationVariables = Exact<{
  data: AdjustWalletBalanceInput;
}>;


export type AdjustWalletBalanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adjustWalletBalance'>
);

export type AdminLoginMutationVariables = Exact<{
  data: AdminLoginInput;
}>;


export type AdminLoginMutation = (
  { __typename?: 'Mutation' }
  & { adminLogin: (
    { __typename?: 'Auth' }
    & Pick<Auth, 'accessToken' | 'expiresIn' | 'refreshToken' | 'refreshExpiresIn'>
  ) }
);

export type AdminMakeTransactionMutationVariables = Exact<{
  data: AdminMakeTransactionInput;
}>;


export type AdminMakeTransactionMutation = (
  { __typename?: 'Mutation' }
  & { adminMakeTransaction: (
    { __typename?: 'Transaction' }
    & RegularTransactionFragment
  ) }
);

export type AdminUpdateTransactionMutationVariables = Exact<{
  data: AdminUpdateTransactionInput;
  where: TransactionWhereUniqueInput;
}>;


export type AdminUpdateTransactionMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateTransaction: (
    { __typename?: 'Transaction' }
    & RegularTransactionFragment
  ) }
);

export type CreateAdminUserMutationVariables = Exact<{
  data: AdminUserCreateInput;
}>;


export type CreateAdminUserMutation = (
  { __typename?: 'Mutation' }
  & { createAdminUser: (
    { __typename?: 'AdminUser' }
    & RegularAdminUserFragment
  ) }
);

export type CreateGameTokenMutationVariables = Exact<{
  data: GameTokenCreateInput;
}>;


export type CreateGameTokenMutation = (
  { __typename?: 'Mutation' }
  & { createGameToken: (
    { __typename?: 'GameToken' }
    & RegularGameTokenFragment
  ) }
);

export type CreateUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & RegularUserFragment
  ) }
);

export type CreateWalletMutationVariables = Exact<{
  data: WalletCreateInput;
}>;


export type CreateWalletMutation = (
  { __typename?: 'Mutation' }
  & { createWallet: (
    { __typename?: 'Wallet' }
    & RegularWalletFragment
  ) }
);

export type DeleteAdminUserMutationVariables = Exact<{
  where: AdminUserWhereUniqueInput;
}>;


export type DeleteAdminUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteAdminUser?: Maybe<(
    { __typename?: 'AdminUser' }
    & RegularAdminUserFragment
  )> }
);

export type DeleteGameTokenMutationVariables = Exact<{
  where: GameTokenWhereUniqueInput;
}>;


export type DeleteGameTokenMutation = (
  { __typename?: 'Mutation' }
  & { deleteGameToken?: Maybe<(
    { __typename?: 'GameToken' }
    & RegularGameTokenFragment
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'User' }
    & RegularUserFragment
  )> }
);

export type ExchangeWalletsMutationVariables = Exact<{
  data: ExchangeWalletsInput;
}>;


export type ExchangeWalletsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exchangeWallets'>
);

export type GeneratePresignedFileMutationVariables = Exact<{
  data: GeneratePresignedFileInput;
}>;


export type GeneratePresignedFileMutation = (
  { __typename?: 'Mutation' }
  & { generatePresignedFile: (
    { __typename?: 'SignedFile' }
    & Pick<SignedFile, 'name' | 'path' | 'url' | 'signedUrl'>
  ) }
);

export type RefreshTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type RefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { refreshToken: (
    { __typename?: 'Auth' }
    & Pick<Auth, 'expiresIn' | 'accessToken' | 'refreshToken' | 'refreshExpiresIn'>
  ) }
);

export type UpdateAdminUserMutationVariables = Exact<{
  data: AdminUserUpdateInput;
  where: AdminUserWhereUniqueInput;
}>;


export type UpdateAdminUserMutation = (
  { __typename?: 'Mutation' }
  & { updateAdminUser?: Maybe<(
    { __typename?: 'AdminUser' }
    & RegularAdminUserFragment
  )> }
);

export type UpdateGameTokenMutationVariables = Exact<{
  data: GameTokenUpdateInput;
  where: GameTokenWhereUniqueInput;
}>;


export type UpdateGameTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateGameToken?: Maybe<(
    { __typename?: 'GameToken' }
    & RegularGameTokenFragment
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & RegularUserFragment
  )> }
);

export type UpdateWalletMutationVariables = Exact<{
  data: WalletUpdateInput;
  where: WalletWhereUniqueInput;
}>;


export type UpdateWalletMutation = (
  { __typename?: 'Mutation' }
  & { updateWallet?: Maybe<(
    { __typename?: 'Wallet' }
    & RegularWalletFragment
  )> }
);

export type AdminEstimateTransactionQueryVariables = Exact<{
  type: TransactionType;
  currency: Currency;
  fiat?: Maybe<Scalars['Float']>;
  cryptoCurrency: Scalars['String'];
  crypto?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
}>;


export type AdminEstimateTransactionQuery = (
  { __typename?: 'Query' }
  & { adminEstimateTransaction?: Maybe<(
    { __typename?: 'EstimateTransactionResponse' }
    & Pick<EstimateTransactionResponse, 'currency' | 'cryptoCurrency' | 'cryptoRate' | 'crypto' | 'fiat'>
  )> }
);

export type ConvertGameTokenRateQueryVariables = Exact<{
  fromCurrency: Scalars['String'];
  toCurrency: Scalars['String'];
}>;


export type ConvertGameTokenRateQuery = (
  { __typename?: 'Query' }
  & { convertGameTokenRate?: Maybe<(
    { __typename?: 'ConvertGameTokenRateResponse' }
    & Pick<ConvertGameTokenRateResponse, 'fromCurrency' | 'toCurrency' | 'conversionRate'>
  )> }
);

export type FindFirstAdminUserQueryVariables = Exact<{
  where: AdminUserWhereInput;
}>;


export type FindFirstAdminUserQuery = (
  { __typename?: 'Query' }
  & { findFirstAdminUser?: Maybe<(
    { __typename?: 'AdminUser' }
    & RegularAdminUserFragment
  )> }
);

export type FindFirstGameTokenQueryVariables = Exact<{
  where: GameTokenWhereInput;
}>;


export type FindFirstGameTokenQuery = (
  { __typename?: 'Query' }
  & { findFirstGameToken?: Maybe<(
    { __typename?: 'GameToken' }
    & RegularGameTokenFragment
  )> }
);

export type FindFirstUserQueryVariables = Exact<{
  where: UserWhereInput;
}>;


export type FindFirstUserQuery = (
  { __typename?: 'Query' }
  & { findFirstUser?: Maybe<(
    { __typename?: 'User' }
    & RegularUserFragment
  )> }
);

export type FindFirstWalletQueryVariables = Exact<{
  where: WalletWhereInput;
}>;


export type FindFirstWalletQuery = (
  { __typename?: 'Query' }
  & { findFirstWallet?: Maybe<(
    { __typename?: 'Wallet' }
    & RegularWalletFragment
  )> }
);

export type ListAdminRolesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<AdminRoleWhereInput>;
  orderBy?: Maybe<Array<AdminRoleOrderByWithRelationInput> | AdminRoleOrderByWithRelationInput>;
}>;


export type ListAdminRolesQuery = (
  { __typename?: 'Query' }
  & { listAdminRoles: (
    { __typename?: 'PaginatedAdminRole' }
    & { items?: Maybe<Array<(
      { __typename?: 'AdminRole' }
      & Pick<AdminRole, 'id' | 'name' | 'slug' | 'description' | 'createdAt' | 'updatedAt'>
    )>>, metadata: (
      { __typename?: 'PaginatationMetadata' }
      & Pick<PaginatationMetadata, 'currentPage' | 'perPage' | 'totalCount' | 'totalPages'>
    ) }
  ) }
);

export type ListAdminUsersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<AdminUserWhereInput>;
  orderBy?: Maybe<Array<AdminUserOrderByWithRelationInput> | AdminUserOrderByWithRelationInput>;
}>;


export type ListAdminUsersQuery = (
  { __typename?: 'Query' }
  & { listAdminUsers: (
    { __typename?: 'PaginatedAdminUser' }
    & { items?: Maybe<Array<(
      { __typename?: 'AdminUser' }
      & { role?: Maybe<(
        { __typename?: 'AdminRole' }
        & Pick<AdminRole, 'name'>
      )> }
      & RegularAdminUserFragment
    )>>, metadata: (
      { __typename?: 'PaginatationMetadata' }
      & Pick<PaginatationMetadata, 'currentPage' | 'perPage' | 'totalCount' | 'totalPages'>
    ) }
  ) }
);

export type ListBalanceSheetReportsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<BalanceSheetReportWhereInput>;
  orderBy?: Maybe<Array<BalanceSheetReportOrderByWithRelationInput> | BalanceSheetReportOrderByWithRelationInput>;
}>;


export type ListBalanceSheetReportsQuery = (
  { __typename?: 'Query' }
  & { listBalanceSheetReports: (
    { __typename?: 'PaginatedBalanceSheetReport' }
    & { items?: Maybe<Array<(
      { __typename?: 'BalanceSheetReport' }
      & Regular_Balance_Sheet_ReportFragment
    )>>, metadata: (
      { __typename?: 'PaginatationMetadata' }
      & Pick<PaginatationMetadata, 'currentPage' | 'perPage' | 'totalCount' | 'totalPages'>
    ) }
  ) }
);

export type ListBlockchainNetworksQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<BlockchainNetworkWhereInput>;
  orderBy?: Maybe<Array<BlockchainNetworkOrderByWithRelationInput> | BlockchainNetworkOrderByWithRelationInput>;
}>;


export type ListBlockchainNetworksQuery = (
  { __typename?: 'Query' }
  & { listBlockchainNetworks: (
    { __typename?: 'PaginatedBlockchainNetwork' }
    & { items?: Maybe<Array<(
      { __typename?: 'BlockchainNetwork' }
      & RegularBlockchainNetworkFragment
    )>>, metadata: (
      { __typename?: 'PaginatationMetadata' }
      & Pick<PaginatationMetadata, 'currentPage' | 'perPage' | 'totalCount' | 'totalPages'>
    ) }
  ) }
);

export type ListGameTokensQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<GameTokenWhereInput>;
  orderBy?: Maybe<Array<GameTokenOrderByWithRelationInput> | GameTokenOrderByWithRelationInput>;
}>;


export type ListGameTokensQuery = (
  { __typename?: 'Query' }
  & { listGameTokens: (
    { __typename?: 'PaginatedGameToken' }
    & { items?: Maybe<Array<(
      { __typename?: 'GameToken' }
      & RegularGameTokenFragment
    )>>, metadata: (
      { __typename?: 'PaginatationMetadata' }
      & Pick<PaginatationMetadata, 'currentPage' | 'perPage' | 'totalCount' | 'totalPages'>
    ) }
  ) }
);

export type ListInternalTransactionsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<InternalTransactionWhereInput>;
  orderBy?: Maybe<Array<InternalTransactionOrderByWithRelationInput> | InternalTransactionOrderByWithRelationInput>;
}>;


export type ListInternalTransactionsQuery = (
  { __typename?: 'Query' }
  & { listInternalTransactions: (
    { __typename?: 'PaginatedInternalTransaction' }
    & { items?: Maybe<Array<(
      { __typename?: 'InternalTransaction' }
      & RegularInternalTransactionFragment
    )>>, metadata: (
      { __typename?: 'PaginatationMetadata' }
      & Pick<PaginatationMetadata, 'currentPage' | 'perPage' | 'totalCount' | 'totalPages'>
    ) }
  ) }
);

export type ListLedgerTransactionsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<LedgerTransactionWhereInput>;
  orderBy?: Maybe<Array<LedgerTransactionOrderByWithRelationInput> | LedgerTransactionOrderByWithRelationInput>;
}>;


export type ListLedgerTransactionsQuery = (
  { __typename?: 'Query' }
  & { listLedgerTransactions: (
    { __typename?: 'PaginatedLedgerTransaction' }
    & { items?: Maybe<Array<(
      { __typename?: 'LedgerTransaction' }
      & RegularLedgerTransactionFragment
    )>>, metadata: (
      { __typename?: 'PaginatationMetadata' }
      & Pick<PaginatationMetadata, 'currentPage' | 'perPage' | 'totalCount' | 'totalPages'>
    ) }
  ) }
);

export type ListTransactionsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<TransactionOrderByWithRelationInput> | TransactionOrderByWithRelationInput>;
}>;


export type ListTransactionsQuery = (
  { __typename?: 'Query' }
  & { listTransactions: (
    { __typename?: 'PaginatedTransaction' }
    & { items?: Maybe<Array<(
      { __typename?: 'Transaction' }
      & RegularTransactionFragment
    )>>, metadata: (
      { __typename?: 'PaginatationMetadata' }
      & Pick<PaginatationMetadata, 'currentPage' | 'perPage' | 'totalCount' | 'totalPages'>
    ) }
  ) }
);

export type ListUsersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput> | UserOrderByWithRelationInput>;
}>;


export type ListUsersQuery = (
  { __typename?: 'Query' }
  & { listUsers: (
    { __typename?: 'PaginatedUser' }
    & { items?: Maybe<Array<(
      { __typename?: 'User' }
      & RegularUserFragment
    )>>, metadata: (
      { __typename?: 'PaginatationMetadata' }
      & Pick<PaginatationMetadata, 'currentPage' | 'perPage' | 'totalCount' | 'totalPages'>
    ) }
  ) }
);

export type ListWalletsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  where?: Maybe<WalletWhereInput>;
  orderBy?: Maybe<Array<WalletOrderByWithRelationInput> | WalletOrderByWithRelationInput>;
}>;


export type ListWalletsQuery = (
  { __typename?: 'Query' }
  & { listWallets: (
    { __typename?: 'PaginatedWallet' }
    & { items?: Maybe<Array<(
      { __typename?: 'Wallet' }
      & RegularWalletFragment
    )>>, metadata: (
      { __typename?: 'PaginatationMetadata' }
      & Pick<PaginatationMetadata, 'currentPage' | 'perPage' | 'totalCount' | 'totalPages'>
    ) }
  ) }
);

export const RegularAdminUserFragmentDoc = gql`
    fragment RegularAdminUser on AdminUser {
  id
  username
  email
  status
  createdAt
  updatedAt
  roleId
  role {
    name
  }
}
    `;
export const Regular_Balance_Sheet_ReportFragmentDoc = gql`
    fragment REGULAR_BALANCE_SHEET_REPORT on BalanceSheetReport {
  id
  date
  total
  metadata
  gainLoss
  createdAt
  updatedAt
}
    `;
export const RegularBlockchainNetworkFragmentDoc = gql`
    fragment RegularBlockchainNetwork on BlockchainNetwork {
  id
  name
  description
  createdAt
  updatedAt
}
    `;
export const RegularGameTokenFragmentDoc = gql`
    fragment RegularGameToken on GameToken {
  logo
  id
  name
  cryptoCurrency
  status
  coinId
  buyMargin
  sellMargin
  symbol
  createdAt
  updatedAt
  blockchainNetworks {
    id
    name
    description
  }
}
    `;
export const RegularInternalTransactionFragmentDoc = gql`
    fragment RegularInternalTransaction on InternalTransaction {
  id
  type
  typeDefinition
  withdrawalMetadata
  depositMetadata
  note
  createdAt
  updatedAt
  admin {
    username
    email
  }
}
    `;
export const RegularLedgerTransactionFragmentDoc = gql`
    fragment RegularLedgerTransaction on LedgerTransaction {
  id
  type
  amount
  balance
  previousBalance
  rate
  description
  totalValue
  createdAt
  updatedAt
  referenceId
  referenceType
  walletId
}
    `;
export const RegularTransactionFragmentDoc = gql`
    fragment RegularTransaction on Transaction {
  id
  tokenId
  userId
  token {
    id
    logo
    name
    cryptoCurrency
    blockchainNetworks {
      id
      name
      description
    }
  }
  user {
    id
    lastName
    firstName
    email
    mobileNumber
  }
  type
  crypto
  fiat
  serviceCharge
  total
  margin
  currency
  cryptoCurrency
  paymentMethod
  status
  depositMetadata
  withdrawalMetadata
  qrCode
  nonce
  rate
  note
  createdAt
  updatedAt
}
    `;
export const RegularUserFragmentDoc = gql`
    fragment RegularUser on User {
  id
  lastName
  firstName
  email
  mobileNumber
  status
  createdAt
  updatedAt
}
    `;
export const RegularWalletFragmentDoc = gql`
    fragment RegularWallet on Wallet {
  id
  title
  qrCode
  type
  currency
  metadata
  status
  createdAt
  updatedAt
  balance
  ledgers {
    type
    amount
    balance
  }
}
    `;
export const AdjustWalletBalanceDocument = gql`
    mutation adjustWalletBalance($data: AdjustWalletBalanceInput!) {
  adjustWalletBalance(data: $data)
}
    `;
export type AdjustWalletBalanceMutationFn = Apollo.MutationFunction<AdjustWalletBalanceMutation, AdjustWalletBalanceMutationVariables>;

/**
 * __useAdjustWalletBalanceMutation__
 *
 * To run a mutation, you first call `useAdjustWalletBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdjustWalletBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adjustWalletBalanceMutation, { data, loading, error }] = useAdjustWalletBalanceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdjustWalletBalanceMutation(baseOptions?: Apollo.MutationHookOptions<AdjustWalletBalanceMutation, AdjustWalletBalanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdjustWalletBalanceMutation, AdjustWalletBalanceMutationVariables>(AdjustWalletBalanceDocument, options);
      }
export type AdjustWalletBalanceMutationHookResult = ReturnType<typeof useAdjustWalletBalanceMutation>;
export type AdjustWalletBalanceMutationResult = Apollo.MutationResult<AdjustWalletBalanceMutation>;
export type AdjustWalletBalanceMutationOptions = Apollo.BaseMutationOptions<AdjustWalletBalanceMutation, AdjustWalletBalanceMutationVariables>;
export const AdminLoginDocument = gql`
    mutation adminLogin($data: AdminLoginInput!) {
  adminLogin(data: $data) {
    accessToken
    expiresIn
    refreshToken
    refreshExpiresIn
  }
}
    `;
export type AdminLoginMutationFn = Apollo.MutationFunction<AdminLoginMutation, AdminLoginMutationVariables>;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminLoginMutation(baseOptions?: Apollo.MutationHookOptions<AdminLoginMutation, AdminLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminLoginMutation, AdminLoginMutationVariables>(AdminLoginDocument, options);
      }
export type AdminLoginMutationHookResult = ReturnType<typeof useAdminLoginMutation>;
export type AdminLoginMutationResult = Apollo.MutationResult<AdminLoginMutation>;
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<AdminLoginMutation, AdminLoginMutationVariables>;
export const AdminMakeTransactionDocument = gql`
    mutation adminMakeTransaction($data: AdminMakeTransactionInput!) {
  adminMakeTransaction(data: $data) {
    ...RegularTransaction
  }
}
    ${RegularTransactionFragmentDoc}`;
export type AdminMakeTransactionMutationFn = Apollo.MutationFunction<AdminMakeTransactionMutation, AdminMakeTransactionMutationVariables>;

/**
 * __useAdminMakeTransactionMutation__
 *
 * To run a mutation, you first call `useAdminMakeTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminMakeTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminMakeTransactionMutation, { data, loading, error }] = useAdminMakeTransactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminMakeTransactionMutation(baseOptions?: Apollo.MutationHookOptions<AdminMakeTransactionMutation, AdminMakeTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminMakeTransactionMutation, AdminMakeTransactionMutationVariables>(AdminMakeTransactionDocument, options);
      }
export type AdminMakeTransactionMutationHookResult = ReturnType<typeof useAdminMakeTransactionMutation>;
export type AdminMakeTransactionMutationResult = Apollo.MutationResult<AdminMakeTransactionMutation>;
export type AdminMakeTransactionMutationOptions = Apollo.BaseMutationOptions<AdminMakeTransactionMutation, AdminMakeTransactionMutationVariables>;
export const AdminUpdateTransactionDocument = gql`
    mutation adminUpdateTransaction($data: AdminUpdateTransactionInput!, $where: TransactionWhereUniqueInput!) {
  adminUpdateTransaction(data: $data, where: $where) {
    ...RegularTransaction
  }
}
    ${RegularTransactionFragmentDoc}`;
export type AdminUpdateTransactionMutationFn = Apollo.MutationFunction<AdminUpdateTransactionMutation, AdminUpdateTransactionMutationVariables>;

/**
 * __useAdminUpdateTransactionMutation__
 *
 * To run a mutation, you first call `useAdminUpdateTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateTransactionMutation, { data, loading, error }] = useAdminUpdateTransactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAdminUpdateTransactionMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateTransactionMutation, AdminUpdateTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateTransactionMutation, AdminUpdateTransactionMutationVariables>(AdminUpdateTransactionDocument, options);
      }
export type AdminUpdateTransactionMutationHookResult = ReturnType<typeof useAdminUpdateTransactionMutation>;
export type AdminUpdateTransactionMutationResult = Apollo.MutationResult<AdminUpdateTransactionMutation>;
export type AdminUpdateTransactionMutationOptions = Apollo.BaseMutationOptions<AdminUpdateTransactionMutation, AdminUpdateTransactionMutationVariables>;
export const CreateAdminUserDocument = gql`
    mutation createAdminUser($data: AdminUserCreateInput!) {
  createAdminUser(data: $data) {
    ...RegularAdminUser
  }
}
    ${RegularAdminUserFragmentDoc}`;
export type CreateAdminUserMutationFn = Apollo.MutationFunction<CreateAdminUserMutation, CreateAdminUserMutationVariables>;

/**
 * __useCreateAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminUserMutation, { data, loading, error }] = useCreateAdminUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminUserMutation, CreateAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminUserMutation, CreateAdminUserMutationVariables>(CreateAdminUserDocument, options);
      }
export type CreateAdminUserMutationHookResult = ReturnType<typeof useCreateAdminUserMutation>;
export type CreateAdminUserMutationResult = Apollo.MutationResult<CreateAdminUserMutation>;
export type CreateAdminUserMutationOptions = Apollo.BaseMutationOptions<CreateAdminUserMutation, CreateAdminUserMutationVariables>;
export const CreateGameTokenDocument = gql`
    mutation createGameToken($data: GameTokenCreateInput!) {
  createGameToken(data: $data) {
    ...RegularGameToken
  }
}
    ${RegularGameTokenFragmentDoc}`;
export type CreateGameTokenMutationFn = Apollo.MutationFunction<CreateGameTokenMutation, CreateGameTokenMutationVariables>;

/**
 * __useCreateGameTokenMutation__
 *
 * To run a mutation, you first call `useCreateGameTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameTokenMutation, { data, loading, error }] = useCreateGameTokenMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateGameTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateGameTokenMutation, CreateGameTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGameTokenMutation, CreateGameTokenMutationVariables>(CreateGameTokenDocument, options);
      }
export type CreateGameTokenMutationHookResult = ReturnType<typeof useCreateGameTokenMutation>;
export type CreateGameTokenMutationResult = Apollo.MutationResult<CreateGameTokenMutation>;
export type CreateGameTokenMutationOptions = Apollo.BaseMutationOptions<CreateGameTokenMutation, CreateGameTokenMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($data: UserCreateInput!) {
  createUser(data: $data) {
    ...RegularUser
  }
}
    ${RegularUserFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateWalletDocument = gql`
    mutation createWallet($data: WalletCreateInput!) {
  createWallet(data: $data) {
    ...RegularWallet
  }
}
    ${RegularWalletFragmentDoc}`;
export type CreateWalletMutationFn = Apollo.MutationFunction<CreateWalletMutation, CreateWalletMutationVariables>;

/**
 * __useCreateWalletMutation__
 *
 * To run a mutation, you first call `useCreateWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWalletMutation, { data, loading, error }] = useCreateWalletMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWalletMutation(baseOptions?: Apollo.MutationHookOptions<CreateWalletMutation, CreateWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWalletMutation, CreateWalletMutationVariables>(CreateWalletDocument, options);
      }
export type CreateWalletMutationHookResult = ReturnType<typeof useCreateWalletMutation>;
export type CreateWalletMutationResult = Apollo.MutationResult<CreateWalletMutation>;
export type CreateWalletMutationOptions = Apollo.BaseMutationOptions<CreateWalletMutation, CreateWalletMutationVariables>;
export const DeleteAdminUserDocument = gql`
    mutation deleteAdminUser($where: AdminUserWhereUniqueInput!) {
  deleteAdminUser(where: $where) {
    ...RegularAdminUser
  }
}
    ${RegularAdminUserFragmentDoc}`;
export type DeleteAdminUserMutationFn = Apollo.MutationFunction<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>;

/**
 * __useDeleteAdminUserMutation__
 *
 * To run a mutation, you first call `useDeleteAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminUserMutation, { data, loading, error }] = useDeleteAdminUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>(DeleteAdminUserDocument, options);
      }
export type DeleteAdminUserMutationHookResult = ReturnType<typeof useDeleteAdminUserMutation>;
export type DeleteAdminUserMutationResult = Apollo.MutationResult<DeleteAdminUserMutation>;
export type DeleteAdminUserMutationOptions = Apollo.BaseMutationOptions<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>;
export const DeleteGameTokenDocument = gql`
    mutation deleteGameToken($where: GameTokenWhereUniqueInput!) {
  deleteGameToken(where: $where) {
    ...RegularGameToken
  }
}
    ${RegularGameTokenFragmentDoc}`;
export type DeleteGameTokenMutationFn = Apollo.MutationFunction<DeleteGameTokenMutation, DeleteGameTokenMutationVariables>;

/**
 * __useDeleteGameTokenMutation__
 *
 * To run a mutation, you first call `useDeleteGameTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameTokenMutation, { data, loading, error }] = useDeleteGameTokenMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteGameTokenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGameTokenMutation, DeleteGameTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGameTokenMutation, DeleteGameTokenMutationVariables>(DeleteGameTokenDocument, options);
      }
export type DeleteGameTokenMutationHookResult = ReturnType<typeof useDeleteGameTokenMutation>;
export type DeleteGameTokenMutationResult = Apollo.MutationResult<DeleteGameTokenMutation>;
export type DeleteGameTokenMutationOptions = Apollo.BaseMutationOptions<DeleteGameTokenMutation, DeleteGameTokenMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($where: UserWhereUniqueInput!) {
  deleteUser(where: $where) {
    ...RegularUser
  }
}
    ${RegularUserFragmentDoc}`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ExchangeWalletsDocument = gql`
    mutation exchangeWallets($data: ExchangeWalletsInput!) {
  exchangeWallets(data: $data)
}
    `;
export type ExchangeWalletsMutationFn = Apollo.MutationFunction<ExchangeWalletsMutation, ExchangeWalletsMutationVariables>;

/**
 * __useExchangeWalletsMutation__
 *
 * To run a mutation, you first call `useExchangeWalletsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangeWalletsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangeWalletsMutation, { data, loading, error }] = useExchangeWalletsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExchangeWalletsMutation(baseOptions?: Apollo.MutationHookOptions<ExchangeWalletsMutation, ExchangeWalletsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExchangeWalletsMutation, ExchangeWalletsMutationVariables>(ExchangeWalletsDocument, options);
      }
export type ExchangeWalletsMutationHookResult = ReturnType<typeof useExchangeWalletsMutation>;
export type ExchangeWalletsMutationResult = Apollo.MutationResult<ExchangeWalletsMutation>;
export type ExchangeWalletsMutationOptions = Apollo.BaseMutationOptions<ExchangeWalletsMutation, ExchangeWalletsMutationVariables>;
export const GeneratePresignedFileDocument = gql`
    mutation generatePresignedFile($data: GeneratePresignedFileInput!) {
  generatePresignedFile(data: $data) {
    name
    path
    url
    signedUrl
  }
}
    `;
export type GeneratePresignedFileMutationFn = Apollo.MutationFunction<GeneratePresignedFileMutation, GeneratePresignedFileMutationVariables>;

/**
 * __useGeneratePresignedFileMutation__
 *
 * To run a mutation, you first call `useGeneratePresignedFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePresignedFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePresignedFileMutation, { data, loading, error }] = useGeneratePresignedFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGeneratePresignedFileMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePresignedFileMutation, GeneratePresignedFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePresignedFileMutation, GeneratePresignedFileMutationVariables>(GeneratePresignedFileDocument, options);
      }
export type GeneratePresignedFileMutationHookResult = ReturnType<typeof useGeneratePresignedFileMutation>;
export type GeneratePresignedFileMutationResult = Apollo.MutationResult<GeneratePresignedFileMutation>;
export type GeneratePresignedFileMutationOptions = Apollo.BaseMutationOptions<GeneratePresignedFileMutation, GeneratePresignedFileMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($token: String!) {
  refreshToken(token: $token) {
    expiresIn
    accessToken
    refreshToken
    refreshExpiresIn
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const UpdateAdminUserDocument = gql`
    mutation updateAdminUser($data: AdminUserUpdateInput!, $where: AdminUserWhereUniqueInput!) {
  updateAdminUser(data: $data, where: $where) {
    ...RegularAdminUser
  }
}
    ${RegularAdminUserFragmentDoc}`;
export type UpdateAdminUserMutationFn = Apollo.MutationFunction<UpdateAdminUserMutation, UpdateAdminUserMutationVariables>;

/**
 * __useUpdateAdminUserMutation__
 *
 * To run a mutation, you first call `useUpdateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminUserMutation, { data, loading, error }] = useUpdateAdminUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminUserMutation, UpdateAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminUserMutation, UpdateAdminUserMutationVariables>(UpdateAdminUserDocument, options);
      }
export type UpdateAdminUserMutationHookResult = ReturnType<typeof useUpdateAdminUserMutation>;
export type UpdateAdminUserMutationResult = Apollo.MutationResult<UpdateAdminUserMutation>;
export type UpdateAdminUserMutationOptions = Apollo.BaseMutationOptions<UpdateAdminUserMutation, UpdateAdminUserMutationVariables>;
export const UpdateGameTokenDocument = gql`
    mutation updateGameToken($data: GameTokenUpdateInput!, $where: GameTokenWhereUniqueInput!) {
  updateGameToken(data: $data, where: $where) {
    ...RegularGameToken
  }
}
    ${RegularGameTokenFragmentDoc}`;
export type UpdateGameTokenMutationFn = Apollo.MutationFunction<UpdateGameTokenMutation, UpdateGameTokenMutationVariables>;

/**
 * __useUpdateGameTokenMutation__
 *
 * To run a mutation, you first call `useUpdateGameTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameTokenMutation, { data, loading, error }] = useUpdateGameTokenMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateGameTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGameTokenMutation, UpdateGameTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGameTokenMutation, UpdateGameTokenMutationVariables>(UpdateGameTokenDocument, options);
      }
export type UpdateGameTokenMutationHookResult = ReturnType<typeof useUpdateGameTokenMutation>;
export type UpdateGameTokenMutationResult = Apollo.MutationResult<UpdateGameTokenMutation>;
export type UpdateGameTokenMutationOptions = Apollo.BaseMutationOptions<UpdateGameTokenMutation, UpdateGameTokenMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateUser(data: $data, where: $where) {
    ...RegularUser
  }
}
    ${RegularUserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateWalletDocument = gql`
    mutation updateWallet($data: WalletUpdateInput!, $where: WalletWhereUniqueInput!) {
  updateWallet(data: $data, where: $where) {
    ...RegularWallet
  }
}
    ${RegularWalletFragmentDoc}`;
export type UpdateWalletMutationFn = Apollo.MutationFunction<UpdateWalletMutation, UpdateWalletMutationVariables>;

/**
 * __useUpdateWalletMutation__
 *
 * To run a mutation, you first call `useUpdateWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWalletMutation, { data, loading, error }] = useUpdateWalletMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateWalletMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWalletMutation, UpdateWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWalletMutation, UpdateWalletMutationVariables>(UpdateWalletDocument, options);
      }
export type UpdateWalletMutationHookResult = ReturnType<typeof useUpdateWalletMutation>;
export type UpdateWalletMutationResult = Apollo.MutationResult<UpdateWalletMutation>;
export type UpdateWalletMutationOptions = Apollo.BaseMutationOptions<UpdateWalletMutation, UpdateWalletMutationVariables>;
export const AdminEstimateTransactionDocument = gql`
    query adminEstimateTransaction($type: TransactionType!, $currency: Currency!, $fiat: Float, $cryptoCurrency: String!, $crypto: Float, $margin: Float, $rate: Float) {
  adminEstimateTransaction(
    type: $type
    currency: $currency
    fiat: $fiat
    cryptoCurrency: $cryptoCurrency
    crypto: $crypto
    margin: $margin
    rate: $rate
  ) {
    currency
    cryptoCurrency
    cryptoRate
    crypto
    fiat
  }
}
    `;

/**
 * __useAdminEstimateTransactionQuery__
 *
 * To run a query within a React component, call `useAdminEstimateTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminEstimateTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminEstimateTransactionQuery({
 *   variables: {
 *      type: // value for 'type'
 *      currency: // value for 'currency'
 *      fiat: // value for 'fiat'
 *      cryptoCurrency: // value for 'cryptoCurrency'
 *      crypto: // value for 'crypto'
 *      margin: // value for 'margin'
 *      rate: // value for 'rate'
 *   },
 * });
 */
export function useAdminEstimateTransactionQuery(baseOptions: Apollo.QueryHookOptions<AdminEstimateTransactionQuery, AdminEstimateTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminEstimateTransactionQuery, AdminEstimateTransactionQueryVariables>(AdminEstimateTransactionDocument, options);
      }
export function useAdminEstimateTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminEstimateTransactionQuery, AdminEstimateTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminEstimateTransactionQuery, AdminEstimateTransactionQueryVariables>(AdminEstimateTransactionDocument, options);
        }
export type AdminEstimateTransactionQueryHookResult = ReturnType<typeof useAdminEstimateTransactionQuery>;
export type AdminEstimateTransactionLazyQueryHookResult = ReturnType<typeof useAdminEstimateTransactionLazyQuery>;
export type AdminEstimateTransactionQueryResult = Apollo.QueryResult<AdminEstimateTransactionQuery, AdminEstimateTransactionQueryVariables>;
export const ConvertGameTokenRateDocument = gql`
    query convertGameTokenRate($fromCurrency: String!, $toCurrency: String!) {
  convertGameTokenRate(fromCurrency: $fromCurrency, toCurrency: $toCurrency) {
    fromCurrency
    toCurrency
    conversionRate
  }
}
    `;

/**
 * __useConvertGameTokenRateQuery__
 *
 * To run a query within a React component, call `useConvertGameTokenRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useConvertGameTokenRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConvertGameTokenRateQuery({
 *   variables: {
 *      fromCurrency: // value for 'fromCurrency'
 *      toCurrency: // value for 'toCurrency'
 *   },
 * });
 */
export function useConvertGameTokenRateQuery(baseOptions: Apollo.QueryHookOptions<ConvertGameTokenRateQuery, ConvertGameTokenRateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConvertGameTokenRateQuery, ConvertGameTokenRateQueryVariables>(ConvertGameTokenRateDocument, options);
      }
export function useConvertGameTokenRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConvertGameTokenRateQuery, ConvertGameTokenRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConvertGameTokenRateQuery, ConvertGameTokenRateQueryVariables>(ConvertGameTokenRateDocument, options);
        }
export type ConvertGameTokenRateQueryHookResult = ReturnType<typeof useConvertGameTokenRateQuery>;
export type ConvertGameTokenRateLazyQueryHookResult = ReturnType<typeof useConvertGameTokenRateLazyQuery>;
export type ConvertGameTokenRateQueryResult = Apollo.QueryResult<ConvertGameTokenRateQuery, ConvertGameTokenRateQueryVariables>;
export const FindFirstAdminUserDocument = gql`
    query findFirstAdminUser($where: AdminUserWhereInput!) {
  findFirstAdminUser(where: $where) {
    ...RegularAdminUser
  }
}
    ${RegularAdminUserFragmentDoc}`;

/**
 * __useFindFirstAdminUserQuery__
 *
 * To run a query within a React component, call `useFindFirstAdminUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstAdminUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstAdminUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstAdminUserQuery(baseOptions: Apollo.QueryHookOptions<FindFirstAdminUserQuery, FindFirstAdminUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstAdminUserQuery, FindFirstAdminUserQueryVariables>(FindFirstAdminUserDocument, options);
      }
export function useFindFirstAdminUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstAdminUserQuery, FindFirstAdminUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstAdminUserQuery, FindFirstAdminUserQueryVariables>(FindFirstAdminUserDocument, options);
        }
export type FindFirstAdminUserQueryHookResult = ReturnType<typeof useFindFirstAdminUserQuery>;
export type FindFirstAdminUserLazyQueryHookResult = ReturnType<typeof useFindFirstAdminUserLazyQuery>;
export type FindFirstAdminUserQueryResult = Apollo.QueryResult<FindFirstAdminUserQuery, FindFirstAdminUserQueryVariables>;
export const FindFirstGameTokenDocument = gql`
    query findFirstGameToken($where: GameTokenWhereInput!) {
  findFirstGameToken(where: $where) {
    ...RegularGameToken
  }
}
    ${RegularGameTokenFragmentDoc}`;

/**
 * __useFindFirstGameTokenQuery__
 *
 * To run a query within a React component, call `useFindFirstGameTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstGameTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstGameTokenQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstGameTokenQuery(baseOptions: Apollo.QueryHookOptions<FindFirstGameTokenQuery, FindFirstGameTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstGameTokenQuery, FindFirstGameTokenQueryVariables>(FindFirstGameTokenDocument, options);
      }
export function useFindFirstGameTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstGameTokenQuery, FindFirstGameTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstGameTokenQuery, FindFirstGameTokenQueryVariables>(FindFirstGameTokenDocument, options);
        }
export type FindFirstGameTokenQueryHookResult = ReturnType<typeof useFindFirstGameTokenQuery>;
export type FindFirstGameTokenLazyQueryHookResult = ReturnType<typeof useFindFirstGameTokenLazyQuery>;
export type FindFirstGameTokenQueryResult = Apollo.QueryResult<FindFirstGameTokenQuery, FindFirstGameTokenQueryVariables>;
export const FindFirstUserDocument = gql`
    query findFirstUser($where: UserWhereInput!) {
  findFirstUser(where: $where) {
    ...RegularUser
  }
}
    ${RegularUserFragmentDoc}`;

/**
 * __useFindFirstUserQuery__
 *
 * To run a query within a React component, call `useFindFirstUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstUserQuery(baseOptions: Apollo.QueryHookOptions<FindFirstUserQuery, FindFirstUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstUserQuery, FindFirstUserQueryVariables>(FindFirstUserDocument, options);
      }
export function useFindFirstUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstUserQuery, FindFirstUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstUserQuery, FindFirstUserQueryVariables>(FindFirstUserDocument, options);
        }
export type FindFirstUserQueryHookResult = ReturnType<typeof useFindFirstUserQuery>;
export type FindFirstUserLazyQueryHookResult = ReturnType<typeof useFindFirstUserLazyQuery>;
export type FindFirstUserQueryResult = Apollo.QueryResult<FindFirstUserQuery, FindFirstUserQueryVariables>;
export const FindFirstWalletDocument = gql`
    query findFirstWallet($where: WalletWhereInput!) {
  findFirstWallet(where: $where) {
    ...RegularWallet
  }
}
    ${RegularWalletFragmentDoc}`;

/**
 * __useFindFirstWalletQuery__
 *
 * To run a query within a React component, call `useFindFirstWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstWalletQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstWalletQuery(baseOptions: Apollo.QueryHookOptions<FindFirstWalletQuery, FindFirstWalletQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstWalletQuery, FindFirstWalletQueryVariables>(FindFirstWalletDocument, options);
      }
export function useFindFirstWalletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstWalletQuery, FindFirstWalletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstWalletQuery, FindFirstWalletQueryVariables>(FindFirstWalletDocument, options);
        }
export type FindFirstWalletQueryHookResult = ReturnType<typeof useFindFirstWalletQuery>;
export type FindFirstWalletLazyQueryHookResult = ReturnType<typeof useFindFirstWalletLazyQuery>;
export type FindFirstWalletQueryResult = Apollo.QueryResult<FindFirstWalletQuery, FindFirstWalletQueryVariables>;
export const ListAdminRolesDocument = gql`
    query listAdminRoles($page: Int = 1, $perPage: Int = 20, $where: AdminRoleWhereInput, $orderBy: [AdminRoleOrderByWithRelationInput!]) {
  listAdminRoles(page: $page, perPage: $perPage, where: $where, orderBy: $orderBy) {
    items {
      id
      name
      slug
      description
      createdAt
      updatedAt
    }
    metadata {
      currentPage
      perPage
      totalCount
      totalPages
    }
  }
}
    `;

/**
 * __useListAdminRolesQuery__
 *
 * To run a query within a React component, call `useListAdminRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminRolesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListAdminRolesQuery(baseOptions?: Apollo.QueryHookOptions<ListAdminRolesQuery, ListAdminRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdminRolesQuery, ListAdminRolesQueryVariables>(ListAdminRolesDocument, options);
      }
export function useListAdminRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminRolesQuery, ListAdminRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdminRolesQuery, ListAdminRolesQueryVariables>(ListAdminRolesDocument, options);
        }
export type ListAdminRolesQueryHookResult = ReturnType<typeof useListAdminRolesQuery>;
export type ListAdminRolesLazyQueryHookResult = ReturnType<typeof useListAdminRolesLazyQuery>;
export type ListAdminRolesQueryResult = Apollo.QueryResult<ListAdminRolesQuery, ListAdminRolesQueryVariables>;
export const ListAdminUsersDocument = gql`
    query listAdminUsers($page: Int, $perPage: Int, $where: AdminUserWhereInput, $orderBy: [AdminUserOrderByWithRelationInput!]) {
  listAdminUsers(page: $page, perPage: $perPage, where: $where, orderBy: $orderBy) {
    items {
      ...RegularAdminUser
      role {
        name
      }
    }
    metadata {
      currentPage
      perPage
      totalCount
      totalPages
    }
  }
}
    ${RegularAdminUserFragmentDoc}`;

/**
 * __useListAdminUsersQuery__
 *
 * To run a query within a React component, call `useListAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListAdminUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListAdminUsersQuery, ListAdminUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdminUsersQuery, ListAdminUsersQueryVariables>(ListAdminUsersDocument, options);
      }
export function useListAdminUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminUsersQuery, ListAdminUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdminUsersQuery, ListAdminUsersQueryVariables>(ListAdminUsersDocument, options);
        }
export type ListAdminUsersQueryHookResult = ReturnType<typeof useListAdminUsersQuery>;
export type ListAdminUsersLazyQueryHookResult = ReturnType<typeof useListAdminUsersLazyQuery>;
export type ListAdminUsersQueryResult = Apollo.QueryResult<ListAdminUsersQuery, ListAdminUsersQueryVariables>;
export const ListBalanceSheetReportsDocument = gql`
    query listBalanceSheetReports($page: Int, $perPage: Int, $where: BalanceSheetReportWhereInput, $orderBy: [BalanceSheetReportOrderByWithRelationInput!]) {
  listBalanceSheetReports(
    page: $page
    perPage: $perPage
    where: $where
    orderBy: $orderBy
  ) {
    items {
      ...REGULAR_BALANCE_SHEET_REPORT
    }
    metadata {
      currentPage
      perPage
      totalCount
      totalPages
    }
  }
}
    ${Regular_Balance_Sheet_ReportFragmentDoc}`;

/**
 * __useListBalanceSheetReportsQuery__
 *
 * To run a query within a React component, call `useListBalanceSheetReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBalanceSheetReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBalanceSheetReportsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListBalanceSheetReportsQuery(baseOptions?: Apollo.QueryHookOptions<ListBalanceSheetReportsQuery, ListBalanceSheetReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBalanceSheetReportsQuery, ListBalanceSheetReportsQueryVariables>(ListBalanceSheetReportsDocument, options);
      }
export function useListBalanceSheetReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBalanceSheetReportsQuery, ListBalanceSheetReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBalanceSheetReportsQuery, ListBalanceSheetReportsQueryVariables>(ListBalanceSheetReportsDocument, options);
        }
export type ListBalanceSheetReportsQueryHookResult = ReturnType<typeof useListBalanceSheetReportsQuery>;
export type ListBalanceSheetReportsLazyQueryHookResult = ReturnType<typeof useListBalanceSheetReportsLazyQuery>;
export type ListBalanceSheetReportsQueryResult = Apollo.QueryResult<ListBalanceSheetReportsQuery, ListBalanceSheetReportsQueryVariables>;
export const ListBlockchainNetworksDocument = gql`
    query listBlockchainNetworks($page: Int = 1, $perPage: Int = 20, $where: BlockchainNetworkWhereInput, $orderBy: [BlockchainNetworkOrderByWithRelationInput!]) {
  listBlockchainNetworks(
    page: $page
    perPage: $perPage
    where: $where
    orderBy: $orderBy
  ) {
    items {
      ...RegularBlockchainNetwork
    }
    metadata {
      currentPage
      perPage
      totalCount
      totalPages
    }
  }
}
    ${RegularBlockchainNetworkFragmentDoc}`;

/**
 * __useListBlockchainNetworksQuery__
 *
 * To run a query within a React component, call `useListBlockchainNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBlockchainNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBlockchainNetworksQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListBlockchainNetworksQuery(baseOptions?: Apollo.QueryHookOptions<ListBlockchainNetworksQuery, ListBlockchainNetworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBlockchainNetworksQuery, ListBlockchainNetworksQueryVariables>(ListBlockchainNetworksDocument, options);
      }
export function useListBlockchainNetworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBlockchainNetworksQuery, ListBlockchainNetworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBlockchainNetworksQuery, ListBlockchainNetworksQueryVariables>(ListBlockchainNetworksDocument, options);
        }
export type ListBlockchainNetworksQueryHookResult = ReturnType<typeof useListBlockchainNetworksQuery>;
export type ListBlockchainNetworksLazyQueryHookResult = ReturnType<typeof useListBlockchainNetworksLazyQuery>;
export type ListBlockchainNetworksQueryResult = Apollo.QueryResult<ListBlockchainNetworksQuery, ListBlockchainNetworksQueryVariables>;
export const ListGameTokensDocument = gql`
    query listGameTokens($page: Int = 1, $perPage: Int = 20, $where: GameTokenWhereInput, $orderBy: [GameTokenOrderByWithRelationInput!]) {
  listGameTokens(page: $page, perPage: $perPage, where: $where, orderBy: $orderBy) {
    items {
      ...RegularGameToken
    }
    metadata {
      currentPage
      perPage
      totalCount
      totalPages
    }
  }
}
    ${RegularGameTokenFragmentDoc}`;

/**
 * __useListGameTokensQuery__
 *
 * To run a query within a React component, call `useListGameTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGameTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGameTokensQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListGameTokensQuery(baseOptions?: Apollo.QueryHookOptions<ListGameTokensQuery, ListGameTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGameTokensQuery, ListGameTokensQueryVariables>(ListGameTokensDocument, options);
      }
export function useListGameTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGameTokensQuery, ListGameTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGameTokensQuery, ListGameTokensQueryVariables>(ListGameTokensDocument, options);
        }
export type ListGameTokensQueryHookResult = ReturnType<typeof useListGameTokensQuery>;
export type ListGameTokensLazyQueryHookResult = ReturnType<typeof useListGameTokensLazyQuery>;
export type ListGameTokensQueryResult = Apollo.QueryResult<ListGameTokensQuery, ListGameTokensQueryVariables>;
export const ListInternalTransactionsDocument = gql`
    query listInternalTransactions($page: Int, $perPage: Int, $where: InternalTransactionWhereInput, $orderBy: [InternalTransactionOrderByWithRelationInput!]) {
  listInternalTransactions(
    page: $page
    perPage: $perPage
    where: $where
    orderBy: $orderBy
  ) {
    items {
      ...RegularInternalTransaction
    }
    metadata {
      currentPage
      perPage
      totalCount
      totalPages
    }
  }
}
    ${RegularInternalTransactionFragmentDoc}`;

/**
 * __useListInternalTransactionsQuery__
 *
 * To run a query within a React component, call `useListInternalTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInternalTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInternalTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListInternalTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<ListInternalTransactionsQuery, ListInternalTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInternalTransactionsQuery, ListInternalTransactionsQueryVariables>(ListInternalTransactionsDocument, options);
      }
export function useListInternalTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInternalTransactionsQuery, ListInternalTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInternalTransactionsQuery, ListInternalTransactionsQueryVariables>(ListInternalTransactionsDocument, options);
        }
export type ListInternalTransactionsQueryHookResult = ReturnType<typeof useListInternalTransactionsQuery>;
export type ListInternalTransactionsLazyQueryHookResult = ReturnType<typeof useListInternalTransactionsLazyQuery>;
export type ListInternalTransactionsQueryResult = Apollo.QueryResult<ListInternalTransactionsQuery, ListInternalTransactionsQueryVariables>;
export const ListLedgerTransactionsDocument = gql`
    query listLedgerTransactions($page: Int, $perPage: Int, $where: LedgerTransactionWhereInput, $orderBy: [LedgerTransactionOrderByWithRelationInput!]) {
  listLedgerTransactions(
    page: $page
    perPage: $perPage
    where: $where
    orderBy: $orderBy
  ) {
    items {
      ...RegularLedgerTransaction
    }
    metadata {
      currentPage
      perPage
      totalCount
      totalPages
    }
  }
}
    ${RegularLedgerTransactionFragmentDoc}`;

/**
 * __useListLedgerTransactionsQuery__
 *
 * To run a query within a React component, call `useListLedgerTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLedgerTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLedgerTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListLedgerTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<ListLedgerTransactionsQuery, ListLedgerTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLedgerTransactionsQuery, ListLedgerTransactionsQueryVariables>(ListLedgerTransactionsDocument, options);
      }
export function useListLedgerTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLedgerTransactionsQuery, ListLedgerTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLedgerTransactionsQuery, ListLedgerTransactionsQueryVariables>(ListLedgerTransactionsDocument, options);
        }
export type ListLedgerTransactionsQueryHookResult = ReturnType<typeof useListLedgerTransactionsQuery>;
export type ListLedgerTransactionsLazyQueryHookResult = ReturnType<typeof useListLedgerTransactionsLazyQuery>;
export type ListLedgerTransactionsQueryResult = Apollo.QueryResult<ListLedgerTransactionsQuery, ListLedgerTransactionsQueryVariables>;
export const ListTransactionsDocument = gql`
    query listTransactions($page: Int = 1, $perPage: Int = 20, $where: TransactionWhereInput, $orderBy: [TransactionOrderByWithRelationInput!]) {
  listTransactions(
    page: $page
    perPage: $perPage
    where: $where
    orderBy: $orderBy
  ) {
    items {
      ...RegularTransaction
    }
    metadata {
      currentPage
      perPage
      totalCount
      totalPages
    }
  }
}
    ${RegularTransactionFragmentDoc}`;

/**
 * __useListTransactionsQuery__
 *
 * To run a query within a React component, call `useListTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<ListTransactionsQuery, ListTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTransactionsQuery, ListTransactionsQueryVariables>(ListTransactionsDocument, options);
      }
export function useListTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTransactionsQuery, ListTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTransactionsQuery, ListTransactionsQueryVariables>(ListTransactionsDocument, options);
        }
export type ListTransactionsQueryHookResult = ReturnType<typeof useListTransactionsQuery>;
export type ListTransactionsLazyQueryHookResult = ReturnType<typeof useListTransactionsLazyQuery>;
export type ListTransactionsQueryResult = Apollo.QueryResult<ListTransactionsQuery, ListTransactionsQueryVariables>;
export const ListUsersDocument = gql`
    query listUsers($page: Int, $perPage: Int, $where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput!]) {
  listUsers(page: $page, perPage: $perPage, where: $where, orderBy: $orderBy) {
    items {
      ...RegularUser
    }
    metadata {
      currentPage
      perPage
      totalCount
      totalPages
    }
  }
}
    ${RegularUserFragmentDoc}`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const ListWalletsDocument = gql`
    query listWallets($page: Int, $perPage: Int, $where: WalletWhereInput, $orderBy: [WalletOrderByWithRelationInput!]) {
  listWallets(page: $page, perPage: $perPage, where: $where, orderBy: $orderBy) {
    items {
      ...RegularWallet
    }
    metadata {
      currentPage
      perPage
      totalCount
      totalPages
    }
  }
}
    ${RegularWalletFragmentDoc}`;

/**
 * __useListWalletsQuery__
 *
 * To run a query within a React component, call `useListWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWalletsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListWalletsQuery(baseOptions?: Apollo.QueryHookOptions<ListWalletsQuery, ListWalletsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWalletsQuery, ListWalletsQueryVariables>(ListWalletsDocument, options);
      }
export function useListWalletsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWalletsQuery, ListWalletsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWalletsQuery, ListWalletsQueryVariables>(ListWalletsDocument, options);
        }
export type ListWalletsQueryHookResult = ReturnType<typeof useListWalletsQuery>;
export type ListWalletsLazyQueryHookResult = ReturnType<typeof useListWalletsLazyQuery>;
export type ListWalletsQueryResult = Apollo.QueryResult<ListWalletsQuery, ListWalletsQueryVariables>;