import { useApolloClient } from '@apollo/client';
import { clearTokens } from 'lib/local-storage';
import { useSetIsAuthenticated } from './use-is-authenticated';

export const useLogout = () => {
  const client = useApolloClient();
  const setIsAuthenticated = useSetIsAuthenticated();

  return function logout() {
    return client.cache
      .reset()
      .then(() => client.resetStore())
      .then(() => {
        setIsAuthenticated(false);
        clearTokens();
      });
  };
};
