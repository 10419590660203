import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { fromPromise } from "@apollo/client/link/utils";
import { RefreshTokenDocument, RefreshTokenMutation } from "api";
import type { GraphQLError } from "graphql";
import {
  clearTokens,
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "lib/local-storage";
import { notifyAuthChange } from "modules/auth/hooks/use-is-authenticated";

export const createQueryClient = () => {
  let isRefreshing = false;
  let pendingRequests: Array<() => void> = [];

  const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback());
    pendingRequests = [];
  };

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          if (isUnauthorizedError(err)) {
            let forward$;

            if (!isRefreshing) {
              isRefreshing = true;

              forward$ = fromPromise(
                client
                  .mutate<RefreshTokenMutation>({
                    mutation: RefreshTokenDocument,
                    variables: {
                      token: getRefreshToken(),
                    },
                  })
                  .then(({ data }) => {
                    if (data && data.refreshToken) {
                      setAccessToken(data.refreshToken.accessToken);
                      setRefreshToken(data.refreshToken.refreshToken);
                      // Store the new tokens for your auth link
                      resolvePendingRequests();
                    }
                  })
                  // eslint-disable-next-line no-loop-func
                  .catch((error) => {
                    pendingRequests = [];
                    // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                    clearTokens();
                    notifyAuthChange();

                    console.error(error);
                    return client.resetStore();
                  })
                  // eslint-disable-next-line no-loop-func
                  .finally(() => {
                    isRefreshing = false;
                  })
              ).filter((value) => Boolean(value));
            } else {
              // Will only emit once the Promise is resolved
              forward$ = fromPromise(
                // eslint-disable-next-line no-loop-func
                new Promise<void>((resolve) =>
                  pendingRequests.push(() => resolve())
                )
              );
            }

            return forward$.flatMap(() => forward(operation));
          }
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        // if you would also like to retry automatically on
        // network errors, we recommend that you use
        // apollo-link-retry
      }
    }
  );

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_BASE_URL,
  });

  const authLink = setContext((_, { headers }) => {
    const token = getAccessToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return client;
};

const isUnauthorizedError = (error: GraphQLError) =>
  error.message === "Unauthorized";
